/**
 *
 * LineBox
 *
 */

import React from 'react';
import KeyboardedInput from './KeyboardedInput';
import PropTypes from 'prop-types';

class Input extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <KeyboardedInput
        disabled={this.props.disabled}
        variant={this.props.variant}
        enabled
        type={this.props.type}
        onChange={this.props.onChange}
        value={this.props.value}
        min={this.props.min}
        max={this.props.max}
        step={this.props.step}
        pattern={this.props.pattern}
        readOnly={this.props.editable === false}
        defaultKeyboard="us"
        opacity={0.9}
        error={this.props.error}
        helperText={this.props.helperText}
        id={this.props.id}
        label={this.props.label}
        className={this.props.className}
        inputlabelprops={this.props.inputlabelprops}
        style={this.props.style}
        size={this.props.size}></KeyboardedInput>
    );
  }
}

Input.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.object,
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  min: PropTypes.string,
  max: PropTypes.string,
  step: PropTypes.string,
  pattern: PropTypes.string,
  enabled: PropTypes.bool
};

export default Input;
