import modelFieldMessages from './modelFieldMessages';

class IncomingPackage {
  static fields = {
    compartmentId: {
      key: 'id',
      type: 'arrayindex',
      title: modelFieldMessages.compartment
    },
    id: {
      key: 'id',
      type: 'string',
      title: modelFieldMessages.id
    },
    number: {
      key: 'number',
      type: 'string',
      title: modelFieldMessages.id
    },
    strengthGrade: {
      key: 'strengthGrade',
      type: 'string',
      title: modelFieldMessages.grade
    },
    rawLamellaId: {
      key: 'rawLamellaId',
      type: 'string',
      title: modelFieldMessages.id
    },
    cutListSent: {
      key: 'cutListSent',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.cutListSent
    },
    fullyUsed: {
      key: 'fullyUsed',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.fullyUsed
    },
    fullyUsedSent: {
      key: 'fullyUsedSent',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.fullyUsedSent
    },
    shouldBeRemoved: {
      key: 'shouldBeRemoved',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.shouldBeRemoved
    },
    shouldBeRemovedSent: {
      key: 'shouldBeRemovedSent',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.shouldBeRemovedSent
    },
    productStacked: {
      key: 'productStacked',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.productStacked
    },
    sentToDB: {
      key: 'sentToDB',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.sentToDB
    },
    passedBanner: {
      key: 'passedBanner',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.passedBanner
    },
    goodWood: {
      key: 'goodWood',
      format: {
        type: 'string'
      },
      title: modelFieldMessages.goodWood
    },
    badWood: {
      key: 'badWood',
      format: {
        type: 'string'
      },
      title: modelFieldMessages.badWood
    },
    cutList: {
      key: 'cutList',
      type: 'arraylength',
      title: modelFieldMessages.cutList
    },
    rawLamellaIdentifier: {
      key: 'rawLamellaIdentifier',
      type: 'string',
      title: modelFieldMessages.rawLamellaIdentifier
    },
    piecesPerLayer: {
      key: 'piecesPerLayer',
      type: 'string',
      title: modelFieldMessages.piecesPerLayer,
      validation: ['float', 'aboveZero']
    },
    identifier: {
      key: 'identifier',
      type: 'string',
      title: modelFieldMessages.identifier,
      blank: false
    },
    order: {
      key: 'order',
      type: 'string',
      title: modelFieldMessages.order,
      blank: false
    },
    neededOnLoadingPosition: {
      key: 'neededOnLoadingPosition',
      type: 'string',
      title: modelFieldMessages.neededOnLoadingPosition
    },
    lmWithPaketladn: {
      key: 'lmWithPaketladn',
      title: modelFieldMessages.lmWithPaketladn,
      format: {
        type: 'object_from_many',
        str: '{{ {lmWithPaketladn} / 1000}} m',
        keys: ['lmWithPaketladn']
      }
    },
    splitWood: {
      key: 'canBeSplit',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.splitWood
    },
    machine: {
      key: 'machine',
      type: 'string',
      title: modelFieldMessages.machine
    },
    averageLength: {
      key: 'averageLength',
      type: 'string',
      title: modelFieldMessages.averageLength,
      validation: ['float', 'aboveZero']
    },
    usedAt: {
      key: 'usedAt',
      title: modelFieldMessages.identifierUsedAt,
      format: {
        type: 'date'
      }
    },
    createdAt: {
      key: 'created_at',
      title: modelFieldMessages.identifierCreatedAt,
      format: {
        type: 'date'
      }
    },
    lineRegime: {
      key: 'line_regime',
      title: modelFieldMessages.lineRegime,
      type: 'string'
    },
    createdDate: {
      key: 'createdDate',
      title: modelFieldMessages.identifierCreatedAt,
      format: {
        type: 'date'
      }
    },
    productionDate: {
      key: 'productionDate',
      title: modelFieldMessages.identifierProductionDate,
      format: {
        type: 'date'
      }
    },
    suppliedDate: {
      key: 'identifierSuppliedDate',
      title: modelFieldMessages.identifierSuppliedDate,
      format: {
        type: 'date'
      }
    },
    numRows: {
      key: 'numRows',
      title: modelFieldMessages.identifierNumRows
    },
    quality: {
      key: 'quality',
      type: 'string',
      title: modelFieldMessages.quality
      // blank: false,
    },
    lotNumber: {
      key: 'lotNumber',
      type: 'string',
      title: modelFieldMessages.lotNumber,
      validation: ['float', 'aboveZero'],
      blank: false
    },
    packageNumber: {
      key: 'packageNumber',
      type: 'string',
      title: modelFieldMessages.packageNumber,
      validation: ['float', 'aboveZero'],
      blank: false
    },
    storage: {
      key: 'storage',
      type: 'string',
      title: modelFieldMessages.storage,
      validation: ['float', 'aboveZero'],
      blank: false
    },
    numColumns: {
      key: 'numColumns',
      title: modelFieldMessages.identifierNumColumns
    },
    numBoards: {
      key: 'numBoards',
      type: 'string',
      title: modelFieldMessages.identifierNumBoards
    },
    lamellaLength: {
      key: 'lamellaLength',
      type: 'string',
      title: modelFieldMessages.lamellaLength,
      validation: ['float', 'aboveZero']
    },
    lm: {
      title: modelFieldMessages.lm,
      format: {
        type: 'object_from_many',
        str: '{{ {lm} / 1000}} m',
        keys: ['lm']
      }
    },
    lmConverted: {
      title: modelFieldMessages.lm,
      format: {
        type: 'conversion_from_many',
        str: '{{ ({lm} / 1000).toFixed(3) }}',
        keys: [{ key: 'lm', fromUnit: 'kilometers', toUnit: 'miles' }],
        fromUnitLabel: 'km',
        toUnitLabel: 'mi',
        unitType: 'length'
      }
    },
    lmWithPaketladnConverted: {
      title: modelFieldMessages.lmWithPaketladn,
      format: {
        type: 'conversion_from_many',
        str: '{{ {lmWithPaketladn} / 1000}} m',
        keys: [{ key: 'lmWithPaketladn', fromUnit: 'kilometers', toUnit: 'miles' }],
        fromUnitLabel: 'km',
        toUnitLabel: 'mi',
        unitType: 'length'
      }
    },
    lmUsed: {
      key: 'lmUsed',
      title: modelFieldMessages.lmUsed
    },
    clientId: {
      key: 'clientId',
      type: 'string',
      title: modelFieldMessages.clientId
    },
    width: {
      key: 'lamellaWidth',
      type: 'string',
      title: modelFieldMessages.lamellaWidth,
      validation: ['float', 'aboveZero']
    },
    thickness: {
      key: 'lamellaThickness',
      type: 'string',
      title: modelFieldMessages.lamellaThickness,
      validation: ['float', 'aboveZero']
    },
    thicknessRaw: {
      key: 'thicknessRaw',
      type: 'string',
      title: modelFieldMessages.thicknessRaw,
      validation: ['float', 'aboveZero']
    },
    woodType: {
      key: 'woodType',
      type: 'string',
      title: modelFieldMessages.woodType
    },
    grade: {
      key: 'grade',
      type: 'string',
      title: modelFieldMessages.grade
    },
    materialCode: {
      key: 'materialCode',
      type: 'string',
      title: modelFieldMessages.materialCode
    },
    rawDimensions: {
      title: modelFieldMessages.rawDimensions,
      key: 'rawDimensions',
      format: {
        type: 'object_from_many',
        str: '{lamellaThickness} x {lamellaWidth} x {lamellaLength} mm',
        keys: ['lamellaThickness', 'lamellaWidth', 'lamellaLength']
      }
    },
    rawDimensionsSodra: {
      title: modelFieldMessages.rawDimensions,
      format: {
        type: 'object_from_many',
        str: '{lamellaThickness} x {lamellaWidth} x {averageLength} mm',
        keys: ['lamellaThickness', 'lamellaWidth', 'averageLength']
      }
    },
    rawDimensionsSmartlam: {
      title: modelFieldMessages.rawDimensions,
      format: {
        type: 'conversion_from_many',
        str: '{lamellaThickness} x {lamellaWidth} x {averageLength} ',
        keys: [
          { key: 'lamellaThickness', fromUnit: 'millimeters', toUnit: 'inches' },
          { key: 'lamellaWidth', fromUnit: 'millimeters', toUnit: 'inches' },
          { key: 'averageLength', fromUnit: 'millimeters', toUnit: 'inches' }
        ],
        fromUnitLabel: 'mm',
        toUnitLabel: 'in',
        unitType: 'length'
      }
    },
    rawDimensionsSchilliger: {
      title: modelFieldMessages.rawDimensions,
      format: {
        type: 'object_from_many',
        str: '{rawLamellaThickness} x {rawLamellaWidth} mm',
        keys: ['rawLamellaThickness', 'rawLamellaWidth']
      }
    },
    dimensions: {
      title: modelFieldMessages.dimensions,
      format: {
        type: 'object_from_many',
        str: '{lamellaThickness} x {lamellaWidth} x {lamellaLength} mm',
        keys: ['lamellaThickness', 'lamellaWidth', 'lamellaLength']
      }
    },
    package_m3: {
      title: modelFieldMessages.package_m3,
      format: {
        type: 'object_from_many',
        str: '{{ ({lamellaThickness} * {lamellaWidth} * {lm} * 0.000000001).toFixed(3)}} m3',
        keys: ['lamellaThickness', 'lamellaWidth', 'lm']
      }
    },
    rawDimensionsWithoutLengthConverted: {
      title: modelFieldMessages.rawDimensions,
      format: {
        type: 'conversion_from_many',
        str: '{lamellaThickness} x {lamellaWidth} ',
        keys: [
          { key: 'lamellaThickness', fromUnit: 'millimeters', toUnit: 'inches' },
          { key: 'lamellaWidth', fromUnit: 'millimeters', toUnit: 'inches' }
        ],
        fromUnitLabel: 'mm',
        toUnitLabel: 'in',
        unitType: 'length'
      }
    },
    plannedDimensionsWithoutLengthConverted: {
      title: modelFieldMessages.planedDimensionsSchneider,
      format: {
        type: 'conversion_from_many',
        str: '{lamellaPlannedThickness} x {lamellaPlannedWidth} ',
        keys: [
          { key: 'lamellaPlannedThickness', fromUnit: 'millimeters', toUnit: 'inches' },
          { key: 'lamellaPlannedWidth', fromUnit: 'millimeters', toUnit: 'inches' }
        ],
        fromUnitLabel: 'mm',
        toUnitLabel: 'in',
        unitType: 'length'
      }
    },
    rawDimensionsWithoutLength: {
      title: modelFieldMessages.rawDimensions,
      format: {
        type: 'object_from_many',
        str: '{lamellaThickness} x {lamellaWidth} mm',
        keys: ['lamellaThickness', 'lamellaWidth']
      }
    },
    planedDimensionsWithoutLength: {
      title: modelFieldMessages.planedDimensionsSchneider,
      format: {
        type: 'object_from_many',
        str: '{lamellaPlannedThickness} x {lamellaPlannedWidth} mm',
        keys: ['lamellaPlannedThickness', 'lamellaPlannedWidth']
      }
    },
    numberOfLamellas: {
      key: 'numberOfLamellas',
      type: 'string',
      title: modelFieldMessages.numberOfLamellas
    },
    numberOfLayers: {
      key: 'numberOfLayers',
      type: 'string',
      title: modelFieldMessages.numberOfLayers
    },
    lamellaClass: {
      key: 'grade',
      type: 'string',
      title: modelFieldMessages.lamellaClass
    },
    layers: {
      key: 'layers',
      type: 'string',
      title: modelFieldMessages.numberOfLayers
    },
    totalLamellaLength: {
      key: 'totalLamellaLength',
      type: 'string',
      title: modelFieldMessages.totalLamellaLength
    },
    numberOfLamellasStacked: {
      key: 'numberOfLamellasStacked',
      type: 'string',
      title: modelFieldMessages.numberOfLamellasPlaned
    },
    numberOfLamellasPlaned: {
      key: 'numberOfLamellasPlaned',
      type: 'string',
      title: modelFieldMessages.numberOfLamellasPlaned
    },
    sortingState: {
      key: 'sortingState',
      type: 'string',
      title: modelFieldMessages.sortingState
    },
    piecesUsed: {
      key: 'piecesUsed',
      type: 'string',
      title: modelFieldMessages.piecesUsed
    },
    packageId: {
      key: 'packageId',
      type: 'string',
      title: modelFieldMessages.packageId
    },
    fetchedFromABF: {
      key: 'fetchedFromABF',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.fetchedFromABF
    },
    canBeSplit: {
      key: 'canBeSplit',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.canBeSplit
    },
    sentToAnte: {
      key: 'sentToAnte',
      type: 'boolean',
      format: {
        type: 'boolean'
      }
    },
    ledinek_id: {
      key: 'ledinek_package_id',
      type: 'string',
      title: modelFieldMessages.led_pckg_id
    },
    articleNumber: {
      key: 'articleNumber',
      type: 'string',
      title: modelFieldMessages.articleNumber
    },
    articleId: {
      key: 'articleId',
      type: 'string',
      title: modelFieldMessages.articleNumber
    },
    pieces: {
      key: 'pieces',
      type: 'string',
      title: modelFieldMessages.pieces
    },
    lmNetto: {
      key: 'lmNetto',
      type: 'string',
      title: modelFieldMessages.lmNetto
    },
    lmBrutto: {
      key: 'lmBrutto',
      type: 'string',
      title: modelFieldMessages.lmBrutto
    },
    sequenceNumber: {
      key: 'sequenceNumber',
      type: 'string',
      title: modelFieldMessages.sequenceNumber
    },
    strengthString: {
      key: 'strengthGradeString',
      type: 'string',
      title: modelFieldMessages.strengthString
    },
    batchId: {
      key: 'batchId',
      type: 'string',
      title: modelFieldMessages.batchId
    }
  };

  static actions = {
    deleteIncomingPackage: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deleteIncomingPackage',
      modal: {
        modal: true,
        buttonText: 'deleteIncomingPackage',
        key: 'deleteIncomingPackage',
        action: 'deleteIncomingPackage',
        actionData: {
          target: 'backend',
          source: 'packages/incomingpackages/',
          url: '/api/v1/packages/incomingpackages/remove_packages/',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    deleteSortedPackage: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deleteIncomingPackage',
      modal: {
        modal: true,
        buttonText: 'deleteIncomingPackage',
        key: 'deleteIncomingPackage',
        action: 'deleteIncomingPackage',
        actionData: {
          target: 'backend',
          source: 'packages/sortedpackages/',
          url: '/api/v1/packages/sortedpackages/remove_sorted_packages/',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    editIncomingPackage: {
      key: 'edit',
      type: 'edit',
      identifier: 'editIncomingPackage',
      configuration: {
        model: 'packages',
        url: '/api/v1/packages/incomingpackages/'
      }
    },
    editSortedPackage: {
      key: 'edit',
      type: 'edit',
      identifier: 'editSortedPackage',
      configuration: {
        model: 'packages',
        url: '/api/v1/packages/sortedpackages/'
      }
    },
    editPackage: {
      adminRequired: false,
      key: 'editPackage',
      format: {
        type: 'action',
        modalTo: '/perspective/loadedpackages/editpackagemodal',
        icon: 'split',
        style: { fontSize: '27px', color: 'black' }
      },
      title: modelFieldMessages.editJD
    },
    editScannedPackage: {
      adminRequired: false,
      key: 'generalEdit',
      additionalParameters: {
        wsAction: 'set-editscannedpackage'
      },
      format: {
        type: 'action',
        modalTo: '/perspective/loadedpackages/editpackagemodal',
        icon: 'split',
        style: { fontSize: '27px', color: 'black' }
      },
      title: modelFieldMessages.editJD
    },
    deleteControlPackage: {
      key: 'delete',
      type: 'deletePackage',
      dispatch: 'deletePackage',
      modal: {
        modal: true,
        buttonText: 'deletePackage',
        key: 'deletePackage',
        action: 'deletePackage',
        icon: 'deleteIcon',
        actionData: {
          target: 'control',
          source: 'packagesqueue',
          identifier: 'package-loaded-delete',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    unusedPackageRemaining: {
      key: 'unusedPackageRemaining',
      type: 'unusedPackageRemaining',
      dispatch: 'unusedPackageRemaining',
      modal: {
        modal: true,
        buttonText: 'unusedPackageRemaining',
        key: 'unusedPackageRemaining',
        action: 'unusedPackageRemaining',
        icon: 'deleteIcon',
        actionData: {
          target: 'control',
          source: 'packagesqueue',
          identifier: 'send-unused-package-data',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    printSticker: {
      key: 'print',
      format: {
        type: 'urlaction',
        action: 'printSticker',
        icon: 'print',
        data: { printer: 'outfeedPrinter', label: 'package_label' },
        style: { fontSize: '18px', color: 'black', cursor: 'pointer' }
      },
      title: modelFieldMessages.print
    },
    printSticker2: {
      key: 'print',
      format: {
        type: 'urlaction',
        action: 'printSticker',
        icon: 'print',
        data: { printer: 'outfeedPrinter2', label: 'package_label2' },
        style: { fontSize: '18px', color: 'black', cursor: 'pointer' }
      },
      title: modelFieldMessages.print
    },
    sendToLoaded: {
      key: 'print',
      format: {
        type: 'wsaction',
        icon: 'sendTo',
        identifier: 'send-to-loaded',
        style: { fontSize: '18px', color: 'black', cursor: 'pointer' }
      },
      title: modelFieldMessages.sendToLoaded
    },
    syncWithErp: {
      key: 'syncWithErp',
      type: 'controlAction',
      dispatch: 'syncWithErp',
      format: {
        icon: 'SyncIcon',
        style: { fontSize: '27px', color: 'black' }
      },
      adminRequired: false,
      modal: {
        modal: true,
        buttonText: 'syncWithErpTitle',
        key: 'syncWithErp',
        action: 'syncWithErp',
        icon: '',
        title: '',
        body: '',
        actionData: {
          target: 'control',
          source: 'packagesqueue',
          identifier: 'sync-package',
          items: 'selected',
          callType: 'POST'
        }
      }
    }
  };
}

export default IncomingPackage;
