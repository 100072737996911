/**
 *
 * OrdersQueue Container
 *
 */

import { useEffect, memo, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import FormattedMessage from '../../components/TranslationWrapper';
import selectWebsocket from '../Websocket/selectors';
import Modal from '../../components/UI/Modal';
import ModalHeader from '../../components/LedModal/ModalHeader';
import ModalFooter from '../../components/LedModal/ModalFooter';
import ModalContent from '../../components/LedModal/ModalContent';
import reducer from './reducer';
import saga from './saga';
import _ from 'lodash';
import makeEditControlQueue from './selectors';
import styled from 'styled-components';
import Input from '../../components/reusable/Input';
import { withStyles } from '@material-ui/core/styles';
import { setCompartment } from './actions';
import editSettingsList from './config/editList';
import EditOrderView from './generateOrderView.js';
import { Accordion, AccordionItem } from 'react-sanfona';
import IconButton from '@mui/material/IconButton';
import globalMessages from '../../globalMessages';
import { IconClose } from '../../components/reusable/Icons';
import { Tabs, Tab, Box } from '@material-ui/core';
import useUnitConverter from '../../utils/hooks/useUnitConverter.js';
import { conversionsConfig } from './config/conversionsConfig.js';
import { sendToWs } from '../Websocket/actions.js';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

(props) => <TextField color="primary" {...props} />;

const CustomInputLabel = withStyles({
  root: {
    color: '#636f83',
    fontWeight: 'bold',
    marginBottom: '0',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center'
  }
})((props) => <InputLabel color="primary" {...props} />);

const CustomCheckbox = withStyles({
  root: {
    color: '#e55353',
    '&$checked': {
      color: '#0c963a'
    }
  },
  checked: {}
})((props) => <Checkbox color="primary" {...props} />);

const PropertyField = styled.div`
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 5px;
  width: 10vw;
  margin-right: 10px;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export function EditControlModal(props) {
  useInjectReducer({ key: 'editcontrol', reducer });
  useInjectSaga({ key: 'editcontrol', saga });
  const { convertUnit, unitSystem } = useUnitConverter();

  /* Another way to get project name ( and not from global state) */
  const editSettings = editSettingsList?.default;

  const dispatch = useDispatch();
  // const history = useHistory();
  const action = props.action;
  let title = '';
  const fieldIDs = {};
  let validationError = false;
  const productionstatus = _.get(props, 'websocket.websocket.cltstatus.map', null);

  const [newState, setNewState] = useState({
    activeItems: [],
    test: true,
    jdName: 'joinedDimensionsNew',
    lastHash: ''
  });
  const [tabIndex, setTabIndex] = useState(0);
  const [cherryPick, setCherryPick] = useState(undefined);
  const [reverseCherryPick, setReverseCherryPick] = useState(undefined);
  const [tempValues, setTempValues] = useState(() => {
    return Object.keys(fieldIDs).reduce((acc, key) => {
      acc[key] = getValue(fieldIDs[key]) || '';
      return acc;
    }, {});
  });

  const handleTempValueChange = (key, newValue) => {
    setTempValues((prev) => ({
      ...prev,
      [key]: newValue
    }));
  };

  function handleChange({ activeItems }) {
    setNewState({ activeItems });
  }

  function getCherryPickFor(window) {
    for (let i = 0; i < editSettings.length; i++) {
      if (editSettings[i][window]?.cherryPick) {
        setCherryPick(editSettings[i][window].cherryPick);
        setReverseCherryPick(editSettings[i][window].reverseCherryPick);
        return;
      } else if (editSettings[i][window]?.reverseCherryPick) {
        setReverseCherryPick(editSettings[i][window].reverseCherryPick);
        setCherryPick(null);
        return;
      }
    }
    setReverseCherryPick(null);
    setCherryPick(null);
  }

  useEffect(() => {
    getCherryPickFor(action);
  }, [action]);

  useEffect(() => {
    if (cherryPick !== undefined) setNewState(initialize(action));
    if (reverseCherryPick !== undefined) setNewState(initialize(action));
  }, [reverseCherryPick, cherryPick]);

  /* R+Formatted messages parse */
  // var messagesBuild = {};
  // console.log("messagesBuild: ", messagesBuild);
  function checkIfAllowed(level, property) {
    if (cherryPick != null) {
      return level > 0 || cherryPick.indexOf(property) >= 0;
    } else if (reverseCherryPick != null) {
      return level > 0 || reverseCherryPick.indexOf(property) < 0;
    } else {
      return true;
    }
  }

  function initialize(string) {
    const newState = {};
    /* -- Settings for edit unique to project -- */
    editSettings.map((setting) => {
      const key = String(Object.keys(setting));
      if (string === key) {
        title = setting[key].title;
        const properties = {};
        setting[key].settings.map((set) => {
          const k = String(Object.keys(set));
          const type = set[k].type;
          const identifier = set[k].identifier;
          const prefix = _.get(props, 'machineId', '');
          switch (type) {
            case 'status':
              properties[k] = productionstatus != null ? productionstatus[prefix + identifier] : 0;
              break;
            case 'nested-status':
              const childIdent = set[k].childIdent;
              console.log();
              properties[k] = productionstatus != null ? productionstatus[identifier][childIdent] : 0;
              break;
            case 'nested-nested-status':
              const child = set[k].childIdent;
              const childChild = set[k].childChildIdent;
              properties[k] = productionstatus != null ? productionstatus[identifier][child][childChild] : 0;
              break;
            case 'boolStatus':
              properties[k] = _.get(productionstatus, prefix + identifier, null);
              break;
            case 'bool':
              properties[k] = false;
              break;
            case 'value':
              const val = set[k].value;
              properties[k] = val;
              break;
            default:
              break;
          }
        });

        newState.item = properties;
        newState.view = generateViewObject(newState.item, 0, [], true);
        return newState;
      }
    });

    /* -- Settings for edit commonly used along all project -- */
    if (string === 'missing-lamella-request') {
      title = 'Missing lamella request';
      const prefix = _.get(props, 'websocket.modalmanager.submodalInfo.modal-edit.data.prefix', '');
      let usedPrefix = '';
      if (prefix.length > 0) {
        usedPrefix = prefix + '-';
      }

      newState.item = {
        layerId: _.get(productionstatus, usedPrefix + 'producing.id', 0),
        numLamellas: 0,
        urgentRequest: false
      };
      newState.view = generateViewObject(newState.item, 0, [], true);
      return newState;
    }

    if (string === 'missing-plate-request') {
      title = 'Missing plate request';
      const prefix = _.get(props, 'websocket.modalmanager.submodalInfo.modal-edit.data.prefix', '');
      let usedPrefix = '';
      if (prefix.length > 0) {
        usedPrefix = prefix + '-';
      }

      newState.item = {
        layerId: _.get(productionstatus, usedPrefix + 'producing.id', 0),
        rawWidth: 0,
        urgentRequest: false
      };
      newState.view = generateViewObject(newState.item, 0, [], true);
      return newState;
    }

    if (string === 'extra-lamella-request') {
      title = 'Extra lamella request';
      let prefix = _.get(props, 'websocket.modalmanager.submodalInfo.modal-edit.data.prefix', '');
      let usedPrefix = '';
      if (prefix.length > 0) {
        usedPrefix = prefix + '-';
      } else {
        prefix = 'default';
      }
      newState.item = {
        lamellaLength: _.get(productionstatus, 'globalSettings.extraLamellaLength', 0),
        numLamellas: 0,
        lamellaWidth: _.get(productionstatus, usedPrefix + 'producing.lamellaWidth', 0),
        lamellaThickness: _.get(productionstatus, usedPrefix + 'producing.lamellaThickness', 0),
        plannedLamellaWidth: _.get(productionstatus, usedPrefix + 'producing.lamellaPlannedWidth', 0),
        plannedLamellaThickness: _.get(productionstatus, usedPrefix + 'producing.lamellaPlannedThickness', 0),
        quality: _.get(productionstatus, usedPrefix + 'producing.quality', ''),
        FJMachine: prefix,
        urgentRequest: false
      };
      newState.view = generateViewObject(newState.item, 0, [], true);
      return newState;
    }
    if (string === 'test-lamella-request') {
      title = 'Test lamella request';
      let prefix = _.get(props, 'websocket.modalmanager.submodalInfo.modal-edit.data.prefix', '');
      let usedPrefix = '';
      if (prefix.length > 0) {
        usedPrefix = prefix + '-';
      }
      newState.item = {
        lamellaLength: _.get(productionstatus, 'globalSettings.testLamellaLength', 0),
        numLamellas: 1,
        lamellaWidth: _.get(productionstatus, usedPrefix + 'producing.lamellaWidth', 0),
        lamellaThickness: _.get(productionstatus, usedPrefix + 'producing.lamellaThickness', 0),
        plannedLamellaWidth: _.get(productionstatus, usedPrefix + 'producing.lamellaPlannedWidth', 0),
        plannedLamellaThickness: _.get(productionstatus, usedPrefix + 'producing.lamellaPlannedThickness', 0),
        quality: _.get(productionstatus, usedPrefix + 'producing.quality', ''),
        FJMachine: prefix,
        urgentRequest: false
      };
      newState.view = generateViewObject(newState.item, 0, [], true);
      return newState;
    }
    if (string === 'waste-lamella-request') {
      title = 'Waste lamella request';
      let prefix = _.get(props, 'websocket.modalmanager.submodalInfo.modal-edit.data.prefix', '');
      let usedPrefix = '';
      if (prefix.length > 0) {
        usedPrefix = prefix + '-';
      }
      newState.item = {
        lamellaLength: _.get(productionstatus, 'globalSettings.wasteLamellaLength', 0),
        numLamellas: 0,
        lamellaWidth: _.get(productionstatus, usedPrefix + 'producing.lamellaWidth', 0),
        lamellaThickness: _.get(productionstatus, usedPrefix + 'producing.lamellaThickness', 0),
        plannedLamellaWidth: _.get(productionstatus, usedPrefix + 'producing.lamellaPlannedWidth', 0),
        plannedLamellaThickness: _.get(productionstatus, usedPrefix + 'producing.lamellaPlannedThickness', 0),
        quality: _.get(productionstatus, usedPrefix + 'producing.quality', ''),
        FJMachine: prefix,
        urgentRequest: false
      };
      newState.view = generateViewObject(newState.item, 0, [], true);
      return newState;
    }

    if (string === 'set-settings') {
      title = 'Set Settings';
      if (productionstatus?.globalSettings != undefined) {
        newState.item = generateGlobalSettingsFromControl(productionstatus.globalSettings);
        newState.view = generateViewObject(newState.item, 0, [], true);
        return newState;
      }
    }
    if (string === 'set-infeed-package') {
      title = 'Set Infeed Package';
      newState.item = props.pack;
      newState.view = generateViewObject(newState.item, 0, [], true);
      return newState;
    }
    if (string === 'set-RCStack') {
      console.log('set-RCStack: ', props);
      title = 'Set RCStack';
      newState.item = productionstatus.RCStack[props.part];
      newState.item.index = props.part;
      newState.view = generateViewObject(newState.item, 0, [], true);
      return newState;
    }
    if (string === 'set-editjd') {
      title = 'Set JD';
      newState.item = props.websocket.modalmanager.submodalInfo['modal-edit'].data;
      newState.view = generateViewObject(newState.item, 0, [], true, cherryPick);
      return newState;
    }
    if (string === 'set-editpackage') {
      title = 'Set Package';
      newState.item = props.websocket.modalmanager.submodalInfo['modal-edit'].data;
      newState.view = generateViewObject(newState.item, 0, [], true);
      return newState;
    }
    if (string === 'set-editproduct') {
      title = 'Set Package';
      newState.item = props.websocket.modalmanager.submodalInfo['modal-edit'].data;
      newState.view = generateViewObject(newState.item, 0, [], true);
      return newState;
    }
    if (string === 'set-editLayer') {
      title = 'Set Layer';
      newState.item = props.websocket.modalmanager.submodalInfo['modal-edit'].data;
      newState.view = generateViewObject(newState.item, 0, [], true);
      return newState;
    }

    if (string === 'set-editControlOrder') {
      title = 'Set ControlOrder';
      newState.item = props.websocket.modalmanager.submodalInfo['modal-edit'].data;
      newState.view = generateViewObject(newState.item, 0, [], true);
      newState.activeItems = [];
      return newState;
    }

    if (string === 'general-edit') {
      title = 'General edit';
      newState.item = props.websocket.modalmanager.submodalInfo['modal-edit'].data;
      newState.view = generateViewObject(newState.item, 0, [], true);
      newState.activeItems = [];
      return newState;
    }

    return newState;
  }

  function generateViewObject(item, level, path, init) {
    const view = {};
    for (const property in item) {
      const allowed = checkIfAllowed(level, property);

      switch (typeof item[property]) {
        case 'boolean':
          if (allowed) {
            view[property] = {
              type: typeof item[property],
              value: item[property],
              level,
              path: path.concat([property])
            };
          }
          break;
        case 'string':
          if (allowed) {
            view[property] = {
              type: typeof item[property],
              value: item[property],
              level,
              path: path.concat([property])
            };
          }
          break;
        case 'number':
          if (allowed) {
            view[property] = {
              type: typeof item[property],
              value: item[property],
              level,
              path: path.concat([property])
            };
          }
          break;
        case 'object':
          if (allowed) {
            if (item[property] === null) {
              // if object and null assume it's a string for now
              view[property] = {
                type: 'string',
                value: '',
                level,
                path: path.concat([property])
              };
            } else {
              view[property] = {
                type: typeof item[property],
                value: null,
                level,
                path: path.concat([property])
              };
              const temp = generateViewObject(item[property], level + 1, path.concat([property]), false);
              view[property].value = temp;
            }
          }
          break;
        default:
          if (allowed) {
            view[property] = {
              type: 'string',
              value: '',
              level,
              path: path.concat([property])
            };
          }
      }
    }

    return view;
  }

  function changeValue(path, value) {
    const stateClone = { ...newState.item };

    const nestedObject = path.slice(0, -1).reduce((object, part) => (object === undefined ? undefined : object[part]), stateClone);

    if (nestedObject !== undefined) {
      const [pathTail] = path.slice(-1);

      if (conversionsConfig[pathTail] && value !== '') {
        const { fromUnit, toUnit, unitType } = conversionsConfig[pathTail];
        value = convertUnit(value, unitType, toUnit, fromUnit, 3);
      }

      nestedObject[pathTail] = value; // Allow empty values to be set
    }

    setNewState((prevState) => ({
      ...prevState,
      item: stateClone,
      view: generateViewObject(stateClone, 0, [], true)
    }));
  }

  function getInputFromItem(viewitem, property) {
    if (fieldIDs['id' + viewitem.path] === undefined) {
      fieldIDs['id' + viewitem.path] = getValue(viewitem.path);
    }

    const inputId = 'id' + viewitem.path;
    const tempValue = inputId in tempValues ? tempValues[inputId] : getValue(viewitem.path) || '';

    const handleInputChange = (event) => {
      const value = event?.target?.value ?? event;
      handleTempValueChange(inputId, value);
    };

    return (
      <Input
        className-={'MuiInputBase-input MuiOutlinedInput-input'}
        enabled
        key={'input' + viewitem.path}
        editable={true}
        id={inputId}
        helperText={tempValue === '' ? <FormattedMessage {...globalMessages.emptyError} /> : []}
        error={tempValue === ''}
        onChange={handleInputChange}
        label={generateNameFromProperty(property)}
        value={tempValue}
        size={'small'}
      />
    );
  }

  function generateGlobalSettingsFromControl(globalSettings) {
    const createdGlobalSettings = {};
    Object.keys(globalSettings).forEach(function (key) {
      globalSettings[key] != null ? (createdGlobalSettings[key] = globalSettings[key]) : (createdGlobalSettings[key] = 0);
    });
    return createdGlobalSettings;
  }

  function generateNameFromProperty(property) {
    return <FormattedMessage {...globalMessages[property]} identifier={property} />;
  }

  function getValue(path) {
    let item = newState.item;
    let lastPath = '';
    for (let i = 0; i < path.length; i++) {
      item = item[path[i]];
      lastPath = path[i];
    }

    if (item === 0) item = '0';
    if (item == null) item = 'null';
    if (conversionsConfig[lastPath]) {
      const { fromUnit, toUnit, unitType } = conversionsConfig[lastPath];
      if (item != '') {
        return convertUnit(item, unitType, fromUnit, toUnit, 3);
      }
    }
    return item;
  }

  function generateOrderView(view, generateObjects = false) {
    const booleanViewArrayOrder = [];
    const otherViewArrayOrder = [];
    const viewArrayLayer = [];
    const [expandedIndex, setExpandedIndex] = useState(null);

    const handleAccordionToggle = (index) => {
      setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    for (const property in view) {
      if (property !== 'layers') {
        if (view[property].type === 'object' && generateObjects) {
          otherViewArrayOrder.push(
            <b key={property}>
              <InputLabel>{property}</InputLabel>
            </b>
          );
          otherViewArrayOrder.push(generateView(view[property].value, generateObjects));
        } else if (view[property].type === 'object' && !generateObjects) {
          continue;
        } else {
          const viewitem = view[property];
          switch (viewitem.type) {
            case 'boolean':
              booleanViewArrayOrder.push(
                <div key={property} classname="genv">
                  <PropertyField>
                    <CustomInputLabel style={{ height: '38px' }}>{generateNameFromProperty(property)}</CustomInputLabel>
                    <CustomCheckbox
                      style={{ textAlign: 'center' }}
                      name={String(view[property].path)}
                      id={'id' + String(view[property.path])}
                      checked={view[property].value}
                      onClick={(e) => changeValue(viewitem.path, e.target.checked)}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </PropertyField>
                  <br />
                </div>
              );
              break;
            default:
              otherViewArrayOrder.push(
                <div key={property} className="genv">
                  <div>
                    <PropertyField>{getInputFromItem(viewitem, property)}</PropertyField>
                  </div>
                  <br />
                </div>
              );
          }
        }
      } else {
        for (let i = 0; i < Object.keys(view[property].value).length; i++) {
          viewArrayLayer.push(
            <AccordionItem
              key={i}
              onExpand={() => handleAccordionToggle(i)}
              onClose={() => handleAccordionToggle(i)}
              title={
                <div className="layerBtn">
                  Layer {i + 1}
                  {expandedIndex === i ? <ExpandLess className="icon" /> : <ExpandMore className="icon" />}
                </div>
              }
              collapsed={expandedIndex !== i}
              style={{ marginLeft: '10px' }}>
              {generateView(view[property].value[i].value, false)}
            </AccordionItem>
          );
        }
      }
    }
    return (
      <EditOrderView style={{ maxHeight: '100%', height: '100%' }}>
        <div style={{ width: '100%', display: 'flex', height: '100%' }}>
          <div style={{ width: '50%' }}>
            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', fontSize: '1.5em', fontWeight: 'bold' }}>
              <FormattedMessage {...globalMessages.orderInfo} identifier={'orderInfo'} />
            </div>
            <div>
              <div style={{ display: 'flow', flexWrap: 'wrap' }}>
                <div style={{ flex: '1', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>{otherViewArrayOrder}</div>
                <div style={{ flex: '2', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>{booleanViewArrayOrder}</div>
              </div>
            </div>
          </div>
          <div style={{ width: '2px', background: 'rgba(0, 0, 0, 0.3)', minHeight: '100%' }} />
          <div style={{ width: '50%' }}>
            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', fontSize: '1.5em', fontWeight: 'bold' }}>
              <FormattedMessage {...globalMessages.layerInfo} identifier={'layerInfo'} />
            </div>
            <div className="accord">
              <Accordion>{viewArrayLayer}</Accordion>
            </div>
          </div>
        </div>
      </EditOrderView>
    );
  }

  function generateView(view, generateObjects = true) {
    const booleanElements = [];
    const otherElements = [];

    for (const property in view) {
      if (view[property].type === 'object' && generateObjects) {
        otherElements.push(
          <b key={property}>
            <InputLabel>{property}</InputLabel>
          </b>
        );
        otherElements.push(generateView(view[property].value, generateObjects));
      } else if (view[property].type === 'object' && !generateObjects) {
        continue;
      } else {
        const viewitem = view[property];
        switch (viewitem.type) {
          case 'boolean':
            booleanElements.push(
              <div key={property} className="genv">
                <PropertyField>
                  <CustomInputLabel style={{ height: '38px' }}>{generateNameFromProperty(property)}</CustomInputLabel>
                  <CustomCheckbox
                    style={{ textAlign: 'center' }}
                    name={String(view[property].path)}
                    id={'id' + String(view[property.path])}
                    checked={view[property].value}
                    onClick={(e) => changeValue(viewitem.path, e.target.checked)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </PropertyField>
                <br />
              </div>
            );
            break;
          default:
            otherElements.push(
              <div key={property} className="genv">
                <PropertyField>{getInputFromItem(viewitem, property)}</PropertyField>
                <br />
              </div>
            );
        }
      }
    }

    return (
      <div style={{ display: 'flow', flexWrap: 'wrap' }}>
        <div style={{ flex: '1', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>{otherElements}</div>
        <div style={{ flex: '2', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>{booleanElements}</div>
      </div>
    );
  }

  function generateViewWithTabs(view) {
    const tabs = [];
    const tabContents = [];

    tabs.push('generalSettings');
    tabContents.push(generateView(view, false));

    for (const property in view) {
      if (view[property].type === 'object') {
        tabs.push(property);
        tabContents.push(generateView(view[property].value));
      }
    }

    const handleTabChange = (event, newValue) => {
      setTabIndex(newValue);
    };

    return (
      <div>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="settings tabs" variant="scrollable" scrollButtons="auto">
          {tabs.map((tab, index) => (
            <Tab label={<FormattedMessage {...globalMessages[tab]} identifier={tab} />} key={index} />
          ))}
        </Tabs>
        {tabContents.map((content, index) => (
          <TabPanel value={tabIndex} index={index} key={index}>
            {content}
          </TabPanel>
        ))}
      </div>
    );
  }

  function hasClass(element, cls) {
    if (element == null) return false;
    return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1;
  }

  function sendToControl(changedsettings) {
    //console.log('sendToControl: ', changedsettings);
    validationError = false;
    for (const k in fieldIDs) {
      const el = document.getElementById(k + '-label');
      const findError = hasClass(el, 'Mui-error');
      if (findError) {
        validationError = true;
        break;
      }
    }

    for (const key in fieldIDs) {
      const fieldPath = key.replace('id', '');
      const rawValue = document.getElementById(key)?.value || '';

      if (conversionsConfig[fieldPath] && rawValue !== '' && unitSystem != 'metric') {
        const { fromUnit, toUnit, unitType } = conversionsConfig[fieldPath];
        const convertedValue = convertUnit(rawValue, unitType, toUnit, fromUnit, 3);
        _.set(changedsettings, fieldPath, convertedValue);
      } else {
        _.set(changedsettings, fieldPath, rawValue);
      }
    }

    if (_.get(props, 'machineId', false)) {
      changedsettings.machineId = props.machineId;
    }

    //console.log('sendToControl2: ', props);

    let wsActionIdentifier = _.get(changedsettings, 'additionalParameters.wsAction', props.action);
    if (true) {
      dispatch(sendToWs(changedsettings, wsActionIdentifier));
      setTimeout(() => {
        props.close(props.action);
      }, 500);
    }
  }

  return (
    <Modal>
      <div style={props.style}>
        <ModalHeader>
          Edit
          <IconButton onClick={() => props.close(props.action)} style={{ float: 'right', color: 'white' }}>
            <IconClose />
          </IconButton>
        </ModalHeader>
        <ModalContent>
          {props.action && props.action === 'set-editControlOrder' ? (
            <div style={{ padding: '1rem', width: '100%', height: '100%' }}>{generateOrderView(newState.view)}</div>
          ) : props.action && props.action === 'set-settings' ? (
            <div style={{ padding: '1rem', width: '100%' }}>{generateViewWithTabs(newState.view)}</div>
          ) : (
            <div style={{ padding: '1rem', width: '100%' }}>{generateView(newState.view)}</div>
          )}
        </ModalContent>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-success"
            onClick={() => sendToControl(newState.item)}
            style={{
              float: 'left',
              color: 'white',
              marginRight: '50px',
              overflow: 'hidden'
            }}>
            <FormattedMessage {...globalMessages.save} identifier={'save'} />
          </button>
          <button type="button" className="btn btn-secondary" onClick={() => props.close(props.action)} style={{ float: 'left', marginLeft: '10px' }}>
            <FormattedMessage {...globalMessages.cancel} identifier={'cancel'} />
          </button>
        </ModalFooter>
      </div>
    </Modal>
  );
}

EditControlModal.propTypes = {
  orderFields: PropTypes.array,
  status: PropTypes.object,
  item: PropTypes.object,
  closeFn: PropTypes.func,
  pack: PropTypes.object,
  id: PropTypes.number
};

const mapStateToProps = createStructuredSelector({
  editcontrol: makeEditControlQueue(),
  websocket: selectWebsocket()
});

function mapDispatchToProps(dispatch) {
  return {
    // setCompartment: () => dispatch(setCompartment()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(EditControlModal);
