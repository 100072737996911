import modelFieldMessages from './modelFieldMessages';
import mainUtilsConfig from './utilsConfig';
import models from './models';
import { Select, MenuItem, FormControl, InputLabel, Typography } from '@mui/material';
import { sendToWs } from '../containers/Websocket/actions';
import { backendCall } from '../containers/TableView/actions';

class lengthsQuality {
  static fields = {
    quality: {
      key: 'quality',
      type: 'string',
      title: modelFieldMessages.quality
    },
    lamellaLength: {
      key: 'lamellaLength',
      type: 'string',
      title: modelFieldMessages.lamellaLength
    },
    lengthMiddleCut1: {
      key: 'lengthMiddleCut1',
      type: 'string',
      title: modelFieldMessages.lengthMiddleCut1
    },
    lengthMiddleCut2: {
      key: 'lengthMiddleCut2',
      type: 'string',
      title: modelFieldMessages.lengthMiddleCut2
    },
    lamellaClass: {
      key: 'lamellaClass',
      type: 'string',
      choices: [
        { id: 'lamellaClass.I-III', defaultMessage: 'I-III' },
        { id: 'lamellaClass.III-V', defaultMessage: 'III-V' },
        { id: 'lamellaClass.VI', defaultMessage: 'VI' },
        { id: 'lamellaClass.0-V', defaultMessage: '0-V' },
        { id: 'lamellaClass.Radial', defaultMessage: 'Radial' }
      ],
      title: modelFieldMessages.lamellaClass
    },
    wrapping: {
      key: 'wrapping',
      type: 'string',
      choices: [
        { id: 'wrapping.Solis-velika', defaultMessage: 'Solis-velika' },
        { id: 'wrapping.Solis-mala', defaultMessage: 'Solis-mala' },
        { id: 'wrapping.Nevtralna', defaultMessage: 'Nevtralna' },
        { id: 'wrapping.Dobaviteljeva', defaultMessage: 'Dobaviteljeva' },
        { id: 'wrapping.Brez', defaultMessage: 'Brez' }
      ],
      title: modelFieldMessages.wrapping
    },
    numberOfLayers: {
      key: 'numberOfLayers',
      type: 'string',
      title: modelFieldMessages.numberOfLayers
    },
    numOfLamellas: {
      key: 'numOfLamellas',
      type: 'string',
      title: modelFieldMessages.numOfLamellas
    },
    moscaNumberOfLayers: {
      key: 'moscaNumberOfLayers',
      type: 'string',
      title: modelFieldMessages.moscaNumberOfLayers
    },
    moscaNumOfLamellas: {
      key: 'moscaNumOfLamellas',
      type: 'string',
      title: modelFieldMessages.moscaNumOfLamellas
    },
    lengthHeadCut: {
      key: 'lengthHeadCut',
      type: 'string',
      title: modelFieldMessages.lengthHeadCut
    }
  };
}

class BSHOrder {
  static packageTypeChoices = {
    Q: modelFieldMessages.qPackageType,
    L: modelFieldMessages.lPackageType
  };

  static productChoices = {
    0: modelFieldMessages.brunoKVH,
    1: modelFieldMessages.brunoLohnzinken,
    2: modelFieldMessages.brunoAusschhuss
  };

  static lineRegimeChoices = {
    0: modelFieldMessages.bypass,
    1: modelFieldMessages.bypassSmarty,
    2: modelFieldMessages.splitcut,
    3: modelFieldMessages.multiplanSmarty
  };

  static fields = {
    dynamicCheckBox: {
      key: 'check',
      format: {
        type: 'dynamicCheckbox',
        enableFunction: function (item, checkedItems) {
          if (_.get(item, 'status', 'confirmed') == 'unconfirmed') {
            return true;
          }

          return false;
        }
      }
    },
    group: {
      key: 'group',
      title: modelFieldMessages.group,
      format: {
        type: 'object',
        str: '{id}',
        defaultStr: '',
        keys: ['id']
      }
    },
    lengthsQualityData: {
      key: 'lengthsQuality',
      type: 'ListViewAdd',
      format: [
        lengthsQuality.fields.quality,
        lengthsQuality.fields.lamellaLength,
        lengthsQuality.fields.lengthMiddleCut1,
        lengthsQuality.fields.lengthMiddleCut2,
        lengthsQuality.fields.lamellaClass,
        lengthsQuality.fields.numberOfLayers,
        lengthsQuality.fields.numOfLamellas,
        lengthsQuality.fields.moscaNumberOfLayers,
        lengthsQuality.fields.moscaNumOfLamellas,
        lengthsQuality.fields.lengthHeadCut,
        lengthsQuality.fields.wrapping
      ],
      title: modelFieldMessages.lengthQualityData
    },
    bshorderColor: {
      key: 'orderColor',
      type: 'string',
      format: {
        type: 'color'
      }
    },
    layers: {
      key: 'layers',
      type: 'foreignkey',
      title: modelFieldMessages.layers
    },
    combinedOrderColor: {
      key: 'combinedColors',
      type: 'string',
      format: {
        type: 'combinedColor'
      }
    },
    status: {
      key: 'status',
      type: 'string',
      title: modelFieldMessages.status
    },
    stackingName: {
      key: 'stackingName',
      type: 'string',
      title: modelFieldMessages.stackingName
    },
    orderCharge: {
      key: 'orderCharge',
      type: 'string',
      title: modelFieldMessages.orderCharge
    },
    projectName_schilliger: {
      key: 'projectName',
      type: 'string',
      title: modelFieldMessages.projectName_schilliger
    },
    layerId: {
      key: 'layerId',
      type: 'string',
      title: modelFieldMessages.layerId
    },
    identifier: {
      key: 'identifier',
      type: 'string',
      title: modelFieldMessages.identifier
    },
    paNr: {
      key: 'paNr',
      type: 'string',
      title: modelFieldMessages.paNr
    },
    noLayers: {
      key: 'layers',
      type: 'arraylength',
      title: modelFieldMessages.layers
    },
    oversizeWidth: {
      key: 'oversizeWidth',
      type: 'string',
      title: modelFieldMessages.oversizeWidth,
      blank: false,
      validation: ['float']
    },
    oversize: {
      key: 'oversize',
      type: 'string',
      title: modelFieldMessages.oversize,
      blank: false,
      validation: ['float']
    },
    id: {
      key: 'id',
      type: 'string',
      title: modelFieldMessages.id,
      input: 'number',
      step: '1'
    },
    pressId: {
      key: 'pressId',
      type: 'string',
      title: modelFieldMessages.pressId
    },
    woodType: {
      key: 'woodType',
      type: 'string',
      title: modelFieldMessages.woodType
    },
    splitLamella: {
      key: 'splitLamella',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.splitLamella
    },
    stagger: {
      key: 'stagger',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.stagger
    },
    // quality: {
    //     key: 'quality',
    //     type: 'string',
    //     title: modelFieldMessages.orderQuality,
    //     blank: false,
    //     input: 'text',
    // },
    quality: {
      key: 'quality',
      type: 'string',
      title: modelFieldMessages.quality
    },
    orderNumber: {
      key: 'orderNumber',
      type: 'string',
      title: modelFieldMessages.orderNumber,
      blank: false,
      input: 'text'
    },
    jobNumber: {
      key: 'jobNumber',
      type: 'string',
      title: modelFieldMessages.jobNumber,
      blank: false,
      input: 'text'
    },
    smartlamOrderNumber: {
      key: 'orderNumber',
      type: 'string',
      title: modelFieldMessages.smartlamOrderNumber,
      blank: false,
      input: 'text'
    },
    orderImportNumber: {
      key: 'orderImportNumber',
      type: 'string',
      title: modelFieldMessages.orderImportNumber,
      blank: false,
      input: 'text'
    },
    erp_order_id: {
      key: 'erp_order_id',
      type: 'string',
      title: modelFieldMessages.erp_order_id,
      input: 'text'
    },
    assignedPress: {
      key: 'assignedPress',
      type: 'string',
      title: modelFieldMessages.assignedPress,
      input: 'text'
    },
    client: {
      key: 'client',
      type: 'string',
      title: modelFieldMessages.clientTitle,
      blank: false,
      filterable: true
    },
    pieces: {
      key: 'pieces',
      type: 'string',
      title: modelFieldMessages.piecesTitle,
      width: 2
    },
    incomingLamellaWidth: {
      key: 'incomingLamellaWidth',
      title: modelFieldMessages.incomingLamellaWidth,
      type: 'string'
    },
    incomingLamellaThickness: {
      key: 'incomingLamellaThickness',
      title: modelFieldMessages.incomingLamellaThickness,
      type: 'string'
    },
    lamellaWidth: {
      title: modelFieldMessages.lamellaWidth,
      key: 'lamellaWidth',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      modelValidation: [
        {
          valueLimit: 'above',
          fromGlobal: true,
          globalKey: 'maxLamellaWidth',
          errorMessage: 'lamellaTooWide'
        },
        {
          valueLimit: 'below',
          fromGlobal: true,
          globalKey: 'minLamellaWidth',
          errorMessage: 'lamellaTooNarrow'
        }
      ],
      convert: true,
      fromUnit: 'millimeters',
      toUnit: 'inches',
      unitType: 'length'
    },
    lamellaThickness: {
      title: modelFieldMessages.lamellaThickness,
      key: 'lamellaThickness',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      modelValidation: [
        {
          valueLimit: 'above',
          fromGlobal: true,
          globalKey: 'maxLamellaThickness',
          errorMessage: 'lamellaTooThick'
        },
        {
          valueLimit: 'below',
          fromGlobal: true,
          globalKey: 'minLamellaThickness',
          errorMessage: 'lamellaTooLow'
        }
      ],
      convert: true,
      fromUnit: 'millimeters',
      toUnit: 'inches',
      unitType: 'length'
    },
    lamellaLength: {
      title: modelFieldMessages.lamellaLength,
      key: 'lamellaLength',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      modelValidation: [
        {
          valueLimit: 'above',
          fromGlobal: true,
          globalKey: 'maxLamellaLength',
          errorMessage: 'lamellaTooLong'
        },
        {
          valueLimit: 'below',
          fromGlobal: true,
          globalKey: 'minLamellaLength',
          errorMessage: 'lamellaTooShort'
        }
      ]
    },

    lamellaPlannedWidth: {
      title: modelFieldMessages.lamellaPlannedWidth,
      key: 'rawWidth',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero']
    },
    lamellaPlannedThickness: {
      title: modelFieldMessages.lamellaPlannedThickness,
      key: 'rawThickness',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.1'
    },
    rawWidth: {
      title: modelFieldMessages.rawWidth,
      key: 'rawWidth',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      convert: true,
      fromUnit: 'millimeters',
      toUnit: 'inches',
      unitType: 'length'
    },
    rawThickness: {
      title: modelFieldMessages.rawThickness,
      key: 'rawThickness',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.1',
      convert: true,
      fromUnit: 'millimeters',
      toUnit: 'inches',
      unitType: 'length'
    },
    /* pressureAvgFinal: {
            title: modelFieldMessages.pressureAvgFinal,
            key: 'pressureAwgFinal',
            type: 'string',
        }, */
    addedAt: {
      key: 'addedAt',
      title: modelFieldMessages.addedAt,
      format: {
        type: 'date'
      }
    },
    erpDate: {
      key: 'erpDate',
      title: modelFieldMessages.erpDate,
      format: {
        type: 'date'
      }
    },
    completedAt: {
      key: 'completedAt',
      title: modelFieldMessages.completedAt,
      format: {
        type: 'date'
      }
    },
    lineNumber: {
      key: 'line',
      title: modelFieldMessages.lineNumber
    },
    priority: {
      key: 'priority',
      type: 'string',
      title: modelFieldMessages.priority
    },
    dimensions: {
      key: 'dimensions',
      title: modelFieldMessages.dimensions,
      format: {
        type: 'object_from_many',
        str: '{lamellaThickness} x {lamellaWidth} x {lamellaLength} mm',
        keys: ['lamellaWidth', 'lamellaThickness', 'lamellaLength']
      }
    },
    dimensionsConverted: {
      key: 'dimensions',
      title: modelFieldMessages.dimensions,
      format: {
        type: 'conversion_from_many',
        str: '{lamellaThickness} x {lamellaWidth} x {lamellaLength} ',
        keys: [
          { key: 'lamellaThickness', fromUnit: 'millimeters', toUnit: 'inches' },
          { key: 'lamellaWidth', fromUnit: 'millimeters', toUnit: 'inches' },
          { key: 'lamellaLength', fromUnit: 'millimeters', toUnit: 'inches' }
        ],
        fromUnitLabel: 'mm',
        toUnitLabel: 'in',
        unitType: 'length'
      }
    },
    rawDimensions: {
      key: 'rawDimensions',
      title: modelFieldMessages.rawDimensions,
      format: {
        type: 'object_from_many',
        str: '{rawThickness} x {rawWidth} x {lamellaLength} mm',
        keys: ['rawWidth', 'rawThickness', 'lamellaLength']
      }
    },
    kvhDimensions: {
      key: 'dimensions',
      title: modelFieldMessages.kvhDimensions,
      format: {
        type: 'eval_function',
        str: "{isKVH} == true ? ({lamellaThickness} + ' x ' + {lamellaWidth} + ' x ' + {lamellaLength}) : ({panelThickness} + 'x' + {panelWidth} + 'x' + {panelLength})",
        keys: {
          isKVH: '{isKVH}',
          lamellaWidth: '{lamellaWidth}',
          lamellaLength: '{lamellaLength}',
          lamellaThickness: '{lamellaThickness}',
          panelThickness: '{panelThickness}',
          panelWidth: '{panelWidth}',
          panelLength: '{panelLength}'
        },
        evalFinalString: true
      }
    },
    orderDimensions: {
      key: 'dimensions',
      title: modelFieldMessages.lamellaDimensions,
      width: 1.5,
      format: {
        type: 'object_from_many',
        str: '{panelThickness} x {orderWidth} x {panelLength} mm',
        keys: ['panelThickness', 'orderWidth', 'panelLength']
      }
    },
    packageDimensions: {
      key: 'package_dimensions',
      title: modelFieldMessages.lamellaDimensions,
      width: 1.5,
      format: {
        type: 'object_from_many',
        str: '{lamellaThickness} x {lamellaWidth} x {lamellaLength} mm',
        keys: ['lamellaWidth', 'lamellaThickness', 'lamellaLength']
      }
    },
    incomingPackageDimensions: {
      key: 'package_dimensions',
      title: modelFieldMessages.incomingLamellaDimensions,
      width: 1.5,
      format: {
        type: 'object_from_many',
        str: '{incomingLamellaThickness} x {incomingLamellaWidth} mm',
        keys: ['incomingLamellaThickness', 'incomingLamellaWidth']
      }
    },
    type: {
      key: 'type',
      type: 'choices',
      title: modelFieldMessages.orderType,
      format: {
        type: 'discreteChoices',
        choices: BSHOrder.packageTypeChoices
      }
    },
    isKVH: {
      key: 'isKVH',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.isKVH
    },
    wrapping: {
      key: 'wrapping',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.wrapping
    },
    headCut: {
      key: 'headCut',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.headCut
    },
    middleCut: {
      key: 'middleCut',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.middleCut
    },
    endCut: {
      key: 'endCut',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.endCut
    },
    withMosca: {
      key: 'withMosca',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.withMosca
    },
    stopper: {
      key: 'stopper',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.stopper
    },
    jobCode: {
      key: 'jobCode',
      type: 'string',
      title: modelFieldMessages.jobCode,
      blank: false
    },
    rowsNumber: {
      key: 'rowsNumber',
      type: 'string',
      title: modelFieldMessages.rowsNumber,
      blank: false
    },
    colsNumber: {
      key: 'colsNumber',
      type: 'string',
      title: modelFieldMessages.colsNumber,
      blank: false
    },
    kvhBoards: {
      key: 'kvhBoards',
      type: 'string',
      title: modelFieldMessages.kvhBoards,
      blank: false
    },
    orderPicture: {
      key: 'picture',
      type: 'drawPicture',
      title: modelFieldMessages.picture,
      function: 'getCLTOrderImage'
    },
    stackingNumOfLayers: {
      key: 'stackingNumOfLayers',
      type: 'string',
      title: modelFieldMessages.stackingNumOfLayers,
      blank: false
    },
    finalBeamThickness: {
      type: 'string',
      key: 'finalBeamThickness',
      convert: true,
      fromUnit: 'millimeters',
      toUnit: 'inches',
      unitType: 'length',
      title: modelFieldMessages.finalBeamThickness,
      format: {
        type: 'conversion_from_many',
        str: '{finalBeamThickness} ',
        keys: [{ key: 'finalBeamThickness', fromUnit: 'millimeters', toUnit: 'inches' }],
        fromUnitLabel: 'mm',
        toUnitLabel: 'in',
        unitType: 'length'
      }
    },
    stackingLamellasPerLayer: {
      key: 'stackingLamellasPerLayer',
      type: 'string',
      title: modelFieldMessages.stackingLamellasPerLayer,
      blank: false
    },
    orderType: {
      key: 'orderType',
      title: modelFieldMessages.orderType,
      type: 'choices',
      format: {
        type: 'discreteChoices',
        retainNames: true,
        choices: BSHOrder.productChoices
      },
      blank: false
    },
    orderTypeString: {
      key: 'orderTypeString',
      type: 'string',
      title: modelFieldMessages.orderType,
      blank: false
    },
    lineRegimeNum: {
      key: 'lineRegimeNum',
      title: modelFieldMessages.lineRegime,
      type: 'choices',
      format: {
        type: 'discreteChoices',
        retainNames: true,
        choices: BSHOrder.lineRegimeChoices
      },
      blank: false
    },
    numOfLamellas: {
      key: 'numOfLamellas',
      title: modelFieldMessages.numOfLamellas,
      type: 'string',
      blank: false
    },
    numberOfLayers: {
      key: 'numberOfLayers',
      title: modelFieldMessages.numberOfLayers,
      type: 'string',
      blank: false
    },
    moscaNumberOfBoards: {
      key: 'moscaNumberOfBoards',
      title: modelFieldMessages.moscaNumberOfBoards,
      type: 'string',
      blank: true
    },
    lengthMiddleCut1: {
      key: 'lengthMiddleCut1',
      title: modelFieldMessages.lengthMiddleCut1,
      type: 'string',
      blank: true
    },
    lengthMiddleCut2: {
      key: 'lengthMiddleCut2',
      title: modelFieldMessages.lengthMiddleCut2,
      type: 'string',
      blank: true
    },
    numOfPackages: {
      key: 'numOfPackages',
      title: modelFieldMessages.numOfPackages,
      type: 'string',
      blank: false
    },
    zincDoneNumLamellas: {
      key: 'zincDoneNumLamellas',
      type: 'string',
      title: modelFieldMessages.zincDoneNumLamellas
    },
    direction: {
      key: 'direction',
      type: 'choices',
      title: modelFieldMessages.direction,
      format: {
        type: 'discreteChoices',
        choices: mainUtilsConfig.exitChoices
      }
    },
    iBeamNumber: {
      key: 'iBeamNumber',
      type: 'string',
      title: modelFieldMessages.iBeamNumber
    },
    glueType: {
      key: 'glueType',
      type: 'string',
      title: modelFieldMessages.glueType
    },
    iDestPress: {
      key: 'iDestPress',
      type: 'string',
      title: modelFieldMessages.iDestPress
    },
    iDestStorage: {
      key: 'iDestStorage',
      type: 'string',
      title: modelFieldMessages.zincDoneNumLamellas
    },
    iLayerInPress: {
      key: 'iLayerInPress',
      type: 'string',
      title: modelFieldMessages.iLayerInPress
    },
    iCostReceiver: {
      key: 'iCostReceiver',
      type: 'string',
      title: modelFieldMessages.iCostReceiver
    },
    iProdSequence: {
      key: 'iProdSequence',
      type: 'string',
      title: modelFieldMessages.iProdSequence
    },
    iStackHeight: {
      key: 'iStackHeight',
      type: 'string',
      title: modelFieldMessages.iStackHeight
    },
    iChinkSaw: {
      key: 'iChinkSaw',
      type: 'string',
      title: modelFieldMessages.iChinkSaw
    },
    finishedLamellaThickness: {
      key: 'finishedLamellaThickness',
      type: 'string',
      title: modelFieldMessages.finishedLamellaThickness
    },
    finishedLamellaWidth: {
      key: 'finishedLamellaWidth',
      type: 'string',
      title: modelFieldMessages.finishedLamellaWidth
    },
    strComment: {
      key: 'strComment',
      type: 'string',
      title: modelFieldMessages.strComment
    },
    glueAmount: {
      key: 'glueAmount',
      type: 'string',
      title: modelFieldMessages.glueAmount
    },
    pressTime: {
      key: 'pressTime',
      type: 'string',
      title: modelFieldMessages.pressTime
    },
    iBeamId: {
      key: 'iBeamId',
      type: 'string',
      title: modelFieldMessages.iBeamId
    },
    iFinishedWidth: {
      key: 'iFinishedWidth',
      type: 'string',
      title: modelFieldMessages.iFinishedWidth
    },
    iFinishedHeight: {
      key: 'iFinishedHeight',
      type: 'string',
      title: modelFieldMessages.iFinishedHeight
    },
    iFinishedLength: {
      key: 'iFinishedLength',
      type: 'string',
      title: modelFieldMessages.iFinishedLength
    },
    iBendingRadius: {
      key: 'iBendingRadius',
      type: 'string',
      title: modelFieldMessages.iBendingRadius
    },
    bshDimensions: {
      key: 'dimensions',
      title: modelFieldMessages.kvhDimensions,
      format: {
        type: 'eval_function',
        str: "{finishedLamellaThickness} + ' x ' + {finishedLamellaWidth}",
        keys: {
          finishedLamellaWidth: '{finishedLamellaWidth}',
          finishedLamellaThickness: '{finishedLamellaThickness}'
        },
        evalFinalString: true
      }
    },
    beamPicture: {
      key: 'picture',
      type: 'drawPicture',
      title: modelFieldMessages.picture,
      function: 'getBSHBeamImage',
      halfsize: false
    },
    pressingMatrix: {
      type: 'string',
      identifier: 'pressingMatrix',
      color: 'blue',
      format: {
        type: 'productStack'
      }
    },
    pressedMatrix: {
      type: 'string',
      identifier: 'pressedMatrix',
      color: 'green',
      format: {
        type: 'productStack'
      }
    }
  };

  static actions = {
    deleteOrder: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deleteOrder',
      modal: {
        modal: true,
        buttonText: 'deleteOrder',
        key: 'deleteOrder',
        action: 'deleteOrder',
        icon: 'deleteIcon',
        actionData: {
          target: 'backend',
          source: 'orders/bsh/?status=todo',
          url: '/api/v1/orders/bsh/remove_bsh/',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    deleteControlOrder: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deleteOrder',
      modal: {
        modal: true,
        buttonText: 'deleteOrder',
        key: 'deleteOrder',
        action: 'deleteOrder',
        icon: 'deleteIcon',
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'order-to-remove-from-queue',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    moveUp: {
      key: 'moveUp',
      type: 'controlAction',
      format: {
        compareField: false,
        icon: 'moveUp',
        style: { fontSize: '27px', color: 'black' }
      },
      modal: {
        modal: true,
        buttonText: 'moveUp',
        key: 'moveUp',
        action: 'moveUp',
        icon: 'moveUp',
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'move-order-by-group',
          items: 'selected',
          callType: 'POST'
        }
      },
      title: modelFieldMessages.moveUp,
      additionalParameters: { direction: 'up' }
    },
    moveDown: {
      key: 'moveDown',
      type: 'controlAction',
      format: {
        compareField: false,
        icon: 'moveDown',
        style: { fontSize: '27px', color: 'black' }
      },
      modal: {
        modal: true,
        buttonText: 'moveDown',
        key: 'moveDown',
        action: 'moveDown',
        icon: 'moveDown',
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'move-order-by-group',
          items: 'selected',
          additionalParameters: { direction: 'down' },
          callType: 'POST'
        }
      },
      title: modelFieldMessages.moveDown,
      additionalParameters: { direction: 'down' }
    },
    oAuthApiTest: {
      key: 'oAuthApiTest',
      type: 'controlAction',
      format: {
        compareField: false,
        icon: 'oAuthApiTestIcon',
        style: { fontSize: '27px', color: 'black' }
      },
      modal: {
        modal: true,
        buttonText: 'oAuthApiTest',
        key: 'oAuthApiTest',
        action: 'oAuthApiTest',
        icon: 'oAuthApiTestIcon',
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'oAuth-api-test',
          items: 'selected',
          callType: 'POST'
        }
      },
      title: modelFieldMessages.oAuthApiTest
    },
    fjApiTest: {
      key: 'fjApiTest',
      type: 'controlAction',
      format: {
        compareField: false,
        icon: 'oAuthApiTestIcon',
        style: { fontSize: '27px', color: 'black' }
      },
      modal: {
        modal: true,
        buttonText: 'fjApiTest',
        key: 'fjApiTest',
        action: 'fjApiTest',
        icon: 'oAuthApiTestIcon',
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'oAuth-api-testFJ',
          items: 'selected',
          callType: 'POST'
        }
      },
      title: modelFieldMessages.fjApiTest
    },
    recalculateOrder: {
      key: 'recalculateOrder',
      type: 'controlAction',
      format: {
        compareField: false,
        icon: 'Calculator',
        style: { fontSize: '27px', color: 'black' }
      },
      modal: {
        modal: true,
        buttonText: 'recalculateOrder',
        key: 'recalculateOrder',
        action: 'recalculateOrder',
        icon: 'oAuthApiTestIcon',
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'recalculate-order',
          items: 'selected',
          callType: 'POST'
        }
      },
      title: modelFieldMessages.recalculateOrder
    },
    editOrder: {
      key: 'edit',
      type: 'edit',
      identifier: 'editOrder',
      configuration: {
        model: 'bshorder',
        url: '/api/v1/orders/bsh/'
      }
    },
    editControlOrder: {
      adminRequired: false,
      key: 'editControlOrder',
      format: {
        type: 'action',
        modalTo: '/api/v1/orders/all/',
        icon: 'split',
        style: { fontSize: '27px', color: 'black' }
      }
      // title: modelFieldMessages.editControlOrder,
    },
    activateOrder: {
      key: 'activateOrder',
      type: 'controlAction',
      dispatch: 'activateOrder',
      format: {
        icon: 'planeIcon',
        style: { fontSize: '27px', color: 'black' }
      },
      adminRequired: false,
      modal: {
        modal: true,
        buttonText: 'activateOrderTitle',
        key: 'activateOrderTitle',
        action: 'activateOrderTitle',
        icon: 'moveIcon',
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'activate-order',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    moveOrderToDone: {
      key: 'moveToDone',
      type: 'moveToDone',
      dispatch: 'moveToDone',
      modal: {
        modal: true,
        buttonText: 'moveToDone',
        key: 'moveToDone',
        action: 'moveToDone',
        icon: 'moveIcon',
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'order-to-move-to-done',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    printStickerOnlyForOrder: {
      type: 'action',
      key: 'customModalAction',
      format: {
        type: 'action',
        icon: 'printIcon',
        style: { fontSize: '33px', color: 'black' }
      },
      itemsKey: '',
      viewFunction: (itemState, setLocalState, localState, tmpState, setTmpState) => {
        return (
          <div style={{ width: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Select Printer
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="printer-select-label">Printer</InputLabel>
              <Select labelId="printer-select-label" value={tmpState || ''} label="Printer" onChange={(e) => setTmpState(e.target.value)}>
                <MenuItem value="FlexiPressPrinter">FlexiPress</MenuItem>
                <MenuItem value="RotoPressPrinter">RotoPress</MenuItem>
              </Select>
            </FormControl>
          </div>
        );
      },
      onSave: (currentItemState, dispatch, closeFn, tmpState) => {
        dispatch(sendToWs({ ...currentItemState?.item, assignedPress: tmpState }, 'print-sticker-for-order'));
        closeFn();
      },
      title: modelFieldMessages.selectPrinter
    },
    printSticker: {
      type: 'action',
      key: 'customModalAction',
      format: {
        type: 'action',
        icon: 'printIcon',
        style: { fontSize: '33px', color: 'black' }
      },
      itemsKey: '',
      viewFunction: (itemState, setLocalState, localState, tmpState, setTmpState) => {
        return (
          <div style={{ width: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Select Printer
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="printer-select-label">Printer</InputLabel>
              <Select labelId="printer-select-label" value={tmpState || ''} label="Printer" onChange={(e) => setTmpState(e.target.value)}>
                <MenuItem value="FlexiPressPrinter">FlexiPress</MenuItem>
                <MenuItem value="RotoPressPrinter">RotoPress</MenuItem>
                <MenuItem value="CutSawPrinter">CutSaw</MenuItem>
              </Select>
            </FormControl>
          </div>
        );
      },
      onSave: (currentItemState, dispatch, closeFn, tmpState) => {
        dispatch(sendToWs({ ...currentItemState?.item, assignedPress: tmpState }, 'print-sticker-for-order'));
        closeFn();
      },
      title: modelFieldMessages.selectPrinter
    },
    printStickerFromBackend: {
      type: 'action',
      key: 'printSticker',
      format: {
        type: 'action',
        icon: 'printIcon',
        style: { fontSize: '33px', color: 'black' }
      },
      itemsKey: '',
      configuration: {
        viewFunction: (itemState, setLocalState, localState, tmpState, setTmpState) => {
          return (
            <div style={{ width: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Select Printer
              </Typography>
              <FormControl fullWidth>
                <InputLabel id="printer-select-label">Printer</InputLabel>
                <Select labelId="printer-select-label" value={tmpState || ''} label="Printer" onChange={(e) => setTmpState(e.target.value)}>
                  <MenuItem value="FlexiPressPrinter">FlexiPress</MenuItem>
                  <MenuItem value="RotoPressPrinter">RotoPress</MenuItem>
                  <MenuItem value="CutSawPrinter">CutSaw</MenuItem>
                </Select>
              </FormControl>
            </div>
          );
        },
        onSave: (currentItemState, dispatch, closeFn, tmpState) => {
          dispatch(backendCall(null, '/api/v1/orders/bsh/print_sticker/', 'POST', { ...currentItemState, printer: tmpState }));
          closeFn();
        },
        title: modelFieldMessages.selectPrinter
      }
    }
  };
}

export default BSHOrder;
