import { defineMessages } from 'react-intl';
export default defineMessages({
  identified: {
    id: 'app.containers.Terminal.identified',
    defaultMessage: 'Identified'
  },
  unidentified: {
    id: 'app.containers.Terminal.unidentified',
    defaultMessage: 'Unidentified'
  },
  pressOrders: {
    id: 'app.containers.Press.pressOrders',
    defaultMessage: 'Press Orders'
  },
  beamTitle: {
    id: 'app.containers.Press.beamTitle',
    defaultMessage: 'Beam'
  },
  produceBetterQualityFirst: {
    id: 'app.containers.globalSettings.produceBetterQualityFirst',
    defaultMessage: 'Produce Better Quality First'
  },
  manageServer: {
    id: 'app.containers.ManageServerModal.manageServer',
    defaultMessage: 'Manage Server'
  },
  statisticSettings: {
    id: 'app.components.Statistics.settings',
    defaultMessage: 'Statistic Settings'
  },
  unitSystem: {
    id: 'app.components.unitSystem',
    defaultMessage: 'Unit System'
  },
  zpressLongLamellas: {
    id: 'app.components.SideMenu.zpressLongLamellas',
    defaultMessage: 'Long Lamellas'
  },
  zpressShortLamellas: {
    id: 'app.components.SideMenu.zpressShortLamellas',
    defaultMessage: 'Short Lamellas'
  },
  allowanceNewPanelToSanding: {
    id: 'app.containers.Sanding.allowanceNewPanelToSanding',
    defaultMessage: 'allowanceNewPanelToSanding'
  },
  allowanceNewDataBeforeBypass: {
    id: 'app.containers.Sanding.allowanceNewDataBeforeBypass',
    defaultMessage: 'allowanceNewDataBeforeBypass'
  },
  flipCLTready: {
    id: 'app.containers.Sanding.flipCLTready',
    defaultMessage: 'flipCLTready'
  },
  flipCLTrunning: {
    id: 'app.containers.Sanding.flipCLTrunning',
    defaultMessage: 'flipCLTrunning'
  },
  flipCLTdone: {
    id: 'app.containers.Sanding.flipCLTdone',
    defaultMessage: 'flipCLTdone'
  },
  turningAllowancePC: {
    id: 'app.containers.Sanding.turningAllowancePC',
    defaultMessage: 'turningAllowancePC'
  },
  selectLinestatusTime: {
    id: 'app.containers.Sidebar.selectLinestatusTime',
    defaultMessage: 'Select linestatus time'
  },
  loadStatus: {
    id: 'app.containers.Sidebar.loadStatus',
    defaultMessage: 'Load Status'
  },
  confirmLoadStatus: {
    id: 'app.containers.Sidebar.confirmLoadStatus',
    defaultMessage: 'Confirm Load Status'
  },
  loadStatusQuestion: {
    id: 'app.containers.Sidebar.loadStatusQuestion',
    defaultMessage: 'Are you sure you want to load the line status for the following date and time?'
  },
  downloadStatus: {
    id: 'app.containers.Sidebar.downloadStatus',
    defaultMessage: 'Downoad Status'
  },
  allowanceNewDataBeforeSanding: {
    id: 'app.containers.Sanding.allowanceNewDataBeforeSanding',
    defaultMessage: 'allowanceNewDataBeforeSanding'
  },
  allowanceNewDataAfterBypass: {
    id: 'app.containers.Sanding.allowanceNewDataAfterBypass',
    defaultMessage: 'allowanceNewDataAfterBypass'
  },
  allowanceNewDataAfterSanding: {
    id: 'app.containers.Sanding.allowanceNewDataAfterSanding',
    defaultMessage: 'allowanceNewDataAfterSanding'
  },
  allowanceNewDataAfterSandingMiddle: {
    id: 'app.containers.Sanding.allowanceNewDataAfterSandingMiddle',
    defaultMessage: 'allowanceNewDataAfterSandingMiddle'
  },

  turnTable: {
    id: 'app.components.SideMenu.turnTable',
    defaultMessage: 'Turn Table'
  },
  schneiderProducing: {
    id: 'app.components.SideMenu.schneiderProducing',
    defaultMessage: 'Producing'
  },
  schneiderReady: {
    id: 'app.components.SideMenu.schneiderReady',
    defaultMessage: 'Ready'
  },
  schneiderTodo: {
    id: 'app.components.SideMenu.schneiderTodo',
    defaultMessage: 'Todo'
  },
  brokenLayer: {
    id: 'app.components.confirmation.brokenLayer',
    defaultMessage: 'Broken'
  },
  cutAtSaw: {
    id: 'app.components.confirmation.cutAtSaw',
    defaultMessage: 'Cut at Saw'
  },
  seeEverywhere: {
    id: 'app.components.confirmation.seeEverywhere',
    defaultMessage: 'See Everywhere'
  },
  noGroupAvailable: {
    id: 'app.components.customModal.noGroupAvailable',
    defaultMessage: 'No Group Available'
  },
  timeDistribution: {
    id: 'app.containers.Insights.timeDistribution',
    defaultMessage: 'Time Distribution'
  },
  active: {
    id: 'app.containers.Insights.active',
    defaultMessage: 'Active'
  },
  passive: {
    id: 'app.containers.Insights.passive',
    defaultMessage: 'Passive'
  },
  lineHealth: {
    id: 'app.containers.Insights.lineHealth',
    defaultMessage: 'Line Health'
  },
  startDate: {
    id: 'app.containers.Insights.startDate',
    defaultMessage: 'Start Date'
  },
  endDate: {
    id: 'app.containers.Insights.endDate',
    defaultMessage: 'End Date'
  },
  windowLength: {
    id: 'app.containers.Insights.windowLength',
    defaultMessage: 'Window Length'
  },
  zinkCycle: {
    id: 'app.containers.Insights.zinkCycle',
    defaultMessage: 'Zink Cycle'
  },
  zinkCycleAverage: {
    id: 'app.containers.Insights.zinkCycleAverage',
    defaultMessage: 'Zink Cycle Average'
  },
  zinkCycleCumulative: {
    id: 'app.containers.Insights.zinkCycleCumulative',
    defaultMessage: 'Zink Cycle Cumulative'
  },
  zpressCycle: {
    id: 'app.containers.Insights.zpressCycle',
    defaultMessage: 'Zpress Cycle'
  },
  zpressCycleAverage: {
    id: 'app.containers.Insights.zpressCycleAverage',
    defaultMessage: 'Zpress Cycle Average'
  },
  zpressCycleCumulative: {
    id: 'app.containers.Insights.zpressCycleCumulative',
    defaultMessage: 'Zpress Cycle Cumulative'
  },
  lamellaLengthAverage: {
    id: 'app.containers.Insights.lamellaLengthAverage',
    defaultMessage: 'Lamella Length Average'
  },
  lamellaLengthCumulative: {
    id: 'app.containers.Insights.lamellaLengthCumulative',
    defaultMessage: 'Lamella Length Cumulative'
  },
  sendMessageToAllClients: {
    id: 'app.containers.WebsocketMessageModal.sendMessageToAllClients',
    defaultMessage: 'Send message to all clients'
  },
  newMessageFromAdmin: {
    id: 'app.containers.WebsocketMessageModal.newMessageFromAdmin',
    defaultMessage: 'New message from admin'
  },
  assignedPackage: {
    id: 'app.containers.RawInfeedLoadingBox.assignedPackage',
    defaultMessage: 'Assigned Package'
  },
  assignedPackageA: {
    id: 'app.containers.RawInfeedLoadingBox.assignedPackageA',
    defaultMessage: 'Assigned Package A'
  },
  assignedPackageB: {
    id: 'app.containers.RawInfeedLoadingBox.assignedPackageB',
    defaultMessage: 'Assigned Package B'
  },
  boardPlanned: {
    id: 'app.containers.RawInfeedLoadingBox.boardPlanned',
    defaultMessage: 'Board Planned'
  },
  vacuumAlowance: {
    id: 'app.containers.RawInfeedLoadingBox.vacuumAlowance',
    defaultMessage: 'Vacuum Allowance'
  },
  packageOnLineConfirmed: {
    id: 'app.containers.RawInfeedLoadingBox.packageOnLineConfirmed',
    defaultMessage: 'Package Confirmed'
  },
  lineEmptybetweenVacuumAndSPL: {
    id: 'app.containers.RawInfeedLoadingBox.lineEmptybetweenVacuumAndSPL',
    defaultMessage: 'Line Before SPL Empty'
  },
  signlingEmpty: {
    id: 'app.containers.RawInfeedLoadingBox.signlingEmpty',
    defaultMessage: 'Signling empty'
  },
  sortingLine1Empty: {
    id: 'app.containers.RawInfeedLoadingBox.sortingLine1Empty',
    defaultMessage: 'Sorting Line 1 Empty'
  },
  sortingLine2Empty: {
    id: 'app.containers.RawInfeedLoadingBox.sortingLine2Empty',
    defaultMessage: 'Sorting Line 2 Empty'
  },
  carousel: {
    id: 'app.containers.Machines.carousel',
    defaultMessage: 'Carousel'
  },
  noSettings: {
    id: 'app.containers.PlanerSettingsBox.noSettings',
    defaultMessage: 'No settings...'
  },
  noMovementOrders: {
    id: 'app.components.ListViewConf.noMovementOrders',
    defaultMessage: 'No Movement Orders'
  },
  noPackagesOrdered: {
    id: 'app.components.ListViewConf.noPackagesOrdered',
    defaultMessage: 'No Packages Ordered'
  },
  noPackage: {
    id: 'app.components.ListViewConf.noPackage',
    defaultMessage: 'No Package'
  },
  noHistoryStatus: {
    id: 'app.components.ListViewConf.noHistoryStatus',
    defaultMessage: 'No History Status'
  },
  planerSetup: {
    id: 'app.containers.planerSetup',
    defaultMessage: 'Planer Setup'
  },
  schneiderPlanerSetup: {
    id: 'app.containers.schneiderPlanerSetup',
    defaultMessage: 'Planer Setup'
  },
  taskManager: {
    id: 'app.containers.taskManager',
    defaultMessage: 'Task Manager'
  },
  addPlanerSetup: {
    id: 'app.containers.planerSetup.addPlanerSetup',
    defaultMessage: 'Add New Setup'
  },
  xmPressCompartments: {
    id: 'app.containers.Machines.xmPressCompartments',
    defaultMessage: 'XMPress'
  },
  xmPressFreigabe: {
    id: 'app.containers.Machines.xmPressCompartments',
    defaultMessage: 'XmPress Available To Receive Data'
  },
  xmPressActive: {
    id: 'app.containers.Machines.xmPressActive',
    defaultMessage: 'Panel in Press'
  },
  lamellaBreak: {
    id: 'app.containers.lamellaBreak',
    defaultMessage: 'Carpet'
  },
  enoughForCurrentCarpet: {
    id: 'app.containers.LamellaBreak.enoughForCurrentCarpet',
    defaultMessage: 'Enough For Current Carpet'
  },
  allowanceNewData: {
    id: 'app.containers.LamellaBreak.allowanceNewData',
    defaultMessage: 'Allow New Data'
  },
  sawDataAllowance: {
    id: 'app.containers.LamellaBreak.sawDataAllowance',
    defaultMessage: 'Allow Saw Data'
  },
  allowance: {
    id: 'app.containers.LamellaBreak.allowance',
    defaultMessage: 'Allowance'
  },
  willFinishAndDataOk: {
    id: 'app.containers.LamellaBreak.willFinishAndDataOk',
    defaultMessage: 'Will Finish & OK'
  },
  weWillFinish: {
    id: 'app.containers.LamellaBreak.weWillFinish',
    defaultMessage: 'Will Finish'
  },
  nextOrder: {
    id: 'app.containers.LamellaBreak.nextOrder',
    defaultMessage: 'Next Order'
  },
  pressEmpty: {
    id: 'app.containers.LamellaBreak.pressEmpty',
    defaultMessage: 'Press Empty'
  },
  dataOk: {
    id: 'app.containers.LamellaBreak.dataOk',
    defaultMessage: 'Data OK'
  },
  waitForWoodToZinc: {
    id: 'app.containers.LamellaBreak.waitForWoodToZinc',
    defaultMessage: 'Please wait for wood to come to zinc'
  },
  vacuumTitle: {
    id: 'app.containers.DashboardXLAM.vacuumTitle',
    defaultMessage: 'Vacuum'
  },
  vacuumInsulationTitle: {
    id: 'app.containers.DashboardXLAM.vacuumInsulationTitle',
    defaultMessage: 'Vacuum Insulation'
  },
  xcutTitle: {
    id: 'app.containers.DashboardXLAM.xcutTitle',
    defaultMessage: 'XCUT'
  },
  cameraMonitoring: {
    id: 'app.containers.cameras.cameraMonitoring',
    defaultMessage: 'Camera Monitoring'
  },
  topOffsetFirstPage: {
    id: 'app.containers.DBUploadModal.topOffsetFirstPage',
    defaultMessage: 'Top Offset First Page'
  },
  topOffsetAllPages: {
    id: 'app.containers.DBUploadModal.topOffsetAllPages',
    defaultMessage: 'Top Offset All Pages'
  },
  pleaseWait: {
    id: 'app.containers.DBUploadModal.pleaseWait',
    defaultMessage: 'Please wait while the file is being processed'
  },
  accuTitle: {
    id: 'app.containers.DashboardXLAM.accuTitle',
    defaultMessage: 'Accumulator'
  },
  zincTitle: {
    id: 'app.containers.DashboardXLAM.zincTitle',
    defaultMessage: 'Zinc'
  },
  etageLagerTitle: {
    id: 'app.containers.DashboardXLAM.etageLagerTitle',
    defaultMessage: 'Etage Lager'
  },
  wraworSawZincTitle: {
    id: 'app.containers.DashboardXLAM.wraworSawZincTitle',
    defaultMessage: 'WRAWOR Saw'
  },
  xFormTitle: {
    id: 'app.containers.DashboardXLAM.xFormTitle',
    defaultMessage: 'XForm'
  },
  BrookhuisTitle: {
    id: 'app.containers.DashboardXLAM.BrookhuisTitle',
    defaultMessage: 'Wood moisture'
  },
  miniZincTitle: {
    id: 'app.containers.DashboardXLAM.miniZincTitle',
    defaultMessage: 'Mini Zinc'
  },
  xmPressTitle: {
    id: 'app.containers.DashboardXLAM.xmPressTitle',
    defaultMessage: 'XM Press '
  },
  exitTitle: {
    id: 'app.containers.DashboardXLAM.exitTitle',
    defaultMessage: 'Exit'
  },
  wraworSaw: {
    id: 'app.containers.DashboardXLAM.wraworSaw',
    defaultMessage: 'Wrawor Saw'
  },
  wraworSawZinc: {
    id: 'app.containers.DashboardXLAM.wraworSawZinc',
    defaultMessage: 'Wrawor Saw / Zinc'
  },
  loadedPackagesTitle: {
    id: 'app.containers.DashboardXLAM.loadedPackagesTitle',
    defaultMessage: 'Loaded Packages'
  },
  beforeLagerTitle: {
    id: 'app.containers.DashboardXLAM.beforeLagerTitle',
    defaultMessage: 'Before Storage'
  },
  smartyTitle: {
    id: 'app.containers.DashboardXLAM.smartyTitle',
    defaultMessage: 'Smarty'
  },
  markedLamella: {
    id: 'app.containers.DashboardXLAM.markedLamella',
    defaultMessage: 'Marked Lamella'
  },
  multiplanTitle: {
    id: 'app.containers.DashboardXLAM.multiplanTitle',
    defaultMessage: 'Multiplan'
  },
  etageLagerSolisTitle: {
    id: 'app.containers.DashboardXLAM.etageLagerSolisTitle',
    defaultMessage: 'Lamella Lager'
  },
  scannedLast: {
    id: 'app.containers.DashboardXLAM.scannedLast',
    defaultMessage: 'Scanned Last'
  },
  planerTitle: {
    id: 'app.containers.DashboardXLAM.planerTitle',
    defaultMessage: 'Planer'
  },
  zPressTitle: {
    id: 'app.containers.DashboardXLAM.zPressTitle',
    defaultMessage: 'ZPress'
  },
  holdersTitle: {
    id: 'app.containers.DashboardXLAM.holdersTitle',
    defaultMessage: 'Holders'
  },
  platesTitle: {
    id: 'app.containers.DashboardXLAM.platesTitle',
    defaultMessage: 'Plates'
  },
  afterLKSTitle: {
    id: 'app.containers.DashboardXLAM.afterLKSTitle',
    defaultMessage: 'CC vacuum'
  },
  LKSTitle: {
    id: 'app.containers.DashboardXLAM.LKSTitle',
    defaultMessage: 'LKS'
  },
  SPLTitle: {
    id: 'app.containers.DashboardXLAM.SPLTitle',
    defaultMessage: 'SPL'
  },
  pressTitle: {
    id: 'app.containers.DashboardXLAM.pressTitle',
    defaultMessage: 'Press'
  },
  pressTitle1: {
    id: 'app.containers.DashboardXLAM.pressTitle1',
    defaultMessage: 'Press 1'
  },
  pressTitle2: {
    id: 'app.containers.DashboardXLAM.pressTitle2',
    defaultMessage: 'Press 2'
  },
  liftStatusTitle: {
    id: 'app.containers.DashboardXLAM.liftStatusTitle',
    defaultMessage: 'Lift status'
  },
  rcstackStatusTitle: {
    id: 'app.containers.DashboardXLAM.rcstackStatusTitle',
    defaultMessage: 'RC Stack'
  },
  exitTitle: {
    id: 'app.containers.DashboardXLAM.exitTitle',
    defaultMessage: 'Exit'
  },
  rotopressTitle: {
    id: 'app.containers.DashboardXLAM.rotopressTitle',
    defaultMessage: 'Rotopress'
  },
  extraLamStorageTitle: {
    id: 'app.containers.DashboardXLAM.extraLamStorageTitle',
    defaultMessage: 'Extra lamella storage'
  },
  MaxipressTitle: {
    id: 'app.containers.DashboardXLAM.MaxipressTitle',
    defaultMessage: 'Maxipress'
  },
  lineTitle: {
    id: 'app.containers.DashboardXLAM.lineTitle',
    defaultMessage: 'Line Status'
  },
  XpressTitle: {
    id: 'app.containers.DashboardXLAM.XpressTitle',
    defaultMessage: 'Xpress'
  },
  Xpress1Title: {
    id: 'app.containers.DashboardXLAM.Xpress1Title',
    defaultMessage: 'Xpress 1'
  },
  Xpress2Title: {
    id: 'app.containers.DashboardXLAM.Xpress2Title',
    defaultMessage: 'Xpress 2'
  },
  etageLagerShortTitle: {
    id: 'app.containers.DashboardXLAM.etageLagerShortTitle',
    defaultMessage: 'Shortlager'
  },
  etageLagerLongTitle: {
    id: 'app.containers.DashboardXLAM.etageLagerLongTitle',
    defaultMessage: 'Longlager'
  },
  chartTitle: {
    id: 'app.containers.DashboardXLAM.chartTitle',
    defaultMessage: 'Zinc Chart'
  },
  productStacking: {
    id: 'app.containers.DashboardXLAM.productStacking',
    defaultMessage: 'Product Stacking'
  },
  header: {
    id: 'app.containers.Sidebar.header',
    defaultMessage: 'This is the Sidebar container!'
  },
  lamellasInTransporter: {
    id: 'app.components.Compartment.header',
    defaultMessage: 'lamellas in transporter'
  },

  frozen: {
    id: 'app.containers.editview.frozen',
    defaultMessage: 'Frozen'
  },
  isWaste: {
    id: 'app.containers.editview.isWaste',
    defaultMessage: 'Is Waste'
  },
  isTest: {
    id: 'app.containers.editview.isTest',
    defaultMessage: 'Is Test'
  },
  FJmachine: {
    id: 'app.containers.editview.FJmachine',
    defaultMessage: 'FJ Machine'
  },
  ZPmachine: {
    id: 'app.containers.editview.ZPmachine',
    defaultMessage: 'ZP Machine'
  },
  machine: {
    id: 'app.containers.editview.machine',
    defaultMessage: 'Machine'
  },
  activeOrders: {
    id: 'app.containers.editview.activeOrders',
    defaultMessage: 'Active Orders'
  },
  clt: {
    id: 'app.containers.editview.clt',
    defaultMessage: 'CLT'
  },
  finishedOrders: {
    id: 'app.containers.editview.finishedOrders',
    defaultMessage: 'Finished Orders'
  },
  abfZincSent: {
    id: 'app.containers.editview.abfZincSent',
    defaultMessage: 'ABF sent for Zinc'
  },
  abfPlanerSent: {
    id: 'app.containers.editview.abfPlanerSent',
    defaultMessage: 'ABF sent for Planner'
  },
  abfZPressSent: {
    id: 'app.containers.editview.abfZPressSent',
    defaultMessage: 'ABF sent for Zpress'
  },
  fingerJointerId: {
    id: 'app.containers.editview.fingerJointerId',
    defaultMessage: 'FingerJointer id'
  },
  firmness: {
    id: 'app.containers.editview.firmness',
    defaultMessage: 'Firmness'
  },
  dimChangeOffset: {
    id: 'app.containers.editview.dimChangeOffset',
    defaultMessage: 'Dimension change offset'
  },
  outputPosition: {
    id: 'app.containers.editview.outputPosition',
    defaultMessage: 'Output position'
  },
  emptied: {
    id: 'app.containers.editview.emptied',
    defaultMessage: 'Emptied'
  },
  forceDry: {
    id: 'app.containers.editview.forceDry',
    defaultMessage: 'Force Dry'
  },
  seenOnDryingStorage: {
    id: 'app.containers.editview.seenOnDryingStorage',
    defaultMessage: 'Seen On Drying Storage'
  },
  forceEmpty: {
    id: 'app.containers.editview.forceEmpty',
    defaultMessage: 'Force Empty'
  },
  zPressCompletionSent: {
    id: 'app.containers.editview.zPressCompletionSent',
    defaultMessage: 'ZPress Completion Sent'
  },
  crossCutSawCompletionSent: {
    id: 'app.containers.editview.crossCutSawCompletionSent',
    defaultMessage: 'CrossCut Saw Completion Sent'
  },
  planeCompletionSent: {
    id: 'app.containers.editview.planeCompletionSent',
    defaultMessage: 'Plane Completion Sent'
  },
  fragmentId: {
    id: 'app.containers.editview.fragmentId',
    defaultMessage: 'Fragment Id'
  },
  extraLamella: {
    id: 'app.containers.editview.extraLamella',
    defaultMessage: 'Extra Lamella'
  },
  groupAge: {
    id: 'app.containers.editview.groupAge',
    defaultMessage: 'Group Age'
  },
  groupWidth: {
    id: 'app.containers.editview.groupWidth',
    defaultMessage: 'Group Width'
  },
  compartment: {
    id: 'app.containers.editview.compartment',
    defaultMessage: 'Compartment'
  },
  compartmentDetails: {
    id: 'app.containers.LagerLong.CompartmentModal.compartmentDetails',
    defaultMessage: 'Compartment details'
  },
  orderId: {
    id: 'app.containers.editview.orderId',
    defaultMessage: 'Order Id'
  },
  layerId: {
    id: 'app.containers.editview.layerId',
    defaultMessage: 'Layer Id'
  },
  noOfLamellas: {
    id: 'app.containers.LagerLong.CompartmentModal.noOfLamellas',
    defaultMessage: 'Number of lamellas'
  },
  noOfLamellasShouldBe: {
    id: 'app.containers.LagerLong.CompartmentModal.noOfLamellasShouldBeb',
    defaultMessage: 'Number of lamellas that should be'
  },
  rawDimensions: {
    id: 'app.components.RawInfeedLoadingBox.rawDimensions',
    defaultMessage: 'Raw Dimensions'
  },
  transferData: {
    id: 'app.components.RawInfeedLoadingBox.transferData',
    defaultMessage: 'Transfer Data'
  },
  paket: {
    id: 'app.components.RawInfeedLoadingBox.paket',
    defaultMessage: 'Paket - '
  },
  transferRawData: {
    id: 'app.components.RawInfeedLoadingBox.transferRawData',
    defaultMessage: 'Auftrag Roh Data'
  },
  dimensions: {
    id: 'app.containers.Zinc.dimensions',
    defaultMessage: 'Dimensions'
  },
  lamellaDimensions: {
    id: 'app.containers.Zinc.lamellaDimensions',
    defaultMessage: 'Lamella Dimensions'
  },
  packageDimensions: {
    id: 'app.containers.Zinc.packageDimensions',
    defaultMessage: 'Package Dimensions'
  },
  moscaDimensions: {
    id: 'app.containers.Zinc.moscaDimensions',
    defaultMessage: 'Mosca (Layers x Lamellas/Layer)'
  },
  type: {
    id: 'app.containers.Zinc.type',
    defaultMessage: 'Type'
  },
  save: {
    id: 'app.components.modals.SetPackageModal.save',
    defaultMessage: 'Save'
  },
  close: {
    id: 'app.components.modals.PackageLoading.close',
    defaultMessage: 'Close'
  },
  showJSON: {
    id: 'app.containers.LagerLong.CompartmentModal.showJSON',
    defaultMessage: 'Show JSON'
  },
  pressId: {
    id: 'app.containers.editview.pressId',
    defaultMessage: 'Press Id'
  },
  masterpanel: {
    id: 'app.containers.editview.masterpanel',
    defaultMessage: 'Masterpanel'
  },
  confirm: {
    id: 'app.containers.editView.confirm',
    defaultMessage: 'Confirm'
  },
  cancel: {
    id: 'app.containers.OrdersQueue.cancel',
    defaultMessage: 'Cancel'
  },
  noaction: {
    id: 'app.containers.Tableview.noaction',
    defaultMessage: 'No action!'
  },
  action: {
    id: 'app.containers.Tableview.action',
    defaultMessage: 'Action'
  },
  moveTo: {
    id: 'app.containers.Tableview.moveTo',
    defaultMessage: 'Move to'
  },
  selectFile: {
    id: 'app.containers.Tableview.selectFile',
    defaultMessage: 'Select FILE'
  },
  addErp: {
    id: 'app.containers.Tableview.addErp',
    defaultMessage: 'Add New'
  },
  uploadFile: {
    id: 'app.containers.Tableview.uploadFile',
    defaultMessage: 'Upload new File'
  },
  addComment: {
    id: 'app.containers.Tableview.addComment',
    defaultMessage: 'ADD COMMENT'
  },
  itemWithNoWidthSelected: {
    id: 'app.containers.TableView.itenWithNoWidthSelected',
    defaultMessage: 'Item with 0 width selected'
  },
  title: {
    id: 'app.components.TabelaricModal.title',
    defaultMessage: 'Title'
  },
  manualfill: {
    id: 'app.components.EtageLagerBox.manualfill',
    defaultMessage: 'Manual filling'
  },
  manualSorting: {
    id: 'app.components.EtageLagerBox.manualSorting',
    defaultMessage: 'Manual Sorting'
  },
  goesThrough: {
    id: 'app.components.LiftStatus.goesThrough',
    defaultMessage: 'Goes Through'
  },
  checkIfUnfinishedCross: {
    id: 'app.components.LiftStatus.checkIfUnfinishedCross',
    defaultMessage: 'Check if unifinished cross'
  },
  liftId: {
    id: 'app.components.LiftStatus.liftId',
    defaultMessage: 'Lift ID '
  },
  assignedID: {
    id: 'app.components.LiftStatus.assignedID',
    defaultMessage: 'Assigned ID '
  },
  goesToEtage: {
    id: 'app.components.LiftStatus.goesToEtage',
    defaultMessage: 'Goes to etage'
  },
  manualempty: {
    id: 'app.components.EtageLagerBox.manualempty',
    defaultMessage: 'Manual emptying'
  },
  modalTitle: {
    id: 'app.components.PressBox.modalTitle',
    defaultMessage: 'Press'
  },
  platesOnHolder: {
    id: 'app.components.PressBox.platesOnHolder',
    defaultMessage: 'Plates on holder'
  },
  vacuumAllowance: {
    id: 'app.components.PackageInfeedBox.vacuumAllowance',
    defaultMessage: 'Vacuum Allowance'
  },
  PLCVacuumAllowance: {
    id: 'app.components.PackageInfeedBox.PLCVacuumAllowance',
    defaultMessage: 'PLC Vacuum Allowance'
  },
  allowanceFromMode: {
    id: 'app.components.PackageInfeedBox.allowanceFromMode',
    defaultMessage: 'Mode Allowance'
  },
  splitCut: {
    id: 'app.components.PackageInfeedBox.splitCut',
    defaultMessage: 'Split Cut'
  },
  zincVacuumAllowance: {
    id: 'app.components.PackageInfeedBox.zincVacuumAllowance',
    defaultMessage: 'Zink Allowance'
  },
  zincActive: {
    id: 'app.components.PackageInfeedBox.zincActive',
    defaultMessage: 'Zinc Active'
  },
  emptyAllowance: {
    id: 'app.components.PackageInfeedBox.emptyAllowance',
    defaultMessage: 'Empty from vacuum to zink'
  },
  more: {
    id: 'app.containers.ZincBox.more',
    defaultMessage: 'more'
  },
  rawInfeedAllowance: {
    id: 'app.containers.RawInfeedLoadingBox.rawInfeedAllowance',
    defaultMessage: 'Raw Infeed Allowance'
  },
  allowanceMultiplan: {
    id: 'app.containers.editview.allowanceMultiplan',
    defaultMessage: 'Allowance Multiplan'
  },
  multiplanAllowance: {
    id: 'app.containers.editview.multiplanAllowance',
    defaultMessage: 'Multiplan Allowance'
  },
  boardsBeforePlaner: {
    id: 'app.containers.RawInfeedLoadingBox.boardsBeforePlaner',
    defaultMessage: 'Boards before planer'
  },
  stackingTitle: {
    id: 'app.containers.Stacking.stackingTitle',
    defaultMessage: 'Stacking'
  },
  stackingNo: {
    id: 'app.containers.Stacking.stackingNo',
    defaultMessage: 'Stacking No'
  },
  stackingid: {
    id: 'app.containers.Stacking.stackingid',
    defaultMessage: 'Current stacking ID'
  },
  superplan: {
    id: 'app.containers.Stacking.superplan',
    defaultMessage: 'Superplan'
  },
  superles: {
    id: 'app.containers.Stacking.superles',
    defaultMessage: 'Superles'
  },
  rawWidthSuperplan: {
    id: 'app.components.StorageBox.rawWidthSuperplan',
    defaultMessage: 'Raw width (±offset)'
  },
  rawThicknessSuperplan: {
    id: 'app.components.StorageBox.rawThicknessSuperplan',
    defaultMessage: 'Raw thickness (±offset)'
  },
  planedWidthSuperplan: {
    id: 'app.components.StorageBox.planedWidthSuperplan',
    defaultMessage: 'Planed width (±offset)'
  },
  planedThicknessSuperplan: {
    id: 'app.components.StorageBox.planedThicknessSuperplan',
    defaultMessage: 'Planed thickness (±offset)'
  },
  confirmMaterial: {
    id: 'app.components.RawInfeedLoadingBox.confirmMaterial',
    defaultMessage: 'Confirm Material'
  },
  totalNumberOfLamellas: {
    id: 'app.components.RawInfeedLoadingBox.totalNumberOfLamellas',
    defaultMessage: 'Number Of Lamellas in Storage '
  },
  resetMaterial: {
    id: 'app.components.RawInfeedLoadingBox.resetMaterial',
    defaultMessage: 'Reset Material'
  },
  lamellas: {
    id: 'app.components.StorageBox.lamellas',
    defaultMessage: 'Lamellas done'
  },
  order: {
    id: 'app.containers.DashboardXLAM.order',
    defaultMessage: 'Order'
  },
  numberOfLamellasAfterSaws: {
    id: 'app.containers.DashboardXLAM.numberOfLamellasAfterSaws',
    defaultMessage: 'Number of Lamellas out of multiplan'
  },
  incomingMaterial: {
    id: 'app.containers.DashboardXLAM.incomingMaterial',
    defaultMessage: 'Incoming Material'
  },
  actual: {
    id: 'app.components.ZincBox.done',
    defaultMessage: 'done'
  },
  left: {
    id: 'app.components.ZincBox.left',
    defaultMessage: 'left'
  },
  nextPressDimension: {
    id: 'app.components.ZincBox.nextPressDimension',
    defaultMessage: 'next press len.'
  },
  outgoingLength: {
    id: 'app.components.ZincBox.outgoingLength',
    defaultMessage: 'out len.'
  },
  extra: {
    id: 'app.containers.ZincBox.extra',
    defaultMessage: '(extra)'
  },
  playVideo: {
    id: 'app.containers.PlayVideoModal.playVideo',
    defaultMessage: 'Play Video'
  },
  plates: {
    id: 'app.components.ZPressBox.plates',
    defaultMessage: 'Plates done'
  },
  planedLamellas: {
    id: 'app.components.ZPressBox.planedLamellas',
    defaultMessage: 'Planed lamellas'
  },
  materialNeededText: {
    id: 'app.components.MicrotecBox.materialNeededText',
    defaultMessage: 'Material needed last 2 hours'
  },
  subTitle: {
    id: 'app.components.MicrotecBox.subTitle',
    defaultMessage: 'Scan report | Lamellas before XCut'
  },
  beforeStorageSubTitle: {
    id: 'app.components.MicrotecBox.beforeStorageSubTitle',
    defaultMessage: 'Lamellas Before Storage'
  },
  exitSubTitle: {
    id: 'app.components.MicrotecBox.exitSubTitle',
    defaultMessage: 'Creating Package'
  },
  scannedPackagesTitle: {
    id: 'app.components.MicrotecBox.scannedPackagesTitle',
    defaultMessage: 'Scanned Packages'
  },
  smartyBoxSubTitle: {
    id: 'app.components.MicrotecBox.smartyBoxSubTitle',
    defaultMessage: 'Scaner Settings'
  },
  zincBoxSubTitle: {
    id: 'app.components.ZincBox.zincBoxSubTitle',
    defaultMessage: 'Zink Info'
  },
  etageLagerBoxSubTitle: {
    id: 'app.components.EtageLagerBox.etageLagerBoxSubTitle',
    defaultMessage: 'Storage Info'
  },
  etageLagerBoxId: {
    id: 'app.components.EtageLagerBox.etageLagerBoxId',
    defaultMessage: 'ID '
  },
  etageLagerBoxNumBoards: {
    id: 'app.components.EtageLagerBox.etageLagerBoxNumBoards',
    defaultMessage: 'Num of Boards '
  },
  firstStorageComp: {
    id: 'app.components.EtageLagerBox.firstStorageComp',
    defaultMessage: 'First storage compartment'
  },
  secondStorageComp: {
    id: 'app.components.EtageLagerBox.secondStorageComp',
    defaultMessage: 'Second storage compartment'
  },
  thirdStorageComp: {
    id: 'app.components.EtageLagerBox.thirdStorageComp',
    defaultMessage: 'Third storage compartment'
  },
  fourthStorageComp: {
    id: 'app.components.EtageLagerBox.fourthStorageComp',
    defaultMessage: 'Fourth storage compartment'
  },
  fifthStorageComp: {
    id: 'app.components.EtageLagerBox.fifthStorageComp',
    defaultMessage: 'Fifth storage compartment'
  },
  stackingBoxSubTitle: {
    id: 'app.components.StackingBox.stackingBoxSubTitle',
    defaultMessage: 'Stacking Info'
  },
  stackingBoxId: {
    id: 'app.components.StackingBox.stackingBoxId',
    defaultMessage: 'ID '
  },
  stackingBoxStacking: {
    id: 'app.components.StackingBox.stackingBoxStacking',
    defaultMessage: 'Stacking '
  },
  stackingBoxResendData: {
    id: 'app.components.StackingBox.stackingBoxResendData',
    defaultMessage: 'Resend Stacker Data'
  },
  xcutSubTitle: {
    id: 'app.components.XCutBox.subTitle',
    defaultMessage: 'XCUT Status'
  },
  lastCutLamella: {
    id: 'app.components.MicrotecBox.lastCutLamella',
    defaultMessage: 'Letzte geschnittene Lamelle'
  },
  lamellaId: {
    id: 'app.components.MicrotecBox.lamellaId',
    defaultMessage: 'Lamelle Id'
  },
  woodType: {
    id: 'app.containers.editview.woodType',
    defaultMessage: 'Wood Type'
  },
  splitLamella: {
    id: 'app.containers.editview.splitLamella',
    defaultMessage: 'Split Lamella'
  },
  finishedLamellaWidth: {
    id: 'app.containers.editview.finishedLamellaWidth',
    defaultMessage: 'Finished Lamella Width'
  },
  finishedLfinishedLamellaThicknessamellaWidth: {
    id: 'app.containers.editview.finishedLamellaThickness',
    defaultMessage: 'Finished Lamella Thickness'
  },
  quality: {
    id: 'app.components.modals.PackageLoading.quality',
    defaultMessage: 'QUALITY'
  },
  qualityLowercase: {
    id: 'app.components.modals.PackageLoading.qualityLowercase',
    defaultMessage: 'Quality'
  },
  currentRawMaterial: {
    id: 'app.components.MicrotecBox.currentRawMaterial',
    defaultMessage: 'Rohware'
  },
  nextRawMaterial: {
    id: 'app.components.MicrotecBox.nextRawMaterial',
    defaultMessage: 'Nächstes Rohware'
  },
  orderDimensSent: {
    id: 'app.components.MicrotecBox.orderDimensSent',
    defaultMessage: 'A daten bestätigt'
  },
  orderDimensAck: {
    id: 'app.components.MicrotecBox.orderDimensAck',
    defaultMessage: 'O. dimen. ack'
  },
  lamellaDimensSent: {
    id: 'app.components.MicrotecBox.lamellaDimensSent',
    defaultMessage: 'L daten bestätigt'
  },
  lamellaDimensAck: {
    id: 'app.components.MicrotecBox.lamellaDimensAck',
    defaultMessage: 'L. dimen. ack'
  },
  forcecut: {
    id: 'app.components.MicrotecBox.forcecut',
    defaultMessage: 'Zwangkappung'
  },
  strName: {
    id: 'app.components.MicrotecBox.strName',
    defaultMessage: 'Str. name'
  },
  planedDimensions: {
    id: 'app.containers.ZincBox.planedDimensions',
    defaultMessage: 'Planed Dimensions'
  },
  stacking: {
    id: 'app.components.RawInfeedLoadingBox.stacking',
    defaultMessage: 'Stacking'
  },
  progress: {
    id: 'app.components.XcutBox.progress',
    defaultMessage: 'Progress'
  },
  repair: {
    id: 'app.components.OutputConveyorBeltBox.repair',
    defaultMessage: 'Reparatur'
  },
  sendToFirstSector: {
    id: 'app.components.MicrotecBox.sendToFirstSector',
    defaultMessage: 'First sec.'
  },
  sendToSecondSector: {
    id: 'app.components.MicrotecBox.sendToSecondSector',
    defaultMessage: 'Second sec.'
  },
  clearPlanerOrder: {
    id: 'app.components.OutputConveyorBeltBox.clearPlanerOrder',
    defaultMessage: 'Clear Planer Order'
  },
  goodLength: {
    id: 'app.components.SystemTMBox.goodLength',
    defaultMessage: 'Gutlängen'
  },
  takenOut: {
    id: 'app.components.SystemTMBox.takenOut',
    defaultMessage: '-4m (ausnehmen)'
  },
  lengthOffset: {
    id: 'app.components.SystemTMBox.lengthOffset',
    defaultMessage: 'Längenversatz'
  },
  EZFreigabe: {
    id: 'app.components.SystemTMBox.AZFreigabe',
    defaultMessage: 'EZFreigabe'
  },
  microtecLength: {
    id: 'app.components.SystemTMBox.microtecLength',
    defaultMessage: 'Mikroteclänge'
  },
  lastLabel: {
    id: 'app.components.UnloadingBox.lastLabel',
    defaultMessage: 'Last label'
  },
  printed: {
    id: 'app.components.UnloadingBox.printed',
    defaultMessage: 'printed'
  },
  print: {
    id: 'app.components.UnloadingBox.print',
    defaultMessage: 'Print'
  },
  pieces: {
    id: 'app.containers.editview.pieces',
    defaultMessage: 'Pieces'
  },
  noboards: {
    id: 'app.containers.ModelFieldMessages.identifierNumBoards',
    defaultMessage: 'No. boards'
  },
  turningBeforePressError1: {
    id: 'app.containers.ModelFieldMessages.turningBeforePressError1',
    defaultMessage: 'Either turningEverySecond or turningEveryFirst must be checked, but not both.'
  },
  turningBeforePressError2: {
    id: 'app.containers.ModelFieldMessages.turningBeforePressError2',
    defaultMessage: 'turningEverySecond and turningEveryFirst should not be checked when turningBeforePress is unchecked.'
  },
  total: {
    id: 'app.components.XcutBox.total',
    defaultMessage: 'Total'
  },
  totalForDimension: {
    id: 'app.components.XcutBox.totalForDimension',
    defaultMessage: 'Total for Dimension'
  },
  totalMadeForDimension: {
    id: 'app.components.XcutBox.totalMadeForDimension',
    defaultMessage: 'Total Made for Dimension'
  },
  totalForAllOrders: {
    id: 'app.components.XcutBox.totalForAllOrders',
    defaultMessage: 'Total made for all orders'
  },
  xcut: {
    id: 'app.components.XcutBox.xcut',
    defaultMessage: 'XCut'
  },
  package: {
    id: 'app.components.XcutBox.package',
    defaultMessage: 'Package'
  },
  waitingForEmptyBeforeXcut: {
    id: 'app.containers.Status.waitingForEmptyBeforeXcuta',
    defaultMessage: 'Waiting for empty before XCut1'
  },
  halterDisabled: {
    id: 'app.containers.Status.halterDisableda',
    defaultMessage: 'Enough boards for current order'
  },
  emptyingXcut1: {
    id: 'app.containers.Status.emptyingXcut1a',
    defaultMessage: 'Emptying before XCut1'
  },
  planerAllowance: {
    id: 'app.components.EtageLagerBox.planerAllowance',
    defaultMessage: 'Planer allowance'
  },
  reqPackageData: {
    id: 'app.components.EtageLagerBox.reqPackageData',
    defaultMessage: 'Request PackageData'
  },
  lamellaInMachine: {
    id: 'app.components.EtageLagerBox.lamellaInMachine',
    defaultMessage: 'Lamella In Planner'
  },
  entryAllowance: {
    id: 'app.components.EtageLagerBox.entryAllowance',
    defaultMessage: 'Entry Allowance'
  },
  vacuumAllows: {
    id: 'app.components.EtageLagerBox.vacuumAllows',
    defaultMessage: 'Vacuum Allows'
  },
  unloadMaterial: {
    id: 'app.components.EtageLagerBox.unloadMaterial',
    defaultMessage: 'Unload Material'
  },
  xCutAllowance: {
    id: 'app.components.EtageLagerBox.xCutAllowance',
    defaultMessage: 'xCut Allowance'
  },
  sequenceError: {
    id: 'app.components.EtageLagerBox.sequenceError',
    defaultMessage: 'Sequence error'
  },
  dataError: {
    id: 'app.components.EtageLagerBox.dataError',
    defaultMessage: 'Data Error'
  },
  waitingForPress: {
    id: 'app.components.PressBox.sentToPress',
    defaultMessage: 'waiting for Press'
  },
  openTime: {
    id: 'app.components.PressBox.openTime',
    defaultMessage: 'Open time'
  },
  pressTime: {
    id: 'app.components.PressBox.pressTime',
    defaultMessage: 'Press time'
  },
  currentTimerString: {
    id: 'app.components.PressBox.currentTimerString',
    defaultMessage: 'Current time for order'
  },
  currentOrderStacking: {
    id: 'app.components.PressBox.currentOrderStacking',
    defaultMessage: 'Current order stacking'
  },
  no: {
    id: 'app.components.PressBox.no',
    defaultMessage: 'No'
  },
  orderPress1: {
    id: 'app.components.PressBox.orderPress1',
    defaultMessage: 'order in press 1'
  },
  orderPress2: {
    id: 'app.components.PressBox.orderPress2',
    defaultMessage: 'order in press 2'
  },
  orderPress: {
    id: 'app.components.PressBox.press1',
    defaultMessage: 'order in press'
  },
  noMaxiOrders: {
    id: 'app.components.PressBox.noMaxiOrders',
    defaultMessage: 'No order in press'
  },
  inMaxiPressEtages: {
    id: 'app.components.PressBox.inMaxiPressEtages',
    defaultMessage: 'Orders in etages'
  },
  packageInfo: {
    id: 'app.components.ProductionLine.packageInfo',
    defaultMessage: 'Package information'
  },
  zincLager: {
    id: 'app.components.ProductionLine.zincLager',
    defaultMessage: 'Zinc Storage'
  },
  loader: {
    id: 'app.components.ProductionLine.loader',
    defaultMessage: 'Loader'
  },
  lamellaLager: {
    id: 'app.components.ProductionLine.lamellaLager',
    defaultMessage: 'Lamella storage'
  },
  multiplanSub: {
    id: 'app.components.ProductionLine.multiplanSub',
    defaultMessage: 'Multiplan Dimensions'
  },
  brookuhuisSub: {
    id: 'app.components.ProductionLine.brookuhuisSub',
    defaultMessage: 'Brookhuis'
  },
  inMaxipress: {
    id: 'app.components.PressBox.inMaxipress',
    defaultMessage: 'Orders in press'
  },
  order2: {
    id: 'app.components.PressBox.press2',
    defaultMessage: 'order on table'
  },
  panelInfo: {
    id: 'app.containers.LagerLong.CompartmentModal.panelInfo',
    defaultMessage: 'Panel Info'
  },
  panelDimensions: {
    id: 'app.containers.LagerLong.CompartmentModal.panelDimensions',
    defaultMessage: 'Panel Dimensions'
  },
  numberOfLayersInPress: {
    id: 'app.containers.LagerLong.CompartmentModal.numberOfLayers',
    defaultMessage: 'Number Of Layers'
  },
  ordersQueueTitle: {
    id: 'app.components.SideMenu.ordersQueueTitle',
    defaultMessage: 'Orders Queue'
  },
  schneiderOrdersQueueTitle: {
    id: 'app.components.SideMenu.schneiderOrdersQueueTitle',
    defaultMessage: 'Orders'
  },
  settings: {
    id: 'app.containers.OrdersQueue.settings',
    defaultMessage: 'Settings'
  },
  userSettings: {
    id: 'app.containers.OrdersQueue.userSettings',
    defaultMessage: 'User Settings'
  },
  emptyError: {
    id: 'app.containers.OrdersQueue.emptyError',
    defaultMessage: 'Must not be empty'
  },
  orderInfo: {
    id: 'app.containers.OrdersQueue.orderInfo',
    defaultMessage: 'Order Info'
  },
  layerInfo: {
    id: 'app.containers.OrdersQueue.layerInfo',
    defaultMessage: 'Layer Info'
  },
  id: {
    id: 'app.containers.editview.id',
    defaultMessage: 'Id'
  },
  lamellasInPackage: {
    id: 'app.containers.editview.lamellasInPackage',
    defaultMessage: 'Lamellas In Package'
  },
  orderimportNumber: {
    id: 'app.containers.editview.orderImportNumber',
    defaultMessage: 'Order import Number'
  },
  identifier: {
    id: 'app.containers.editview.identifier',
    defaultMessage: 'Identifier'
  },
  lamellaThickness: {
    id: 'app.containers.editview.lamellaThickness',
    defaultMessage: 'Lamella Thickness'
  },
  lamellaWidth: {
    id: 'app.containers.editview.lamellaWidth',
    defaultMessage: 'Lamella Width'
  },
  insulationWidth: {
    id: 'app.containers.editview.insulationWidth',
    defaultMessage: 'Insulation Width'
  },
  lamellaLength: {
    id: 'app.containers.editview.lamellaLength',
    defaultMessage: 'Lamella Length'
  },
  resetZPress: {
    id: 'app.containers.editview.resetZPress',
    defaultMessage: 'Reset Z-Press production'
  },
  resendMultiplan: {
    id: 'app.containers.editview.resendMultiplan',
    defaultMessage: 'Resend Multiplan Data'
  },
  resetVacuumTask: {
    id: 'app.containers.editview.resetVacuumTask',
    defaultMessage: 'Reset Vacuum Task'
  },
  resendZink: {
    id: 'app.containers.editview.resendZink',
    defaultMessage: 'Resend Zink Data'
  },
  recalculateAndResendZPress: {
    id: 'app.containers.editview.recalculateAndResendZPress',
    defaultMessage: 'Recalculate and Resend Data'
  },
  resetXFormOrder: {
    id: 'app.containers.editview.resetXFormOrder',
    defaultMessage: 'Reset xForm Order'
  },
  resendWraworData: {
    id: 'app.containers.editview.resendWraworData',
    defaultMessage: 'Resend Wrawor Data'
  },
  averageLength: {
    id: 'app.containers.editview.averageLength',
    defaultMessage: 'Average Length'
  },
  lm: {
    id: 'app.components.modals.SetPackageModal.lm',
    defaultMessage: 'METERS IN PACKAGE'
  },
  strengthGrade: {
    id: 'app.containers.editview.strengthGrade',
    defaultMessage: 'Strength Grade'
  },
  lmUsed: {
    id: 'app.containers.editview.lmUsed',
    defaultMessage: 'Lm Used'
  },
  shouldBeRemoved: {
    id: 'app.containers.editview.shouldBeRemoved',
    defaultMessage: 'Should Be Removed'
  },
  packageId: {
    id: 'app.components.modals.PackageLoading.packageId',
    defaultMessage: 'PACKAGE ID'
  },
  packageNumber: {
    id: 'app.containers.editview.packageNumber',
    defaultMessage: 'Package number'
  },
  finishedPackageId: {
    id: 'app.containers.editview.finishedPackageId',
    defaultMessage: 'finished Package Id'
  },
  piecesUsed: {
    id: 'app.containers.editview.piecesUsed',
    defaultMessage: 'Pieces Used'
  },
  inappropriateForUsing: {
    id: 'app.containers.editview.inappropriateForUsing',
    defaultMessage: 'Inappropriate For Using'
  },
  showAlreadyOrderedPackages: {
    id: 'app.containers.InfeedRaw.showAlreadyOrderedPackages',
    defaultMessage: 'Show already ordered packages'
  },
  piecesPerLayer: {
    id: 'app.containers.InfeedRaw.piecesPerLayer',
    defaultMessage: 'Pieces'
  },
  neededOnLoadingPosition: {
    id: 'app.containers.editview.neededOnLoadingPosition',
    defaultMessage: 'Needed On Loading Position'
  },
  lmAfterMessrad: {
    id: 'app.containers.editview.lmAfterMessrad',
    defaultMessage: 'Lm After Messrad'
  },
  lmWithPaketladn: {
    id: 'app.containers.editview.lmWithPaketladn',
    defaultMessage: 'Lm With Paketladn'
  },
  lmAtFlySaw: {
    id: 'app.containers.editview.lmAtFlySaw',
    defaultMessage: 'Lm At Fly Saw'
  },
  createdAt: {
    id: 'app.containers.editview.createdAt',
    defaultMessage: 'Created At'
  },
  creatingPackage: {
    id: 'app.containers.editview.creatingPackage',
    defaultMessage: 'Creating Package'
  },
  statsSent: {
    id: 'app.containers.editview.statsSent',
    defaultMessage: 'Stats Sent'
  },
  grade: {
    id: 'app.containers.editview.grade',
    defaultMessage: 'Grade'
  },
  numberOfLamellas: {
    id: 'app.containers.editview.numberOfLamellas',
    defaultMessage: 'Number Of Lamellas'
  },
  fsc: {
    id: 'app.containers.editview.fsc',
    defaultMessage: 'Fsc'
  },
  numberOfLayers: {
    id: 'app.containers.InfeedRaw.numberOfLayers',
    defaultMessage: 'Layers'
  },
  numberOfLamellasStacked: {
    id: 'app.containers.editview.numberOfLamellasStacked',
    defaultMessage: 'Number Of Lamellas Stacked'
  },
  numberOfLamellasPlaned: {
    id: 'app.containers.editview.numberOfLamellasPlaned',
    defaultMessage: 'Number Of Lamellas Planed'
  },
  sortingState: {
    id: 'app.containers.editview.sortingState',
    defaultMessage: 'Sorting State'
  },
  created_at: {
    id: 'app.containers.editview.created_at',
    defaultMessage: 'Created_at'
  },
  updated_at: {
    id: 'app.containers.editview.updated_at',
    defaultMessage: 'Updated_at'
  },
  ante_package_id: {
    id: 'app.containers.editview.ante_package_id',
    defaultMessage: 'Ante_package_id'
  },
  productionDate: {
    id: 'app.containers.editview.productionDate',
    defaultMessage: 'Production Date'
  },
  qualityId: {
    id: 'app.containers.editview.qualityId',
    defaultMessage: 'Quality Id'
  },
  packageWidth: {
    id: 'app.containers.editview.packageWidth',
    defaultMessage: 'Package Width'
  },
  articleId: {
    id: 'app.containers.editview.articleId',
    defaultMessage: 'Article Id'
  },
  materialId: {
    id: 'app.containers.editview.materialId',
    defaultMessage: 'Material Id'
  },
  articleNumber: {
    id: 'app.containers.editview.articleNumber',
    defaultMessage: 'Article Number'
  },
  lmNetto: {
    id: 'app.containers.editview.lmNetto',
    defaultMessage: 'Lm Netto'
  },
  lmBrutto: {
    id: 'app.containers.editview.lmBrutto',
    defaultMessage: 'Lm Brutto'
  },
  packageHeight: {
    id: 'app.containers.editview.packageHeight',
    defaultMessage: 'Package Height'
  },
  destination: {
    id: 'app.containers.InfeedRaw.destination',
    defaultMessage: 'Destination'
  },
  shuttleEnableToMove: {
    id: 'app.containers.InfeedRaw.shuttleEnableToMove',
    defaultMessage: 'Shuttle Can Move'
  },
  shuttleBusy: {
    id: 'app.containers.InfeedRaw.shuttleBusy',
    defaultMessage: 'Shuttle Is Busy'
  },
  shuttleStoppedFromOP: {
    id: 'app.containers.InfeedRaw.shuttleStoppedFromOP',
    defaultMessage: 'Shuttle Stopped (OP)'
  },
  shuttleError: {
    id: 'app.containers.InfeedRaw.shuttleError',
    defaultMessage: 'Shuttle Error'
  },
  shuttleFull: {
    id: 'app.containers.InfeedRaw.shuttleFull',
    defaultMessage: 'Shuttle Is Full'
  },
  gatesClosed: {
    id: 'app.containers.InfeedRaw.gatesClosed',
    defaultMessage: 'Gate Closed'
  },
  deleteAllMovementOrders: {
    id: 'app.containers.InfeedRaw.deleteAllMovementOrders',
    defaultMessage: 'Delete all orders'
  },
  piecesMismatch: {
    id: 'app.containers.InfeedRaw.piecesMismatch',
    defaultMessage: 'Pieces Missmatch'
  },
  needsToEmpty: {
    id: 'app.containers.InfeedRaw.needsToEmpty',
    defaultMessage: 'Requires emptying'
  },
  LMToXcut: {
    id: 'app.containers.InfeedRaw.LMToXcut',
    defaultMessage: 'LM to XCut'
  },
  goldeneyeDimensions: {
    id: 'app.containers.Goldeneye.goldeneyeDimensions',
    defaultMessage: 'Dim.: '
  },
  goldeneyeEmptyList: {
    id: 'app.containers.Goldeneye.goldeneyeEmptyList',
    defaultMessage: ' Empty List '
  },
  placeEmpty: {
    id: 'app.containers.editview.placeEmpty',
    defaultMessage: 'Place Empty'
  },
  errorPackage: {
    id: 'app.containers.editview.errorPackage',
    defaultMessage: 'Error Package'
  },
  packEditable: {
    id: 'app.containers.editview.packEditable',
    defaultMessage: 'Pack Editable'
  },
  pos: {
    id: 'app.containers.editview.pos',
    defaultMessage: 'Pos'
  },
  storageNumber: {
    id: 'app.containers.editview.storageNumber',
    defaultMessage: 'Storage Number'
  },
  takenOutAt: {
    id: 'app.containers.editview.takenOutAt',
    defaultMessage: 'Taken Out At'
  },
  ledinek_package_id: {
    id: 'app.containers.editview.ledinek_package_id',
    defaultMessage: 'Ledinek_package_id'
  },
  finishedArticleId: {
    id: 'app.containers.editview.finishedArticleId',
    defaultMessage: 'Finished Article Id'
  },
  waitingForNextPackageDataStacker: {
    id: 'app.containers.editview.waitingForNextPackageDataStacker',
    defaultMessage: 'Waiting For Next Package Data Stacker'
  },
  cumulativeWood: {
    id: 'app.containers.editview.cumulativeWood',
    defaultMessage: 'Cumulative Wood'
  },
  accumulatorBeforeXcut: {
    id: 'app.containers.editview.accumulatorBeforeXcut',
    defaultMessage: 'accumulatorBeforeXcut'
  },
  afterXCutBoards: {
    id: 'app.containers.editview.afterXCutBoards',
    defaultMessage: 'afterXCutBoards'
  },
  plannedLamellaThickness: {
    id: 'app.containers.editview.plannedLamellaThickness',
    defaultMessage: 'Lamella Planned Thickness'
  },
  lamellaPlannedThickness: {
    id: 'app.containers.editview.lamellaPlannedThickness',
    defaultMessage: 'Lamella Planned Thickness'
  },
  plannedLamellaWidth: {
    id: 'app.containers.editview.plannedLamellaWidth',
    defaultMessage: 'Lamella Planned Width'
  },
  lamellaPlannedWidth: {
    id: 'app.containers.editview.lamellaPlannedWidth',
    defaultMessage: 'Lamella Planned Width'
  },
  plannedWidth: {
    id: 'app.containers.editview.plannedWidth',
    defaultMessage: 'Planned Width'
  },
  plannedThickness: {
    id: 'app.containers.editview.plannedThickness',
    defaultMessage: 'Planned Thickness'
  },
  numLamellas: {
    id: 'app.containers.editview.numLamellas',
    defaultMessage: 'Num Lamellas'
  },
  lamellas: {
    id: 'app.containers.editview.lamellas',
    defaultMessage: 'Lamellas'
  },
  insNumLamellas: {
    id: 'app.containers.editview.insNumLamellas',
    defaultMessage: 'ISO Num Lamellas'
  },
  omitCalculation: {
    id: 'app.containers.editview.omitCalculation',
    defaultMessage: 'Do not calculate'
  },
  currentBatchNumber: {
    id: 'app.containers.editview.currentBatchNumber',
    defaultMessage: 'Current Batch Number'
  },
  zincDoneNumLamellas: {
    id: 'app.containers.editview.zincDoneNumLamellas',
    defaultMessage: 'Zinc Done Num Lamellas'
  },
  planedLamellasNumber: {
    id: 'app.containers.editview.planedLamellasNumber',
    defaultMessage: 'Planer Done Num Lamellas'
  },
  stackedLamellasNumber: {
    id: 'app.containers.editview.stackedLamellasNumber',
    defaultMessage: 'Stacker Done Num Lamellas'
  },
  insZincDoneNumLamellas: {
    id: 'app.containers.editview.insZincDoneNumLamellas',
    defaultMessage: 'ISO Zinc Done Num Lamellas'
  },
  zPressDoneNumLamellas: {
    id: 'app.containers.editview.zPressDoneNumLamellas',
    defaultMessage: 'Z Press Done Num Lamellas'
  },
  orientation: {
    id: 'app.containers.editview.orientation',
    defaultMessage: 'Orientation'
  },
  producingOnZinc: {
    id: 'app.containers.editview.producingOnZinc',
    defaultMessage: 'Producing On Zinc'
  },
  finishedProducingOnZinc: {
    id: 'app.containers.editview.finishedProducingOnZinc',
    defaultMessage: 'Finished Producing On Zinc'
  },
  producingOnISoZinc: {
    id: 'app.containers.editview.producingOnISoZinc',
    defaultMessage: 'Producing On ISO Zinc'
  },
  finishedProducingInsZinc: {
    id: 'app.containers.editview.finishedProducingInsZinc',
    defaultMessage: 'Finished Producing On ISO Zinc'
  },
  layerNo: {
    id: 'app.containers.editview.layerNo',
    defaultMessage: 'Layer No'
  },
  rotation: {
    id: 'app.containers.editview.rotation',
    defaultMessage: 'Rotation'
  },
  numOfLamellas: {
    id: 'app.containers.editview.numOfLamellas',
    defaultMessage: 'Num Of Lamellas'
  },
  numOfLamellasPerLayer: {
    id: 'app.containers.editview.numOfLamellasPerLayer',
    defaultMessage: 'Num Of Lamellas Per Layer'
  },
  hasGlue: {
    id: 'app.containers.editview.hasGlue',
    defaultMessage: 'Has Glue'
  },
  layerDry: {
    id: 'app.containers.editview.layerDry',
    defaultMessage: 'Layer Dry'
  },
  inEtage: {
    id: 'app.containers.editview.inEtage',
    defaultMessage: 'In Etage'
  },
  separatorDistance: {
    id: 'app.containers.editview.separatorDistance',
    defaultMessage: 'Separator Distance'
  },
  numnKVHLayers: {
    id: 'app.containers.editview.numnKVHLayers',
    defaultMessage: 'Numn K V H Layers'
  },
  numLamellasPerLayer: {
    id: 'app.containers.editview.numLamellasPerLayer',
    defaultMessage: 'Num Lamellas Per Layer'
  },
  assignedToEtage: {
    id: 'app.containers.editview.assignedToEtage',
    defaultMessage: 'Assigned To Etage'
  },
  rawWidth: {
    id: 'app.containers.editview.rawWidth',
    defaultMessage: 'Raw Width'
  },
  lamellaRawWidth: {
    id: 'app.containers.editview.lamellaRawWidth',
    defaultMessage: 'Lamella Raw Width'
  },
  lamellaRawThickness: {
    id: 'app.containers.editview.lamellaRawThickness',
    defaultMessage: 'Lamella Raw Thickness'
  },
  rawSortingLoading: {
    id: 'app.containers.editview.rawSortingLoading',
    defaultMessage: 'Raw Sorting Loading'
  },
  rawThickness: {
    id: 'app.containers.editview.rawThickness',
    defaultMessage: 'Raw Thickness'
  },
  hasOneShorter: {
    id: 'app.containers.editview.hasOneShorter',
    defaultMessage: 'Has One Shorter'
  },
  hasTwoShorter: {
    id: 'app.containers.editview.hasTwoShorter',
    defaultMessage: 'Has Two Shorter'
  },
  lastLamellaToEtageTime: {
    id: 'app.containers.editview.lastLamellaToEtageTime',
    defaultMessage: 'Last Lamella To Etage Time'
  },
  etage: {
    id: 'app.containers.editview.etage',
    defaultMessage: 'Etage'
  },
  zincStartDate: {
    id: 'app.containers.editview.zincStartDate',
    defaultMessage: 'Zinc Start Date'
  },
  zincProductionTime: {
    id: 'app.containers.editview.zincProductionTime',
    defaultMessage: 'Zinc Production Time'
  },
  planerStartDate: {
    id: 'app.containers.editview.planerStartDate',
    defaultMessage: 'Planer Start Date'
  },
  planerProductionTime: {
    id: 'app.containers.editview.planerProductionTime',
    defaultMessage: 'Planer Production Time'
  },
  joinedDimensionId: {
    id: 'app.containers.editview.joinedDimensionId',
    defaultMessage: 'Joined Dimension Id'
  },
  inStorage: {
    id: 'app.containers.editview.inStorage',
    defaultMessage: 'In Storage'
  },
  fullyStored: {
    id: 'app.containers.editview.fullyStored',
    defaultMessage: 'Fully Stored'
  },
  preparedOnPress: {
    id: 'app.containers.editview.preparedOnPress',
    defaultMessage: 'Prepared On Press'
  },
  glueUsed: {
    id: 'app.containers.editview.glueUsed',
    defaultMessage: 'Glue Used'
  },
  awgTimeBetweenZinc: {
    id: 'app.containers.editview.awgTimeBetweenZinc',
    defaultMessage: 'Awg Time Between Zinc'
  },
  awgTimeBetweenPlan: {
    id: 'app.containers.editview.awgTimeBetweenPlan',
    defaultMessage: 'Awg Time Between Plan'
  },
  batchId: {
    id: 'app.containers.editview.batchId',
    defaultMessage: 'Batch Id'
  },
  primingTop: {
    id: 'app.containers.editview.primingTop',
    defaultMessage: 'Priming Top'
  },
  primingBottom: {
    id: 'app.containers.editview.primingBottom',
    defaultMessage: 'Priming Bottom'
  },
  chamferTop: {
    id: 'app.containers.editview.chamferTop',
    defaultMessage: 'Chamfer Top'
  },
  chamferBottom: {
    id: 'app.containers.editview.chamferBottom',
    defaultMessage: 'Chamfer Bottom'
  },
  requiresExactQuality: {
    id: 'app.containers.editview.requiresExactQuality',
    defaultMessage: 'Requires Exact Quality'
  },
  ist: {
    id: 'app.containers.editview.ist',
    defaultMessage: 'Ist'
  },
  seenOnCrossVacuum: {
    id: 'app.containers.editview.seenOnCrossVacuum',
    defaultMessage: 'Seen On Cross Vacuum'
  },
  isGluLam: {
    id: 'app.containers.editview.isGluLam',
    defaultMessage: 'Is Glu Lam'
  },
  crossFinished: {
    id: 'app.containers.editview.crossFinished',
    defaultMessage: 'Cross Finished'
  },
  qLamLength1: {
    id: 'app.containers.editview.qLamLength1',
    defaultMessage: 'Q Lam Length1'
  },
  stackingNumOfLayers: {
    id: 'app.containers.editview.stackingNumOfLayers',
    defaultMessage: 'Stacking Num Of Layers'
  },
  stackingLamellasPerLayer: {
    id: 'app.containers.editview.stackingLamellasPerLayer',
    defaultMessage: 'Stacking Lamellas Per Layer ff'
  },
  layersStacked: {
    id: 'app.containers.editview.layersStacked',
    defaultMessage: 'Layers Stacked'
  },
  layersStackedDrying: {
    id: 'app.containers.editview.layersStackedDrying',
    defaultMessage: 'Layers Stacked Drying'
  },
  groupString: {
    id: 'app.containers.editview.groupString',
    defaultMessage: 'Group String'
  },
  lamellasDrying: {
    id: 'app.containers.editview.lamellasDrying',
    defaultMessage: 'Lamellas Drying'
  },
  planingOffset: {
    id: 'app.containers.editview.planingOffset',
    defaultMessage: 'Planing Offset'
  },
  layerDone: {
    id: 'app.containers.editview.layerDone',
    defaultMessage: 'Layer Done'
  },
  canBeDeleted: {
    id: 'app.containers.editview.canBeDeleted',
    defaultMessage: 'Can Be Deleted'
  },
  assignedDryingSollwertLamellas: {
    id: 'app.containers.editview.assignedDryingSollwertLamellas',
    defaultMessage: 'Assigned Drying Sollwert Lamellas'
  },
  zincEndDate: {
    id: 'app.containers.editview.zincEndDate',
    defaultMessage: 'Zinc End Date'
  },
  planerEndDate: {
    id: 'app.containers.editview.planerEndDate',
    defaultMessage: 'Planer End Date'
  },
  zPressStartDate: {
    id: 'app.containers.editview.zPressStartDate',
    defaultMessage: 'Z Press Start Date'
  },
  zPressEndDate: {
    id: 'app.containers.editview.zPressEndDate',
    defaultMessage: 'Z Press End Date'
  },
  qLamellaLength: {
    id: 'app.containers.editview.qLamellaLength',
    defaultMessage: 'Q Lamella Length'
  },
  qLamellaNum: {
    id: 'app.containers.editview.qLamellaNum',
    defaultMessage: 'Q Lamella Num'
  },
  xcutOversize: {
    id: 'app.containers.editview.xcutOversize',
    defaultMessage: 'Xcut Oversize'
  },
  reserve: {
    id: 'app.containers.editview.reserve',
    defaultMessage: 'Reserve'
  },
  plannedLamellas: {
    id: 'app.containers.editview.plannedLamellas',
    defaultMessage: 'Planned Lamellas'
  },
  numMmRawMaterialNeeded: {
    id: 'app.containers.editview.numMmRawMaterialNeeded',
    defaultMessage: 'Num Mm Raw Material Needed'
  },
  finishedAtZincAt: {
    id: 'app.containers.editview.finishedAtZincAt',
    defaultMessage: 'Finished At Zinc At'
  },
  startedAtZincAt: {
    id: 'app.containers.editview.startedAtZincAt',
    defaultMessage: 'Started At Zinc At'
  },
  awgTimeBetweenZincsSum: {
    id: 'app.containers.editview.awgTimeBetweenZincsSum',
    defaultMessage: 'Awg Time Between Zincs Sum'
  },
  numOfZincs: {
    id: 'app.containers.editview.numOfZincs',
    defaultMessage: 'Num Of Zincs'
  },
  awgZincStart: {
    id: 'app.containers.editview.awgZincStart',
    defaultMessage: 'Awg Zinc Start'
  },
  awgTimeBetweenPlanSum: {
    id: 'app.containers.editview.awgTimeBetweenPlanSum',
    defaultMessage: 'Awg Time Between Plan Sum'
  },
  numOfPlan: {
    id: 'app.containers.editview.numOfPlan',
    defaultMessage: 'Num Of Plan'
  },
  awgPlanStart: {
    id: 'app.containers.editview.awgPlanStart',
    defaultMessage: 'Awg Plan Start'
  },
  materialUsage: {
    id: 'app.containers.editview.materialUsage',
    defaultMessage: 'Material Usage'
  },
  enoughRawMaterial: {
    id: 'app.containers.editview.enoughRawMaterial',
    defaultMessage: 'Enough Raw Material'
  },
  assignedBeforeXCut: {
    id: 'app.containers.editview.assignedBeforeXCut',
    defaultMessage: 'Assigned Before X Cut'
  },
  repairOrder: {
    id: 'app.containers.editview.repairOrder',
    defaultMessage: 'Repair Order'
  },
  extraLamellaOrder: {
    id: 'app.containers.editview.extraLamellaOrder',
    defaultMessage: 'Extra Lamella Order'
  },
  currentlyLoadingMaterialFor: {
    id: 'app.containers.editview.currentlyLoadingMaterialFor',
    defaultMessage: 'Currently Loading Material For'
  },
  currentlyNeededMaterial: {
    id: 'app.components.signalCard.currentlyNeededMaterial',
    defaultMessage: 'Needs Material'
  },
  dimensionsSignalCard: {
    id: 'app.components.signalCard.dimensionsSignalCard',
    defaultMessage: 'Dimensions '
  },
  numOfBoardsSignalCard: {
    id: 'app.components.signalCard.numOfBoardsSignalCard',
    defaultMessage: 'Boards '
  },
  beforeSinglingSignalCard: {
    id: 'app.components.signalCard.beforeSinglingSignalCard',
    defaultMessage: 'Before Singling '
  },
  afterSinglingSignalCard: {
    id: 'app.components.signalCard.afterSinglingSignalCard',
    defaultMessage: 'After Singling '
  },
  alltogetherSignalCard: {
    id: 'app.components.signalCard.alltogetherSignalCard',
    defaultMessage: 'Alltogether '
  },
  producingOnZPress: {
    id: 'app.containers.editview.producingOnZPress',
    defaultMessage: 'Producing On Z Press'
  },
  finishedProducingOnZPress: {
    id: 'app.containers.editview.finishedProducingOnZPress',
    defaultMessage: 'Finished Producing On Z Press'
  },
  errorGroup: {
    id: 'app.containers.editview.errorGroup',
    defaultMessage: 'Error Group'
  },
  stored: {
    id: 'app.containers.editview.stored',
    defaultMessage: 'Stored'
  },
  numOfLamellasStored: {
    id: 'app.containers.editview.numOfLamellasStored',
    defaultMessage: 'Number of lamellas stored'
  },
  isActiveInClt: {
    id: 'app.containers.editview.isActiveInClt',
    defaultMessage: 'Active in CLT'
  },
  errorLoading: {
    id: 'app.containers.editview.errorLoading',
    defaultMessage: 'Error loading'
  },
  throughZPress: {
    id: 'app.containers.editview.throughZPress',
    defaultMessage: 'Through Z Press'
  },
  numberOfPlates: {
    id: 'app.containers.editview.numberOfPlates',
    defaultMessage: 'Number Of Plates'
  },
  zPressDonePlates: {
    id: 'app.containers.editview.zPressDonePlates',
    defaultMessage: 'Zpress Nr. Of Plates done'
  },
  goesToGigaPress: {
    id: 'app.containers.editview.goesToGigaPress',
    defaultMessage: 'Goes To Giga Press'
  },
  onForkliftPlace: {
    id: 'app.containers.editview.onForkliftPlace',
    defaultMessage: 'On Forklift Place'
  },
  numLamellasQ1: {
    id: 'app.containers.editview.numLamellasQ1',
    defaultMessage: 'Num Lamellas Q1'
  },
  lamellaLengthQ1: {
    id: 'app.containers.editview.lamellaLengthQ1',
    defaultMessage: 'Lamella Length Q1'
  },
  lamellaLengthQ2: {
    id: 'app.containers.editview.lamellaLengthQ2',
    defaultMessage: 'Lamella Length Q2'
  },
  numLamellasQ2: {
    id: 'app.containers.editview.numLamellasQ2',
    defaultMessage: 'Num Lamellas Q2'
  },
  acknowledgedAtZinc: {
    id: 'app.containers.editview.acknowledgedAtZinc',
    defaultMessage: 'Acknowledged At Zinc'
  },
  orderColor: {
    id: 'app.containers.editview.orderColor',
    defaultMessage: 'Order Color'
  },
  totalZPressLength: {
    id: 'app.containers.editview.totalZPressLength',
    defaultMessage: 'Total Z Press Length'
  },
  totalZPressPlates: {
    id: 'app.containers.editview.totalZPressPlates',
    defaultMessage: 'Total Z Press Plates'
  },
  totalZPressPlatesDone: {
    id: 'app.containers.editview.totalZPressPlatesDone',
    defaultMessage: 'Total Z Press Plates Done'
  },
  noCutPlateWidth: {
    id: 'app.containers.editview.noCutPlateWidth',
    defaultMessage: 'No Cut Plate Width'
  },
  noGroupMode: {
    id: 'app.containers.editview.noGroupMode',
    defaultMessage: 'No Group Mode'
  },
  paNr: {
    id: 'app.containers.editview.paNr',
    defaultMessage: 'Pa Nr'
  },
  orderCharge: {
    id: 'app.containers.editview.orderCharge',
    defaultMessage: 'Order Charge'
  },
  skipDimension: {
    id: 'app.containers.editview.skipDimension',
    defaultMessage: 'Skip Dimension'
  },
  isKVH: {
    id: 'app.containers.editview.isKVH',
    defaultMessage: 'Is K V H'
  },
  currentDate: {
    id: 'app.containers.editview.currentDate',
    defaultMessage: 'Current Date'
  },
  planerPackage: {
    id: 'app.containers.editview.planerPackage',
    defaultMessage: 'Planer Package'
  },
  numberOfBoardsPerLayer: {
    id: 'app.containers.editview.numberOfBoardsPerLayer',
    defaultMessage: 'Number Of Boards Per Layer'
  },
  pressChainAfterRollers: {
    id: 'app.containers.editview.pressChainAfterRollers',
    defaultMessage: 'Press Chain After Rollers'
  },
  productionOrdersHashCode: {
    id: 'app.containers.editview.productionOrdersHashCode',
    defaultMessage: 'Production Orders Hash Code'
  },
  vacuumMode: {
    id: 'app.containers.editview.vacuumMode',
    defaultMessage: 'Vacuum Mode'
  },
  stationRequest: {
    id: 'app.containers.editview.stationRequest',
    defaultMessage: 'Station Request'
  },
  ch_w_dp_bool: {
    id: 'app.containers.editview.ch_w_dp_bool',
    defaultMessage: 'Ch_w_dp_bool'
  },
  waitingForNextPackageDataSuperplan: {
    id: 'app.containers.editview.waitingForNextPackageDataSuperplan',
    defaultMessage: 'Waiting For Next Package Data Superplan'
  },
  lastGoldenEyeLamella: {
    id: 'app.containers.editview.lastGoldenEyeLamella',
    defaultMessage: 'Last Golden Eye Lamella'
  },
  groupUUID: {
    id: 'app.containers.editview.groupUUID',
    defaultMessage: 'Group U U I D'
  },
  panelWidth: {
    id: 'app.containers.editview.panelWidth',
    defaultMessage: 'Panel Width'
  },
  panelThickness: {
    id: 'app.containers.editview.panelThickness',
    defaultMessage: 'Panel Thickness'
  },
  panelLength: {
    id: 'app.containers.editview.panelLength',
    defaultMessage: 'Panel Length'
  },
  firstLayerOfGlueTime: {
    id: 'app.containers.editview.firstLayerOfGlueTime',
    defaultMessage: 'First Layer Of Glue Time'
  },
  combinedPressId: {
    id: 'app.containers.editview.combinedPressId',
    defaultMessage: 'Combined Press ID'
  },
  pressTimeStart: {
    id: 'app.containers.editview.pressTimeStart',
    defaultMessage: 'Press Time Start'
  },
  pressTimeEnd: {
    id: 'app.containers.editview.pressTimeEnd',
    defaultMessage: 'Press Time End'
  },
  orderNumber: {
    id: 'app.containers.editview.orderNumber',
    defaultMessage: 'Order Number'
  },
  client: {
    id: 'app.containers.editview.client',
    defaultMessage: 'Client'
  },
  pressureRAwgNumOfSamples: {
    id: 'app.containers.editview.pressureRAwgNumOfSamples',
    defaultMessage: 'Pressure R Awg Num Of Samples'
  },
  pressureRAwgSum: {
    id: 'app.containers.editview.pressureRAwgSum',
    defaultMessage: 'Pressure R Awg Sum'
  },
  pressureAwgFinal: {
    id: 'app.containers.editview.pressureAwgFinal',
    defaultMessage: 'Pressure Awg Final'
  },
  sollDruckAwgNumOfSamples: {
    id: 'app.containers.editview.sollDruckAwgNumOfSamples',
    defaultMessage: 'Soll Druck Awg Num Of Samples'
  },
  sollDruckAwgSum: {
    id: 'app.containers.editview.sollDruckAwgSum',
    defaultMessage: 'Soll Druck Awg Sum'
  },
  sollDruckAwgFinal: {
    id: 'app.containers.editview.sollDruckAwgFinal',
    defaultMessage: 'Soll Druck Awg Final'
  },
  optimizationDone: {
    id: 'app.containers.editview.optimizationDone',
    defaultMessage: 'Optimization Done'
  },
  sentToPress: {
    id: 'app.containers.editview.sentToPress',
    defaultMessage: 'Sent To Press'
  },
  status: {
    id: 'app.containers.editview.status',
    defaultMessage: 'Status'
  },
  glueAmount: {
    id: 'app.containers.editview.glueAmount',
    defaultMessage: 'Glue Amount'
  },
  targetPressure: {
    id: 'app.containers.editview.targetPressure',
    defaultMessage: 'Target Pressure'
  },
  targetSollDruck: {
    id: 'app.containers.editview.targetSollDruck',
    defaultMessage: 'Target Soll Druck'
  },
  recipe: {
    id: 'app.containers.editview.recipe',
    defaultMessage: 'Recipe'
  },
  glueForPress: {
    id: 'app.containers.editview.glueForPress',
    defaultMessage: 'Glue For Press'
  },
  cutMode: {
    id: 'app.containers.editview.cutMode',
    defaultMessage: 'Cut Mode'
  },
  masterpanelpakID: {
    id: 'app.containers.editview.masterpanelpakID',
    defaultMessage: 'Masterpanelpak I D'
  },
  batchText: {
    id: 'app.containers.editview.batchText',
    defaultMessage: 'Batch Text'
  },
  comission: {
    id: 'app.containers.editview.comission',
    defaultMessage: 'Comission'
  },
  articleCode: {
    id: 'app.containers.editview.articleCode',
    defaultMessage: 'Article Code'
  },
  sortingInfeedPlannedThickness: {
    id: 'app.containers.editview.sortingInfeedPlannedThickness',
    defaultMessage: 'Sorting Infeed Planned Thickness'
  },
  istNumBoards: {
    id: 'app.containers.editview.istNumBoards',
    defaultMessage: 'Ist Num Boards'
  },
  sequenceNumber: {
    id: 'app.containers.editview.sequenceNumber',
    defaultMessage: 'Sequence Number'
  },
  layerSequenceNumber: {
    id: 'app.containers.editview.layerSequenceNumber',
    defaultMessage: 'Layer Sequence Number'
  },
  usage: {
    id: 'app.containers.editview.usage',
    defaultMessage: 'Usage'
  },
  sortedUsage: {
    id: 'app.containers.editview.sortedUsage',
    defaultMessage: 'Sorted Usage'
  },
  fillingDisabled: {
    id: 'app.containers.editview.fillingDisabled',
    defaultMessage: 'Filling Disabled'
  },
  numberOfBoardsBeforePlaner: {
    id: 'app.containers.editview.numberOfBoardsBeforePlaner',
    defaultMessage: 'Number Of Boards Before Planer'
  },
  currentMaterialPackageAndLengthString: {
    id: 'app.containers.editview.currentMaterialPackageAndLengthString',
    defaultMessage: 'Current Material'
  },
  dryingTime: {
    id: 'app.containers.editview.dryingTime',
    defaultMessage: 'Drying Time'
  },
  maxMoistureLimit: {
    id: 'app.containers.editview.maxMoistureLimit',
    defaultMessage: 'Max Moisture Limit'
  },
  loaderPCData: {
    id: 'app.containers.editview.loaderPCData',
    defaultMessage: 'Loader P C Data'
  },
  emptying: {
    id: 'app.containers.editview.emptying',
    defaultMessage: 'Emptying'
  },
  operational: {
    id: 'app.containers.editview.operational',
    defaultMessage: 'Operational'
  },
  disabled: {
    id: 'app.containers.editview.fullDisabled',
    defaultMessage: 'Disabled'
  },
  full: {
    id: 'app.containers.editview.full',
    defaultMessage: 'Full'
  },
  fullSolis: {
    id: 'app.containers.editview.fullSolis',
    defaultMessage: 'Full PLC / Full PC '
  },
  joinedDim: {
    id: 'app.containers.editview.joinedDim',
    defaultMessage: 'Joined Dim'
  },
  layer: {
    id: 'app.containers.DashboardXLAM.layer',
    defaultMessage: 'Layer'
  },
  sollNumBoards: {
    id: 'app.containers.editview.sollNumBoards',
    defaultMessage: 'Soll Num Boards'
  },
  compartmentIndex: {
    id: 'app.containers.editview.compartmentIndex',
    defaultMessage: 'Compartment Index'
  },
  manualEmptying: {
    id: 'app.containers.editview.manualEmptying',
    defaultMessage: 'Manual Emptying'
  },
  negativeWoodBufferForBetterDimensionNext: {
    id: 'app.containers.editview.negativeWoodBufferForBetterDimensionNext',
    defaultMessage: 'Negative Wood Buffer For Better Dimension Next'
  },
  noGroupModeWidthTakeaway: {
    id: 'app.containers.editview.noGroupModeWidthTakeaway',
    defaultMessage: 'No Group Mode Width Takeaway'
  },
  sortingInfeedPlannedWidthOffset: {
    id: 'app.containers.editview.sortingInfeedPlannedWidthOffset',
    defaultMessage: 'Sorting Infeed Planned Width Offset'
  },
  waitingForDryingOrder: {
    id: 'app.containers.editview.waitingForDryingOrder',
    defaultMessage: 'Waiting For Drying Order'
  },
  etageNumber: {
    id: 'app.containers.editview.etageNumber',
    defaultMessage: 'Etage Number'
  },
  compartmentNumber: {
    id: 'app.containers.editview.compartmentNumber',
    defaultMessage: 'Compartment Number'
  },
  indexInSequence: {
    id: 'app.containers.editview.indexInSequence',
    defaultMessage: 'Index In Sequence'
  },
  timeInStorage: {
    id: 'app.containers.editview.timeInStorage',
    defaultMessage: 'Time In Storage'
  },
  isDry: {
    id: 'app.containers.editview.isDry',
    defaultMessage: 'Is Dry'
  },
  etageCurrentlyFilling: {
    id: 'app.containers.editview.etageCurrentlyFilling',
    defaultMessage: 'Etage Currently Filling'
  },
  minMoistureLimit: {
    id: 'app.containers.editview.minMoistureLimit',
    defaultMessage: 'Min Moisture Limit'
  },
  combinedAllowanceFromVacuum: {
    id: 'app.containers.editview.combinedAllowanceFromVacuum',
    defaultMessage: 'Combined Allowance From Vacuum'
  },
  goldenEyeSortState: {
    id: 'app.containers.editview.goldenEyeSortState',
    defaultMessage: 'Golden Eye Sort State'
  },
  currentWoodOnLine: {
    id: 'app.containers.editview.currentWoodOnLine',
    defaultMessage: 'Current Wood On Line'
  },
  planerSettings: {
    id: 'app.containers.editview.planerSettings',
    defaultMessage: 'Planer Settings'
  },
  fillKVH: {
    id: 'app.containers.editview.fillKVH',
    defaultMessage: 'Fill K V H'
  },
  editEnabled1: {
    id: 'app.containers.editview.editEnabled1',
    defaultMessage: 'Edit Enabled1'
  },
  pressExit1: {
    id: 'app.containers.editview.pressExit1',
    defaultMessage: 'Press Exit1'
  },
  pressExit: {
    id: 'app.containers.editview.pressExit',
    defaultMessage: 'Press Exit'
  },
  editEnabled2: {
    id: 'app.containers.editview.editEnabled2',
    defaultMessage: 'Edit Enabled2'
  },
  pressExit2: {
    id: 'app.containers.editview.pressExit2',
    defaultMessage: 'Press Exit2'
  },
  pressChainAfterPusher: {
    id: 'app.containers.editview.pressChainAfterPusher',
    defaultMessage: 'Press Chain After Pusher'
  },
  editEnabled3: {
    id: 'app.containers.editview.editEnabled3',
    defaultMessage: 'Edit Enabled3'
  },
  editEnabled4: {
    id: 'app.containers.editview.editEnabled4',
    defaultMessage: 'Edit Enabled4'
  },
  noGroupModeCOversize: {
    id: 'app.containers.editview.noGroupModeCOversize',
    defaultMessage: 'No Group Mode C Oversize'
  },
  order1: {
    id: 'app.containers.editview.order1',
    defaultMessage: 'Order1'
  },
  allowanceRawInfeed: {
    id: 'app.containers.editview.allowanceRawInfeed',
    defaultMessage: 'Allowance Raw Infeed'
  },
  currentVacuumMode: {
    id: 'app.containers.editview.currentVacuumMode',
    defaultMessage: 'Current Vacuum Mode'
  },
  totalNeededWithoutWoodOnLine: {
    id: 'app.containers.editview.totalNeededWithoutWoodOnLine',
    defaultMessage: 'Total Needed Without Wood On Line'
  },
  endId: {
    id: 'app.containers.editview.endId',
    defaultMessage: 'End Id'
  },
  currentSetNumberOfBoards: {
    id: 'app.containers.editview.currentSetNumberOfBoards',
    defaultMessage: 'Current Set Number Of Boards'
  },
  mitnemerPlaces: {
    id: 'app.containers.editview.mitnemerPlaces',
    defaultMessage: 'Mitnemer Places'
  },
  superplanOrder1: {
    id: 'app.containers.editview.superplanOrder1',
    defaultMessage: 'Multiplan order 1'
  },
  superplanOrder2: {
    id: 'app.containers.editview.superplanOrder2',
    defaultMessage: 'Multiplan order 2'
  },
  zPressOrder: {
    id: 'app.containers.editview.zPressOrder',
    defaultMessage: 'Z Press Order'
  },
  zPressOrder1: {
    id: 'app.containers.editview.zPressOrder1',
    defaultMessage: 'Z Press 1 Order'
  },
  zPressOrder2: {
    id: 'app.containers.editview.zPressOrder2',
    defaultMessage: 'Z Press 2 Order'
  },
  zPressLastDataSent1: {
    id: 'app.containers.editview.zPressLastDataSent1',
    defaultMessage: 'Z Press 1 Last sent Order'
  },
  zPressLastDataSent2: {
    id: 'app.containers.editview.zPressLastDataSent2',
    defaultMessage: 'Z Press 2 Last sent Order'
  },
  liftZPress1Order: {
    id: 'app.containers.editview.liftZPress1Order',
    defaultMessage: 'Z Press 1 Lift Order'
  },
  liftZPress2Order: {
    id: 'app.containers.editview.liftZPress2Order',
    defaultMessage: 'Z Press 2 Lift Order'
  },
  lastCrossLiftJD: {
    id: 'app.containers.editview.lastCrossLiftJD',
    defaultMessage: 'Last lift cross order'
  },
  ccZPress1Holder1: {
    id: 'app.containers.editview.ccZPress1Holder1',
    defaultMessage: 'ZPress 1 holder 1'
  },
  ccZPress1Holder2: {
    id: 'app.containers.editview.ccZPress1Holder2',
    defaultMessage: 'ZPress 1 holder 2'
  },
  ccZPress2Holder1: {
    id: 'app.containers.editview.ccZPress2Holder1',
    defaultMessage: 'ZPress 2 holder 1'
  },
  ccZPress2Holder2: {
    id: 'app.containers.editview.ccZPress2Holder2',
    defaultMessage: 'ZPress 2 holder 2'
  },
  liftZPres1Order: {
    id: 'app.containers.editview.liftZPres1Order',
    defaultMessage: 'Lift ZPress order 1'
  },
  liftZPres2Order: {
    id: 'app.containers.editview.liftZPres2Order',
    defaultMessage: 'Lift ZPress order 2'
  },
  numberOfBoards: {
    id: 'app.containers.editview.numberOfBoards',
    defaultMessage: 'Number Of Boards'
  },
  manualFillingEtage: {
    id: 'app.containers.editview.manualFillingEtage',
    defaultMessage: 'Manual Filling Etage'
  },
  sortingInfeedPlannedWidth: {
    id: 'app.containers.editview.sortingInfeedPlannedWidth',
    defaultMessage: 'Sorting Infeed Planned Width'
  },
  sortingInfeedWidthOffset: {
    id: 'app.containers.editview.sortingInfeedWidthOffset',
    defaultMessage: 'Sorting Infeed Width Offset'
  },
  sortingInfeedThicknessOffset: {
    id: 'app.containers.editview.sortingInfeedThicknessOffset',
    defaultMessage: 'Sorting Infeed Thickness Offset'
  },
  totalNeededAfterSingling: {
    id: 'app.containers.editview.totalNeededAfterSingling',
    defaultMessage: 'Total Needed After Singling'
  },
  stackingPackage: {
    id: 'app.containers.editview.stackingPackage',
    defaultMessage: 'Stacking Package'
  },
  defaultVacuumLocation: {
    id: 'app.containers.editview.defaultVacuumLocation',
    defaultMessage: 'Default Vacuum Location'
  },
  manualLoading: {
    id: 'app.containers.editview.manualLoading',
    defaultMessage: 'Manual Loading'
  },
  freigabeVakum: {
    id: 'app.containers.editview.freigabeVakum',
    defaultMessage: 'Vacuum Allowance'
  },
  shuttleAvailable: {
    id: 'app.containers.editview.shuttleAvailable',
    defaultMessage: 'Shuttle Available'
  },
  wraworActive: {
    id: 'app.containers.editview.wraworActive',
    defaultMessage: 'Wrawor Active'
  },
  xFormActive: {
    id: 'app.containers.editview.xFormActive',
    defaultMessage: 'XForm Active'
  },
  wraworBeamDone: {
    id: 'app.containers.editview.wraworBeamDone',
    defaultMessage: 'Wrawor Beam Done'
  },
  vacuumAvailable: {
    id: 'app.containers.editview.vacuumAvailable',
    defaultMessage: 'Vacuum Available'
  },
  crossroadsMoveAvailable: {
    id: 'app.containers.editview.crossroadsMoveAvailable',
    defaultMessage: 'Crossroads Move Available'
  },
  wraworReadyForPlate: {
    id: 'app.containers.editview.wraworReadyForPlate',
    defaultMessage: 'Wrawor Ready For Plate'
  },
  numberOfBoardsStacked: {
    id: 'app.containers.editview.numberOfBoardsStacked',
    defaultMessage: 'Number Of Boards Stacked'
  },
  combinedAllowanceRawInfeed: {
    id: 'app.containers.editview.combinedAllowanceRawInfeed',
    defaultMessage: 'Combined Allowance Raw Infeed'
  },
  sortingInfeedWidth: {
    id: 'app.containers.editview.sortingInfeedWidth',
    defaultMessage: 'Sorting Infeed Width'
  },
  productionSequenceHashCode: {
    id: 'app.containers.editview.productionSequenceHashCode',
    defaultMessage: 'Production Sequence Hash Code'
  },
  minTakeaway: {
    id: 'app.containers.editview.minTakeaway',
    defaultMessage: 'Min Takeaway'
  },
  wasteLamellaLength: {
    id: 'app.containers.editview.wasteLamellaLength',
    defaultMessage: 'Waste Lamella Length'
  },
  testLamellaLength: {
    id: 'app.containers.editview.testLamellaLength',
    defaultMessage: 'Test Lamella Length'
  },
  extraLamellaLength: {
    id: 'app.containers.editview.extraLamellaLength',
    defaultMessage: 'Extra Lamella Length'
  },
  packageLengthBuffer: {
    id: 'app.containers.editview.packageLengthBuffer',
    defaultMessage: 'Package Length Buffer'
  },
  averagePackageSize: {
    id: 'app.containers.editview.averagePackageSize',
    defaultMessage: 'Average package size'
  },
  clearWoodlayers: {
    id: 'app.containers.editview.clearWoodlayers',
    defaultMessage: 'Clear wood layers'
  },
  insertTestlayers: {
    id: 'app.containers.editview.insertTestlayers',
    defaultMessage: 'Insert test layers'
  },
  insertWastelayers: {
    id: 'app.containers.editview.insertWastelayers',
    defaultMessage: 'Insert waste layers'
  },
  packageTest: {
    id: 'app.containers.editview.packageTest',
    defaultMessage: 'Package test'
  },
  maxEncoderDifferenceFull: {
    id: 'app.containers.editview.maxEncoderDifferenceFull',
    defaultMessage: 'Max Encoder Difference Full'
  },
  separationDistance: {
    id: 'app.containers.editview.separationDistance',
    defaultMessage: 'Separation distance'
  },
  maxDryingEncoder: {
    id: 'app.containers.editview.maxDryingEncoder',
    defaultMessage: 'Max drying encoder'
  },
  groupBatchSize: {
    id: 'app.containers.editview.groupBatchSize',
    defaultMessage: 'Group batch size'
  },
  globalZPressOffset: {
    id: 'app.containers.editview.globalZPressOffset',
    defaultMessage: 'Global ZPress offset'
  },
  cncOversizeWidth: {
    id: 'app.containers.editview.cncOversizeWidth',
    defaultMessage: 'CNC oversize width'
  },
  cncOversizeLenght: {
    id: 'app.containers.editview.cncOversizeLenght',
    defaultMessage: 'CNC oversize length'
  },
  urgentRequest: {
    id: 'app.containers.editview.urgentRequest',
    defaultMessage: 'Urgent request'
  },
  FJMachine: {
    id: 'app.containers.editview.FJMachine',
    defaultMessage: 'Finger jointing machine'
  },
  maxTakeaway: {
    id: 'app.containers.editview.maxTakeaway',
    defaultMessage: 'Max Takeaway'
  },
  kvhOversize: {
    id: 'app.containers.editview.kvhOversize',
    defaultMessage: 'Kvh Oversize'
  },
  qOversize: {
    id: 'app.containers.editview.qOversize',
    defaultMessage: 'Q Oversize'
  },
  lOversize: {
    id: 'app.containers.editview.lOversize',
    defaultMessage: 'L Oversize'
  },
  nextPackageId: {
    id: 'app.containers.editview.nextPackageId',
    defaultMessage: 'Next Package Id'
  },
  maxPanelLength: {
    id: 'app.containers.editview.maxPanelLength',
    defaultMessage: 'Max Panel Length'
  },
  minPanelLength: {
    id: 'app.containers.editview.minPanelLength',
    defaultMessage: 'Min Panel Length'
  },
  maxPanelWidth: {
    id: 'app.containers.editview.maxPanelWidth',
    defaultMessage: 'Max Panel Width'
  },
  minPanelWidth: {
    id: 'app.containers.editview.minPanelWidth',
    defaultMessage: 'Min Panel Width'
  },
  maxLamellaLength: {
    id: 'app.containers.editview.maxLamellaLength',
    defaultMessage: 'Max Lamella Length'
  },
  minLamellaLength: {
    id: 'app.containers.editview.minLamellaLength',
    defaultMessage: 'Min Lamella Length'
  },
  maxLamellaWidth: {
    id: 'app.containers.editview.maxLamellaWidth',
    defaultMessage: 'Max Lamella Width'
  },
  minLamellaWidth: {
    id: 'app.containers.editview.minLamellaWidth',
    defaultMessage: 'Min Lamella Width'
  },
  maxLamellaThickness: {
    id: 'app.containers.editview.maxLamellaThickness',
    defaultMessage: 'Max Lamella Thickness'
  },
  minLamellaThickness: {
    id: 'app.containers.editview.minLamellaThickness',
    defaultMessage: 'Min Lamella Thickness'
  },
  minZincLength: {
    id: 'app.containers.editview.minZincLength',
    defaultMessage: 'Min Zinc Length'
  },
  maxZincLength: {
    id: 'app.containers.editview.maxZincLength',
    defaultMessage: 'Max Zinc Length'
  },
  cncOversize: {
    id: 'app.containers.editview.cncOversize',
    defaultMessage: 'Cnc Oversize'
  },
  goldenEyeConnected: {
    id: 'app.containers.editview.goldenEyeConnected',
    defaultMessage: 'Golden Eye Connected'
  },
  manualEmptyingID: {
    id: 'app.containers.editview.manualEmptyingID',
    defaultMessage: 'Manual Emptying I D'
  },
  percentageOfWoodBeforeGoldenEye: {
    id: 'app.containers.editview.percentageOfWoodBeforeGoldenEye',
    defaultMessage: 'Percentage Of Wood Before Golden Eye'
  },
  numberOfBoardsAfterPlaner: {
    id: 'app.containers.editview.numberOfBoardsAfterPlaner',
    defaultMessage: 'Number Of Boards After Planer'
  },
  sortingInfeedPlannedThicknessOffset: {
    id: 'app.containers.editview.sortingInfeedPlannedThicknessOffset',
    defaultMessage: 'Sorting Infeed Planned Thickness Offset'
  },
  emptyFromVacuumToZink: {
    id: 'app.containers.editview.emptyFromVacuumToZink',
    defaultMessage: 'Empty From Vacuum To Zink'
  },
  numberOfBoardsPlaned: {
    id: 'app.containers.editview.numberOfBoardsPlaned',
    defaultMessage: 'Number Of Boards Planed'
  },
  rawVacuumMode: {
    id: 'app.containers.editview.rawVacuumMode',
    defaultMessage: 'Raw Vacuum Mode'
  },
  pressPusher: {
    id: 'app.containers.editview.pressPusher',
    defaultMessage: 'Press Pusher'
  },
  sortingInfeedThickness: {
    id: 'app.containers.editview.sortingInfeedThickness',
    defaultMessage: 'Sorting Infeed Thickness'
  },
  panels: {
    id: 'app.containers.editview.panels',
    defaultMessage: 'Panels'
  },
  multiplePanels: {
    id: 'app.containers.editview.multiplePanels',
    defaultMessage: 'Multiple Panels'
  },
  panel: {
    id: 'app.containers.editview.panel',
    defaultMessage: 'Panel'
  },
  panelsInPress: {
    id: 'app.containers.editview.panelsInPress',
    defaultMessage: 'Panels in press'
  },
  joinedDimension: {
    id: 'app.containers.editview.joinedDimension',
    defaultMessage: 'Joined Dimension'
  },
  group: {
    id: 'app.containers.editview.group',
    defaultMessage: 'Group'
  },
  startId: {
    id: 'app.containers.editview.startId',
    defaultMessage: 'Start Id'
  },
  noGroupModeLOversize: {
    id: 'app.containers.editview.noGroupModeLOversize',
    defaultMessage: 'No Group Mode L Oversize'
  },
  manualFilling: {
    id: 'app.containers.editview.manualFilling',
    defaultMessage: 'Manual Filling'
  },
  currentNeededMaterialForLayer: {
    id: 'app.containers.editview.currentNeededMaterialForLayer',
    defaultMessage: 'Current Needed Material For Layer'
  },
  woodBufferForDimension: {
    id: 'app.containers.editview.woodBufferForDimension',
    defaultMessage: 'Wood Buffer For Dimension'
  },
  changeWidthFrom: {
    id: 'app.containers.editview.changeWidthFrom',
    defaultMessage: 'Change Width From'
  },
  changeWidthTo: {
    id: 'app.containers.editview.changeWidthTo',
    defaultMessage: 'Change Width To'
  },
  changeThicknessFrom: {
    id: 'app.containers.editview.changeThicknessFrom',
    defaultMessage: 'Change Thickness From'
  },
  changeThicknessTo: {
    id: 'app.containers.editview.changeThicknessTo',
    defaultMessage: 'Change Thickness To'
  },
  changeBoardDimensions: {
    id: 'app.containers.editview.changeBoardDimensions',
    defaultMessage: 'Change Board Dimensions'
  },
  makeExtraLamellasId: {
    id: 'app.containers.editview.makeExtraLamellasId',
    defaultMessage: 'Make Extra Lamellas Id'
  },
  makeExtraLamellasNum: {
    id: 'app.containers.editview.makeExtraLamellasNum',
    defaultMessage: 'Make Extra Lamellas Number'
  },
  makeExtraLamellas: {
    id: 'app.containers.editview.makeExtraLamellas',
    defaultMessage: 'Make Extra Lamellas'
  },
  changePlaneThicknessFrom: {
    id: 'app.containers.editview.changePlaneThicknessFrom',
    defaultMessage: 'Change Plane Thickness From'
  },
  changePlaneThicknessTo: {
    id: 'app.containers.editview.changePlaneThicknessTo',
    defaultMessage: 'Change Planed Thickness To'
  },
  changePlaneThickness: {
    id: 'app.containers.editview.changePlaneThickness',
    defaultMessage: 'Change Planed Thickness'
  },
  stickerTest: {
    id: 'app.containers.editview.stickerTest',
    defaultMessage: 'Sticker Test'
  },
  stickerPanelThickness: {
    id: 'app.containers.editview.stickerPanelThickness',
    defaultMessage: 'Sticker Panel Thickness'
  },
  stickerPanelTopQaulity: {
    id: 'app.containers.editview.stickerPanelTopQaulity',
    defaultMessage: 'Sticker Top Qaulity'
  },
  stickerPanelBottomQuality: {
    id: 'app.containers.editview.stickerPanelBottomQuality',
    defaultMessage: 'Sticker Panel Bottom Quality'
  },
  stickerPanelId: {
    id: 'app.containers.editview.stickerPanelId',
    defaultMessage: 'Sticker Panel ID'
  },
  currentVacuumWidth: {
    id: 'app.containers.editview.currentVacuumWidth',
    defaultMessage: 'Current Vacuum Width'
  },
  currentVacuumThickness: {
    id: 'app.containers.editview.currentVacuumThickness',
    defaultMessage: 'Current Vacuum Thickness'
  },
  currentVacuumLength: {
    id: 'app.containers.editview.currentVacuumLength',
    defaultMessage: 'Current Vacuum Length'
  },
  manualVacuumAllowance: {
    id: 'app.containers.editview.manualVacuumAllowance',
    defaultMessage: 'Manual Vacuum Allowance'
  },
  manualMultiplanAllowance: {
    id: 'app.containers.editview.manualMultiplanAllowance',
    defaultMessage: 'Manual Multiplan Allowance'
  },
  manualFillingID: {
    id: 'app.containers.editview.manualFillingID',
    defaultMessage: 'Manual Filling ID'
  },
  maxRawWidth: {
    id: 'app.containers.editview.maxRawWidth',
    defaultMessage: 'Max raw width'
  },
  minRawWidth: {
    id: 'app.containers.editview.minRawWidth',
    defaultMessage: 'Min raw width'
  },
  urgent: {
    id: 'app.containers.editview.urgent',
    defaultMessage: 'Urgent'
  },
  productId: {
    id: 'app.containers.editview.productId',
    defaultMessage: 'Product Id'
  },
  stackerNumber: {
    id: 'app.containers.editview.stackerNumber',
    defaultMessage: 'Stacker'
  },
  storagePercentageUsed: {
    id: 'app.containers.editview.storagePercentageUsed',
    defaultMessage: 'Storage percentage used'
  },
  wrapping: {
    id: 'app.containers.editview.wrapping',
    defaultMessage: 'Wrapping'
  },
  stopper: {
    id: 'app.containers.editview.stopper',
    defaultMessage: 'Anschlag'
  },
  jobCode: {
    id: 'app.containers.editview.jobCode',
    defaultMessage: 'Job code'
  },
  materialAlreadyLoadedFor: {
    id: 'app.containers.editview.materialAlreadyLoadedFor',
    defaultMessage: 'Already loaded for'
  },
  moveGroupDimChange: {
    id: 'app.containers.editview.moveGroupDimChange',
    defaultMessage: 'Move from next same dimension group on dimension breaks'
  },
  dimBreakId: {
    id: 'app.containers.editview.dimBreakId',
    defaultMessage: 'Dim Break id'
  },
  hasADimBreakOrder: {
    id: 'app.containers.editview.hasADimBreakOrder',
    defaultMessage: 'Has a dimension break order'
  },
  dimensionChangeFromNextGroup: {
    id: 'app.containers.editview.dimensionChangeFromNextGroup',
    defaultMessage: 'Dimension change order'
  },
  dimBreakOf: {
    id: 'app.containers.editview.dimBreakOf',
    defaultMessage: 'Dimension change of ID'
  },
  dimension: {
    id: 'app.containers.editview.dimension',
    defaultMessage: 'Dimension'
  },
  blockHeight: {
    id: 'app.containers.editview.blockHeight',
    defaultMessage: 'Panel stacking wood block height'
  },
  rollers1Height: {
    id: 'app.containers.editview.rollers1Height',
    defaultMessage: 'Rollers 1 height (CNC1)'
  },
  rollers2Height: {
    id: 'app.containers.editview.rollers2Height',
    defaultMessage: 'Rollers 2 height (CNC2)'
  },
  rollers3Height: {
    id: 'app.containers.editview.rollers3Height',
    defaultMessage: 'Rollers 3 height (EXIT)'
  },
  qOversizeMin: {
    id: 'app.containers.editview.qOversizeMin',
    defaultMessage: 'Q oversize MIN'
  },
  qOversizeMax: {
    id: 'app.containers.editview.qOversizeMax',
    defaultMessage: 'Q oversize MAX'
  },
  acceptableDimensionJumps: {
    id: 'app.containers.editview.acceptableDimensionJumps',
    defaultMessage: 'Acceptable dimension jumps'
  },
  oneByOneThreshold: {
    id: 'app.containers.editview.oneByOneThreshold',
    defaultMessage: 'One by one threshold'
  },
  addOrder: {
    id: 'app.containers.Tableview.addOrder',
    defaultMessage: 'Add new order'
  },
  addSupplier: {
    id: 'app.containers.Tableview.addSupplier',
    defaultMessage: 'Add new Supplier'
  },
  edit: {
    id: 'app.containers.editView.edit',
    defaultMessage: 'Edit'
  },
  add: {
    id: 'app.containers.editView.add',
    defaultMessage: 'Add'
  },
  formErrors: {
    id: 'app.containers.editView.formErrors',
    defaultMessage: 'Form is not completely filled!'
  },
  delete: {
    id: 'app.containers.editView.delete',
    defaultMessage: 'Delete'
  },
  transferToNewChild: {
    id: 'app.containers.editView.transferToNewChild',
    defaultMessage: 'Transfer data to new'
  },
  crossLayer: {
    id: 'app.containers.editView.crossLayer',
    defaultMessage: 'Cross layer'
  },
  insulationLayer: {
    id: 'app.containers.editView.insulationLayer',
    defaultMessage: 'Insulation layer'
  },
  insQLayer: {
    id: 'app.containers.editView.insQLayer',
    defaultMessage: 'Insulation Cross'
  },
  insLLayer: {
    id: 'app.containers.editView.insLLayer',
    defaultMessage: 'Insulation Long'
  },
  mLayer: {
    id: 'app.containers.editView.mLayer',
    defaultMessage: 'Multipiece Long'
  },
  longLayer: {
    id: 'app.containers.editView.longLayer',
    defaultMessage: 'Long layer'
  },
  addOrderWithRecipe: {
    id: 'app.containers.Tableview.addOrderWithRecipe',
    defaultMessage: 'Add Order with Recipe'
  },
  addProjectWithRecipe: {
    id: 'app.containers.Tableview.addProjectWithRecipe',
    defaultMessage: 'Add Project with new Length'
  },
  addLayerWithRecipe: {
    id: 'app.containers.Tableview.addLayerWithRecipe',
    defaultMessage: 'Add Recipe with recipe layers'
  },
  jdQueueTitle: {
    id: 'app.components.SideMenu.jdQueueTitle',
    defaultMessage: 'JD Queue'
  },
  closebtn: {
    id: 'app.containers.PackagesQueue.closebtn',
    defaultMessage: 'Close'
  },
  missinglamellreq: {
    id: 'app.containers.JoinedDimQueue.missinglamellreq',
    defaultMessage: 'Missing Lamellas request'
  },
  missingDimensions: {
    id: 'app.containers.JoinedDimQueue.missingDimensions',
    defaultMessage: 'Missing Dimensions'
  },
  extralamelreq: {
    id: 'app.containers.JoinedDimQueue.extralamelreq',
    defaultMessage: 'Extra Lamellas request'
  },
  testlamelreq: {
    id: 'app.containers.JoinedDimQueue.testlamelreq',
    defaultMessage: 'Test Lamellas request'
  },
  wastelamelreq: {
    id: 'app.containers.JoinedDimQueue.wastelamelreq',
    defaultMessage: 'Waste Lamellas request'
  },
  'missing-lamella-request': {
    id: 'app.containers.JoinedDimQueue.missingLamellaRequest',
    defaultMessage: 'Missing Lamella Request'
  },
  'extra-lamella-request': {
    id: 'app.containers.JoinedDimQueue.extraLamellaRequest',
    defaultMessage: 'Extra Lamella Request'
  },
  ignoreKVH: {
    id: 'app.containers.JoinedDimQueue.ignoreKVH',
    defaultMessage: 'Ignore KVH'
  },
  'test-lamella-request': {
    id: 'app.containers.JoinedDimQueue.testLamellaRequest',
    defaultMessage: 'Test Lamella Request'
  },
  'waste-lamella-request': {
    id: 'app.containers.JoinedDimQueue.wasteLamellaRequest',
    defaultMessage: 'Waste Lamella Request'
  },
  lagerShortTitle: {
    id: 'app.containers.DashboardXLAM.lagerShortTitle',
    defaultMessage: 'Lager Short'
  },
  press: {
    id: 'app.components.SideMenu.press',
    defaultMessage: 'Press'
  },
  extraStoragePlcTitle: {
    id: 'app.containers.MachineTitles.extraStoragePlcTitle',
    defaultMessage: 'Extra Lamella Storage PLC Signals'
  },
  extraLamellaTitle: {
    id: 'app.containers.MachineTitles.extraLamellaTitle',
    defaultMessage: 'Add Extra Lamella'
  },
  cutList: {
    id: 'app.containers.MachineTitles.cutList',
    defaultMessage: 'Cutlist'
  },
  trashLamellaTitle: {
    id: 'app.containers.MachineTitles.trashLamellaTitle',
    defaultMessage: 'Remove Lamella'
  },
  goldeneyePlcTitle: {
    id: 'app.containers.MachineTitles.goldeneyePlcTitle',
    defaultMessage: 'Goldeneye PLC Signals'
  },
  goldeneyeExtraLamellaTitle: {
    id: 'app.containers.MachineTitles.goldeneyeExtraLamellaTitle',
    defaultMessage: 'Extra Lamella'
  },
  goldeneyeTitle: {
    id: 'app.containers.MachineTitles.goldeneyeTitle',
    defaultMessage: 'Goldeneye'
  },
  beforexcutTitle: {
    id: 'app.containers.MachineTitles.beforexcutTitle',
    defaultMessage: 'Before XCUT Lamellas'
  },
  xcutPlcTitle: {
    id: 'app.containers.MachineTitles.xcutPlcTitle',
    defaultMessage: 'XCUT PLC Signals'
  },
  infeedPlcTitle: {
    id: 'app.containers.MachineTitles.infeedPlcTitle',
    defaultMessage: 'Infeed PLC Signals'
  },
  insulationPlcTitle: {
    id: 'app.containers.MachineTitles.insulationPlcTitle',
    defaultMessage: 'Vacuum Insulation PLC Signals'
  },
  crossZPressPlcTitle: {
    id: 'app.containers.MachineTitles.crossZPressPlcTitle',
    defaultMessage: 'Cross ZPress PLC Signals'
  },
  infeedSettingsTitle: {
    id: 'app.containers.MachineTitles.infeedSettingsTitle',
    defaultMessage: 'Infeed Settings'
  },
  freigabeMicrotec: {
    id: 'app.containers.MachineTitles.freigabeMicrotec',
    defaultMessage: 'Freigabe to Microtec Scanner - data received'
  },
  testLamellaRequest: {
    id: 'app.containers.MachineTitles.testLamellaRequest',
    defaultMessage: 'Test Lamella request'
  },
  testLamellaConditions: {
    id: 'app.containers.MachineTitles.testLamellaConditions',
    defaultMessage: 'Test lamella conditions'
  },
  resendXcutData: {
    id: 'app.containers.MachineTitles.resendXcutData',
    defaultMessage: 'Resend Xcut Data'
  },
  testReaJetData: {
    id: 'app.containers.MachineTitles.testReaJetData',
    defaultMessage: 'Test ReaJet Data'
  },
  resetOperatorWaste: {
    id: 'app.containers.MachineTitles.resetOperatorWaste',
    defaultMessage: 'Reset operator waste'
  },
  clearScannedPackage: {
    id: 'app.containers.MachineTitles.clearScannedPackage',
    defaultMessage: 'Clear scanned package'
  },
  paketDataNachPLC: {
    id: 'app.containers.MachineTitles.paketDataNachPLC',
    defaultMessage: 'Paket data nach PLC'
  },
  resendProducedPaketInfodata: {
    id: 'app.containers.MachineTitles.resendProducedPaketInfodata',
    defaultMessage: 'Resend Produced Paket Infodata'
  },
  resendProducedPaketPrinter: {
    id: 'app.containers.MachineTitles.resendProducedPaketPrinter',
    defaultMessage: 'Resend Produced Paket Printer'
  },
  testSticker: {
    id: 'app.containers.MachineTitles.resendProducedPaketPrinter',
    defaultMessage: 'Test Sticker'
  },
  beforeStorageTitle: {
    id: 'app.containers.MachineTitles.beforeStorageTitle',
    defaultMessage: 'Before Storage'
  },
  missingID: {
    id: 'app.containers.MachineTitles.missingID',
    defaultMessage: 'No ID'
  },
  missingSmartyInfo: {
    id: 'app.containers.MachineTitles.missingSmartyInfo',
    defaultMessage: 'Missing Information from Smarty'
  },
  CompleteInfoAboutLamella: {
    id: 'app.containers.MachineTitles.CompleteInfoAboutLamella',
    defaultMessage: 'Complete Information'
  },
  InfeedMachinesTitle: {
    id: 'app.containers.MachineTitles.InfeedMachinesTitle',
    defaultMessage: 'Infeed'
  },
  OutfeedMachinesTitle: {
    id: 'app.containers.MachineTitles.InfeedMachinesTitle',
    defaultMessage: 'Outfeed'
  },
  lamellaStorage: {
    id: 'app.containers.MachineTitles.lamellaStorage',
    defaultMessage: 'Lamella Storage'
  },
  infeedLoadersTitle: {
    id: 'app.containers.MachineTitles.infeedLoadersTitle',
    defaultMessage: 'Infeed Loaders'
  },
  zincPlcTitle: {
    id: 'app.containers.MachineTitles.zincPlcTitle',
    defaultMessage: 'Zinc PLC Signals'
  },
  zincSettingsTitle: {
    id: 'app.containers.MachineTitles.zincSettingsTitle',
    defaultMessage: 'Zinc Settings'
  },
  dryingPlcTitle: {
    id: 'app.containers.MachineTitles.dryingPlcTitle',
    defaultMessage: 'Drying PLC Signals'
  },
  lagerlongPlcTitle: {
    id: 'app.containers.MachineTitles.lagerlongPlcTitle',
    defaultMessage: 'Lager Long PLC Signals'
  },
  lagershortPlcTitle: {
    id: 'app.containers.MachineTitles.lagershortPlcTitle',
    defaultMessage: 'Lager Short PLC Signals'
  },
  lagershortTitle: {
    id: 'app.containers.MachineTitles.lagershortTitle',
    defaultMessage: 'Lager Short Status'
  },
  lagerlongTitle: {
    id: 'app.containers.MachineTitles.lagerlongTitle',
    defaultMessage: 'Lager Long Status'
  },
  dryingSettingsTitle: {
    id: 'app.containers.MachineTitles.dryingSettingsTitle',
    defaultMessage: 'Drying Settings'
  },
  pressPlcTitle: {
    id: 'app.containers.MachineTitles.pressPlcTitle',
    defaultMessage: 'Press PLC Signals'
  },
  sandingTitle: {
    id: 'app.containers.MachineTitle.sandingTitle',
    defaultMessage: 'Sanding'
  },
  pressStatusTitle: {
    id: 'app.containers.MachineTitles.pressStatusTitle',
    defaultMessage: 'Press Status'
  },
  lam_elevatorTitle: {
    id: 'app.containers.MachineTitles.lam_elevatorTitle',
    defaultMessage: 'Lamella on lift'
  },
  floor1_sector1Title: {
    id: 'app.containers.MachineTitles.floor1_sector1Title',
    defaultMessage: 'Storage first compartment'
  },
  floor1_sector2Title: {
    id: 'app.containers.MachineTitles.floor1_sector2Title',
    defaultMessage: 'Storage second compartment'
  },
  firstCompartmentTitle: {
    id: 'app.containers.MachineTitles.firstCompartmentTitle',
    defaultMessage: 'Storage first compartment'
  },
  secondCompartmentTitle: {
    id: 'app.containers.MachineTitles.secondCompartmentTitle',
    defaultMessage: 'Storage second compartment'
  },
  thirdCompartmentTitle: {
    id: 'app.containers.MachineTitles.thirdCompartmentTitle',
    defaultMessage: 'Storage third compartment'
  },
  fourthCompartmentTitle: {
    id: 'app.containers.MachineTitles.fourthCompartmentTitle',
    defaultMessage: 'Storage fourth compartment'
  },
  fifthCompartmentTitle: {
    id: 'app.containers.MachineTitles.fifthCompartmentTitle',
    defaultMessage: 'Storage fifth compartment'
  },
  lam_for_epTitle: {
    id: 'app.containers.MachineTitles.lam_for_epTitle',
    defaultMessage: 'Lamella before Europlan'
  },
  lam_after_epTitle: {
    id: 'app.containers.MachineTitles.lam_after_epTitle',
    defaultMessage: 'Lamella after Europlan'
  },
  stacker_liftTitle: {
    id: 'app.containers.MachineTitles.stacker_liftTitle',
    defaultMessage: 'Lift stacker'
  },
  stackerTitle: {
    id: 'app.containers.MachineTitles.stackerTitle',
    defaultMessage: 'Stacker'
  },
  layerAfterEp: {
    id: 'app.containers.MachineTitles.layerAfterEp',
    defaultMessage: 'Layer after Europlan'
  },
  layerAfterEp1: {
    id: 'app.containers.MachineTitles.layerAfterEp1',
    defaultMessage: 'Layer 1 after Europlan'
  },
  layerAfterEp2: {
    id: 'app.containers.MachineTitles.layerAfterEp2',
    defaultMessage: 'Layer 2 after Europlan'
  },
  packageRollConveyor: {
    id: 'app.containers.MachineTitles.packageRollConveyor',
    defaultMessage: 'Package roll conveyor'
  },
  packageRollConveyor1: {
    id: 'app.containers.MachineTitles.packageRollConveyor1',
    defaultMessage: 'Package roll conveyor 1'
  },
  packageRollConveyor2: {
    id: 'app.containers.MachineTitles.packageRollConveyor2',
    defaultMessage: 'Package roll conveyor 2'
  },
  wrapper_forksTitle: {
    id: 'app.containers.MachineTitles.wrapper_forksTitle',
    defaultMessage: 'Wrapper forks'
  },
  wrapperTitle: {
    id: 'app.containers.MachineTitles.wrapperTitle',
    defaultMessage: 'Wrapper'
  },
  wrapped_packageTitle: {
    id: 'app.containers.MachineTitles.wrapped_packageTitle',
    defaultMessage: 'Before Wrapping'
  },
  aufsnit: {
    id: 'app.components.TabelaricModal.aufsnit',
    defaultMessage: 'Long lamella length margin'
  },
  selectPackageTitle: {
    id: 'app.components.RawInfeedLoadingBox.selectPackageTitle',
    defaultMessage: 'Assign package'
  },
  assignProduct: {
    id: 'app.components.RawInfeedLoadingBox.assignProduct',
    defaultMessage: 'Assign product'
  },
  woodSupplier: {
    id: 'app.components.RawInfeedLoadingBox.woodSupplier',
    defaultMessage: 'Wood Supplier'
  },
  assignedProductStacker: {
    id: 'app.components.RawInfeedLoadingBox.assignedProductStacker',
    defaultMessage: 'Assigned Product'
  },
  product: {
    id: 'app.components.RawInfeedLoadingBox.product',
    defaultMessage: 'Product'
  },
  assignSupplier: {
    id: 'app.components.RawInfeedLoadingBox.assignSupplier',
    defaultMessage: 'Assign supplier'
  },
  inputPackage: {
    id: 'app.components.RawInfeed.inputPackage',
    defaultMessage: 'Input Package'
  },
  layerInput: {
    id: 'app.components.RawInfeed.layerInput',
    defaultMessage: 'Layer Input'
  },
  layerExit: {
    id: 'app.components.RawInfeed.layerExit',
    defaultMessage: 'Layer Exit'
  },
  cross: {
    id: 'app.components.RawInfeed.cross',
    defaultMessage: 'zig zag'
  },
  sticks: {
    id: 'app.components.RawInfeed.sticks',
    defaultMessage: 'sticks'
  },
  middle: {
    id: 'app.components.RawInfeed.middle',
    defaultMessage: 'middle'
  },
  straps: {
    id: 'app.components.RawInfeed.straps',
    defaultMessage: 'straps'
  },
  fj: {
    id: 'app.components.RawInfeed.fj',
    defaultMessage: 'FJ'
  },
  warehouse: {
    id: 'app.components.RawInfeed.warehouse',
    defaultMessage: 'Warehouse'
  },
  pressStackerTitle: {
    id: 'app.containers.MachineTitles.pressStackerTitle',
    defaultMessage: 'Press Stacker'
  },
  stackerSettingsTitle: {
    id: 'app.containers.MachineTitles.stackerSettingsTitle',
    defaultMessage: 'Stacker Settings'
  },
  lastPrintedLabel: {
    id: 'app.containers.MachineTitles.lastPrintedLabel',
    defaultMessage: 'Last printed label'
  },
  loading: {
    id: 'app.containers.MachineTitles.loading',
    defaultMessage: 'Loading'
  },
  stackerPlcTitle: {
    id: 'app.containers.MachineTitles.stackerPlcTitle',
    defaultMessage: 'Stacker PLC Signals'
  },
  orderDetails: {
    id: 'app.containers.MachineTitles.orderDetails',
    defaultMessage: 'Order details'
  },
  panelCorrectionMode: {
    id: 'app.containers.MachineTitles.panelCorrectionMode',
    defaultMessage: 'Panel correction'
  },
  craneOperation: {
    id: 'app.containers.MachineTitles.craneOperation',
    defaultMessage: 'Crane operation'
  },
  vacuumOperation: {
    id: 'app.containers.MachineTitles.vacuumOperation',
    defaultMessage: 'Vacuum operation'
  },
  allowDrag: {
    id: 'app.containers.MachineTitles.allowDrag',
    defaultMessage: 'Allow Drag'
  },
  update: {
    id: 'app.containers.MachineTitles.updated',
    defaultMessage: 'Update'
  },
  reset: {
    id: 'app.containers.MachineTitles.reset',
    defaultMessage: 'Reset'
  },
  cutlist: {
    id: 'app.containers.MachineTitles.cutlist',
    defaultMessage: 'Cutlist'
  },
  fromLocation: {
    id: 'app.containers.MachineTitles.fromLocation',
    defaultMessage: 'From location'
  },
  toLocation: {
    id: 'app.containers.MachineTitles.toLocation',
    defaultMessage: 'To location'
  },
  sendToXLAM: {
    id: 'app.containers.MachineTitles.sendToXLAM',
    defaultMessage: 'Send to XLAM'
  },
  requestStarted: {
    id: 'app.containers.MachineTitles.requestStarted',
    defaultMessage: 'Request started'
  },
  sourceConfirmed: {
    id: 'app.containers.MachineTitles.sourceConfirmed',
    defaultMessage: 'Source confirmed'
  },
  destinationConfirmed: {
    id: 'app.containers.MachineTitles.destinationConfirmed',
    defaultMessage: 'Destination confirmed'
  },
  currentCraneOrder: {
    id: 'app.containers.MachineTitles.currentCraneOrder',
    defaultMessage: 'Current crane order'
  },
  sendToCNC1: {
    id: 'app.containers.MachineTitles.sendToCNC1',
    defaultMessage: 'Send to CNC 1'
  },
  sendToCNC2: {
    id: 'app.containers.MachineTitles.sendToCNC2',
    defaultMessage: 'Send to CNC 2'
  },
  vhod1title: {
    id: 'app.containers.InfeedRaw.vhod1title',
    defaultMessage: 'Infeed'
  },
  xcut1title: {
    id: 'app.containers.xcut1.xcut1title',
    defaultMessage: 'XCut1'
  },
  moisture: {
    id: 'app.containers.InfeedRaw.moisture',
    defaultMessage: 'Moisture meter settings'
  },
  supplier: {
    id: 'app.components.modals.PackageLoading.supplier',
    defaultMessage: 'SUPPLIER'
  },
  numOfBoards: {
    id: 'app.components.modals.PackageLoading.numOfBoards',
    defaultMessage: 'NO. BOARDS'
  },
  species: {
    id: 'app.components.modals.PackageLoading.species',
    defaultMessage: 'SPECIES'
  },
  set: {
    id: 'app.components.modals.PackageLoading.set',
    defaultMessage: 'SET'
  },
  numRows: {
    id: 'app.components.modals.PackageLoading.numRows',
    defaultMessage: 'NO. ROWS'
  },
  numColumns: {
    id: 'app.components.modals.PackageLoading.numColumns',
    defaultMessage: 'NO. COLS'
  },
  setModalTitle: {
    id: 'app.components.modals.PackageLoading.setModalTitle',
    defaultMessage: 'package'
  },
  areaA11Simple: {
    id: 'app.components.modals.PackageLoading.areaA11Simple',
    defaultMessage: 'Area A11'
  },
  areaA12Simple: {
    id: 'app.components.modals.PackageLoading.areaA12Simple',
    defaultMessage: 'Area A12'
  },
  areaA11: {
    id: 'app.components.modals.PackageLoading.areaA11',
    defaultMessage: 'Area A11 (package loading)'
  },
  areaA12: {
    id: 'app.components.modals.PackageLoading.areaA12',
    defaultMessage: 'Area A12 (vacuum)'
  },
  areaA13: {
    id: 'app.components.modals.PackageLoading.areaA13',
    defaultMessage: 'Area A13 (dog chain)'
  },
  automaticA11: {
    id: 'app.components.modals.PackageLoading.automaticA11',
    defaultMessage: 'Automatic A11 (package loading)'
  },
  automaticA12: {
    id: 'app.components.modals.PackageLoading.automaticA12',
    defaultMessage: 'Automatic A12 (vacuum)'
  },
  automaticA13: {
    id: 'app.components.modals.PackageLoading.automaticA13',
    defaultMessage: 'Automatic A13 (dog chain)'
  },
  ml: {
    id: 'app.components.modals.PackageLoading.ml',
    defaultMessage: 'Manual loading'
  },
  xcutempty: {
    id: 'app.components.modals.PackageLoading.xcutempty',
    defaultMessage: 'XCut empty'
  },
  clearanceHolder1: {
    id: 'app.components.modals.PackageLoading.clearanceHolder1',
    defaultMessage: 'Clearance holder 1'
  },
  clearanceVacuum: {
    id: 'app.components.modals.PackageLoading.clearanceVacuum',
    defaultMessage: 'Vacuum clearance'
  },
  A1Clearance: {
    id: 'app.components.modals.PackageLoading.A1Clearance',
    defaultMessage: 'A1 clearance to load'
  },
  B1Clearance: {
    id: 'app.components.modals.PackageLoading.B1Clearance',
    defaultMessage: 'B1 clearance to load'
  },
  C1Clearance: {
    id: 'app.components.modals.PackageLoading.C1Clearance',
    defaultMessage: 'C1 clearance to load'
  },
  D1Clearance: {
    id: 'app.components.modals.PackageLoading.D1Clearance',
    defaultMessage: 'D1 clearance to load'
  },
  newDimension: {
    id: 'app.components.modals.PackageLoading.newDimension',
    defaultMessage: 'New dimension'
  },
  holder1: {
    id: 'app.components.modals.PackageLoading.holder1',
    defaultMessage: 'Holder 1'
  },
  empty: {
    id: 'app.components.modals.PackageLoading.empty',
    defaultMessage: 'Empty'
  },
  notEmpty: {
    id: 'app.components.modals.PackageLoading.notEmpty',
    defaultMessage: 'Not empty'
  },
  boards: {
    id: 'app.components.modals.PackageLoading.boards',
    defaultMessage: 'boards'
  },
  moistureMeterParameters: {
    id: 'app.components.modals.PackageLoading.moistureMeterParameters',
    defaultMessage: 'Moisture meter parameters'
  },
  numberOfBoardsBeforeHolder1: {
    id: 'app.components.modals.PackageLoading.numberOfBoardsBeforeHolder1',
    defaultMessage: 'Number of boards before holder 1'
  },
  boardsBeforeOptiscan: {
    id: 'app.components.modals.PackageLoading.boardsBeforeOptiscan',
    defaultMessage: 'LAMELLAS BEFORE OPTISCAN'
  },
  dogChainBoards: {
    id: 'app.components.modals.PackageLoading.dogChainBoards',
    defaultMessage: 'MITNEMER LAMELLAS'
  },
  addScannedPackage: {
    id: 'app.components.modals.PackageLoading.addScannedPackage',
    defaultMessage: 'ADD SCANNED PACKAGE'
  },
  XCutEvents: {
    id: 'app.components.modals.PackageLoading.XCutEvents',
    defaultMessage: 'XCUT EVENTS'
  },
  turnOffManualLoading: {
    id: 'app.components.modals.PackageLoading.turnOffManualLoading',
    defaultMessage: 'TURN OFF MANUAL LOADING'
  },
  clearBeforeOptifeed: {
    id: 'app.components.modals.PackageLoading.clearBeforeOptifeed',
    defaultMessage: 'CLEAR BEFORE OPTIFEED'
  },
  resendMicrotecData: {
    id: 'app.components.modals.PackageLoading.resendMicrotecData',
    defaultMessage: 'Resend batch/package data to Microtec'
  },
  currentNeededForLayer: {
    id: 'app.components.modals.PackageLoading.currentNeededForLayer',
    defaultMessage: 'Current needed for layer'
  },
  currentAfterGoldenEyeToXCut: {
    id: 'app.components.modals.PackageLoading.currentAfterGoldenEyeToXCut',
    defaultMessage: 'Current wood after goldeneye to xcut'
  },
  totalNeededAfterGoldenEye: {
    id: 'app.components.modals.PackageLoading.totalNeededAfterGoldenEye',
    defaultMessage: 'Total needed after golden eye'
  },
  currentBatchId: {
    id: 'app.components.modals.PackageLoading.currentBatchId',
    defaultMessage: 'Current batch id'
  },
  scannedPackagesQueue: {
    id: 'app.components.modals.PackageLoading.scannedPackagesQueue',
    defaultMessage: 'Scanned packages queue'
  },
  moveScannedToLoaded: {
    id: 'app.components.modals.PackageLoading.moveScannedToLoaded',
    defaultMessage: 'Scanned to loaded'
  },
  cutWasteBoardsOnXCut: {
    id: 'app.components.modals.PackageLoading.cutWasteBoardsOnXCut',
    defaultMessage: 'Cut waste boards on xcut'
  },
  groupid: {
    id: 'app.containers.Zinc.groupid',
    defaultMessage: 'Group ID '
  },
  orderid: {
    id: 'app.containers.Zinc.orderid',
    defaultMessage: 'Order ID '
  },
  woodonline: {
    id: 'app.containers.Zinc.woodonline',
    defaultMessage: 'Current wood on line'
  },
  woodonlineForDim: {
    id: 'app.containers.Zinc.woodonlineForDim',
    defaultMessage: 'Current wood on line for dim.'
  },
  neededmaterial: {
    id: 'app.containers.Zinc.neededmaterial',
    defaultMessage: 'Current needed material'
  },
  vacuumstatus: {
    id: 'app.containers.Zinc.vacuumstatus',
    defaultMessage: 'Vacuum status'
  },
  mode: {
    id: 'app.containers.Zinc.mode',
    defaultMessage: 'Mode'
  },
  lamellasproduced: {
    id: 'app.containers.Zinc.lamellasproduced',
    defaultMessage: 'Lamellas produced'
  },
  lamellarequest: {
    id: 'app.containers.Zinc.lamellarequest',
    defaultMessage: 'Test lamella request'
  },
  forlayers: {
    id: 'app.containers.Zinc.forlayers',
    defaultMessage: 'm for layers'
  },
  totalDimNum: {
    id: 'app.containers.Zinc.totalDimNum',
    defaultMessage: 'Total for dim.'
  },
  mainChainEmpty: {
    id: 'app.containers.Zinc.mainChainEmpty',
    defaultMessage: 'Main chain empty'
  },
  lineEmptyUntilZink: {
    id: 'app.containers.Zinc.lineEmptyUntilZink',
    defaultMessage: 'Infeed empty'
  },
  jointEmpty: {
    id: 'app.containers.Zinc.jointEmpty',
    defaultMessage: 'Joint empty'
  },
  powerFeedEmpty: {
    id: 'app.containers.Zinc.powerFeedEmpty',
    defaultMessage: 'Powerfeed empty'
  },
  exitStatusTitle: {
    id: 'app.containers.MachineTitles.exitStatusTitle',
    defaultMessage: 'Exit Status'
  },
  recalc: {
    id: 'app.containers.OrdersQueue.recalc',
    defaultMessage: 'Recalculate groups'
  },
  combineidValidation: {
    id: 'app.containers.OrdersQueue.combineidValidation',
    defaultMessage: 'Combined ID validation'
  },
  fiber: {
    id: 'app.components.SideMenu.fiber',
    defaultMessage: '3D model'
  },
  exit: {
    id: 'app.components.SideMenu.exit',
    defaultMessage: 'Exit'
  },
  orders: {
    id: 'app.components.SideMenu.orders',
    defaultMessage: 'Orders'
  },
  beams: {
    id: 'app.components.SideMenu.beams',
    defaultMessage: 'Beams'
  },
  beam: {
    id: 'app.components.SideMenu.beam',
    defaultMessage: 'Beam'
  },
  bsh: {
    id: 'app.components.SideMenu.bsh',
    defaultMessage: 'BSH Orders'
  },
  kvh: {
    id: 'app.components.SideMenu.kvh',
    defaultMessage: 'KVH Orders'
  },
  fileUploaded: {
    id: 'app.components.SideMenu.fileUploaded',
    defaultMessage: 'Upload'
  },
  minda: {
    id: 'app.components.SideMenu.minda',
    defaultMessage: 'Minda'
  },
  ordersUploaded: {
    id: 'app.components.SideMenu.fileUploaded',
    defaultMessage: 'Orders upload'
  },
  excelStatistics: {
    id: 'app.components.SideMenu.excelStatistics',
    defaultMessage: 'Excel Statistics'
  },
  configFiles: {
    id: 'app.components.SideMenu.configFiles',
    defaultMessage: 'Configuration files'
  },
  todo: {
    id: 'app.components.SideMenu.todo',
    defaultMessage: 'Todo'
  },
  ready: {
    id: 'app.components.SideMenu.ready',
    defaultMessage: 'Ready'
  },
  optimized: {
    id: 'app.components.SideMenu.optimized',
    defaultMessage: 'Optimized'
  },
  done: {
    id: 'app.components.SideMenu.done',
    defaultMessage: 'Done'
  },
  producing: {
    id: 'app.components.SideMenu.producing',
    defaultMessage: 'producing'
  },
  deleted: {
    id: 'app.components.SideMenu.deleted',
    defaultMessage: 'Deleted'
  },
  packages: {
    id: 'app.components.SideMenu.packages',
    defaultMessage: 'Packages'
  },
  incoming: {
    id: 'app.components.SideMenu.incoming',
    defaultMessage: 'Incoming'
  },
  sorted: {
    id: 'app.components.SideMenu.sorted',
    defaultMessage: 'Sorted'
  },
  created: {
    id: 'app.components.SideMenu.created',
    defaultMessage: 'Created'
  },
  qualitySorting: {
    id: 'app.components.SideMenu.qualitySorting',
    defaultMessage: 'Q. Sorting'
  },
  recipes: {
    id: 'app.components.SideMenu.recipes',
    defaultMessage: 'Recipes'
  },
  recipeLayer: {
    id: 'app.components.SideMenu.recipeLayer',
    defaultMessage: 'Layer Recipe'
  },
  materialsTable: {
    id: 'app.components.SideMenu.materialsTable',
    defaultMessage: 'Materials Table'
  },
  eventlog: {
    id: 'app.components.SideMenu.eventlog',
    defaultMessage: 'Eventlog'
  },
  fixedRecipeLayers: {
    id: 'app.components.SideMenu.fixedRecipeLayers',
    defaultMessage: 'Fixed Recipe Layers'
  },
  hollow: {
    id: 'app.components.SideMenu.hollow',
    defaultMessage: 'Hollow'
  },
  erp: {
    id: 'app.components.SideMenu.erp',
    defaultMessage: 'Erp'
  },
  refinements: {
    id: 'app.components.SideMenu.refinements',
    defaultMessage: 'Refinements'
  },
  extralamellas: {
    id: 'app.components.SideMenu.extralamellas',
    defaultMessage: 'Extra lamellas'
  },
  productionline: {
    id: 'app.components.SideMenu.productionline',
    defaultMessage: 'Production line'
  },
  productionstatus: {
    id: 'app.components.SideMenu.productionstatus',
    defaultMessage: 'Status'
  },
  productlist: {
    id: 'app.components.SideMenu.productlist',
    defaultMessage: 'Product List'
  },
  materialNeededTitle: {
    id: 'app.components.SideMenu.materialNeededTitle',
    defaultMessage: 'Material needed'
  },
  accumulatorEtages: {
    id: 'app.components.SideMenu.accumulatorEtages',
    defaultMessage: 'Accumulator Etages'
  },
  lamellasOnTheLine: {
    id: 'app.components.SideMenu.lamellasOnTheLine',
    defaultMessage: 'Lamellas On The Line'
  },
  packagesQueueTitle: {
    id: 'app.components.SideMenu.packagesQueueTitle',
    defaultMessage: 'Packages Queue'
  },
  errorListTitle: {
    id: 'app.components.SideMenu.errorListTitle',
    defaultMessage: 'Error List'
  },
  problemSolutionTitle: {
    id: 'app.components.SideMenu.problemSolutionTitle',
    defaultMessage: 'Get the solution to your problem'
  },
  sortingQueueTitle: {
    id: 'app.components.SideMenu.sortingQueueTitle',
    defaultMessage: 'Sorting Queue'
  },
  maschines: {
    id: 'app.components.SideMenu.maschines',
    defaultMessage: 'Machines'
  },
  lagerlong: {
    id: 'app.components.SideMenu.lagerlong',
    defaultMessage: 'Lager Long'
  },
  lagershort: {
    id: 'app.components.SideMenu.lagershort',
    defaultMessage: 'Lager Short'
  },
  drying: {
    id: 'app.components.SideMenu.drying',
    defaultMessage: 'Drying'
  },
  dryingMech: {
    id: 'app.components.SideMenu.dryingMech',
    defaultMessage: 'Drying Mech.'
  },
  longlamellas: {
    id: 'app.components.SideMenu.longlamellas',
    defaultMessage: 'Lamellas ZPressLong'
  },
  shortlamellas: {
    id: 'app.components.SideMenu.shortlamellas',
    defaultMessage: 'Lamellas ZPressShort'
  },
  zpress: {
    id: 'app.components.SideMenu.zpress',
    defaultMessage: 'ZPress'
  },
  schneiderDrying: {
    id: 'app.components.SideMenu.schneiderDrying',
    defaultMessage: 'Drying'
  },
  zincs: {
    id: 'app.components.SideMenu.zincs',
    defaultMessage: 'Zincs'
  },
  zinc1: {
    id: 'app.components.SideMenu.zinc1',
    defaultMessage: 'Zinc 1'
  },
  zinc2: {
    id: 'app.components.SideMenu.zinc2',
    defaultMessage: 'Zinc 2'
  },
  zinc3: {
    id: 'app.components.SideMenu.zinc3',
    defaultMessage: 'Zinc 3'
  },
  zinc: {
    id: 'app.components.SideMenu.zinc',
    defaultMessage: 'Zinc'
  },
  bodenlager: {
    id: 'app.components.SideMenu.bodenlager',
    defaultMessage: 'Stacking storage'
  },
  goldeneye: {
    id: 'app.components.SideMenu.goldeneye',
    defaultMessage: 'Goldeneye'
  },
  m3Connected: {
    id: 'app.components.SideMenu.m3Connected',
    defaultMessage: 'M3 Connected'
  },
  stacker: {
    id: 'app.components.SideMenu.stacker',
    defaultMessage: 'Stacker'
  },
  stack: {
    id: 'app.components.SideMenu.stack',
    defaultMessage: 'Stack'
  },
  presstacker: {
    id: 'app.components.SideMenu.presstacker',
    defaultMessage: 'Press Stacker'
  },
  packageinfeed: {
    id: 'app.components.SideMenu.packageinfeed',
    defaultMessage: 'Sorting Packages'
  },
  loadedPackages: {
    id: 'app.components.SideMenu.loadedPackages',
    defaultMessage: 'Loaded Packages'
  },
  scannedPackagesbtn: {
    id: 'app.containers.Header.scannedPackagesbtn',
    defaultMessage: 'Scanned Packages'
  },
  storage: {
    id: 'app.components.SideMenu.storage',
    defaultMessage: 'Storage'
  },
  beforeStorage: {
    id: 'app.components.SideMenu.beforeStorage',
    defaultMessage: 'Before Storage'
  },
  smarty: {
    id: 'app.components.SideMenu.smarty',
    defaultMessage: 'Smarty'
  },
  insulation: {
    id: 'app.components.SideMenu.insulation',
    defaultMessage: 'Insulation'
  },
  infeeds: {
    id: 'app.components.SideMenu.infeeds',
    defaultMessage: 'Infeeds'
  },
  infeed1: {
    id: 'app.components.SideMenu.infeed1',
    defaultMessage: 'Infeed 1'
  },
  infeed2: {
    id: 'app.components.SideMenu.infeed2',
    defaultMessage: 'Infeed 2'
  },
  extralamellastorage: {
    id: 'app.components.SideMenu.extralamellastorage',
    defaultMessage: 'Xtra Lam Storage'
  },
  lift: {
    id: 'app.components.SideMenu.lift',
    defaultMessage: 'Lift'
  },
  products: {
    id: 'app.components.SideMenu.products',
    defaultMessage: 'Products'
  },
  suppliers: {
    id: 'app.components.SideMenu.suppliers',
    defaultMessage: 'Suppliers'
  },
  afterZPressStacks: {
    id: 'app.components.SideMenu.afterZPressStacks',
    defaultMessage: 'After ZPress stacks'
  },
  platesStorage: {
    id: 'app.components.SideMenu.platesStorage',
    defaultMessage: 'Plates Storage'
  },
  indicatorBackend: {
    id: 'app.components.SideMenu.indicatorBackend',
    defaultMessage: 'Backend'
  },
  indicatorControl: {
    id: 'app.components.SideMenu.indicatorControl',
    defaultMessage: 'Control'
  },
  indicatorZebra: {
    id: 'app.components.SideMenu.indicatorZebra',
    defaultMessage: 'Zebra'
  },
  indicatorPlc0: {
    id: 'app.components.SideMenu.indicatorPlc0',
    defaultMessage: 'Infeed PLC'
  },
  indicatorPlc1: {
    id: 'app.components.SideMenu.indicatorPlc1',
    defaultMessage: 'Zinc PLC'
  },
  indicatorPlc2: {
    id: 'app.components.SideMenu.indicatorPlc2',
    defaultMessage: 'Multiplan PLC'
  },
  indicatorPlc3: {
    id: 'app.components.SideMenu.indicatorPlc3',
    defaultMessage: 'XCut PLC'
  },
  indicatorPlc4: {
    id: 'app.components.SideMenu.indicatorPlc4',
    defaultMessage: 'Press PLC'
  },
  indicatorPlc5: {
    id: 'app.components.SideMenu.indicatorPlc5',
    defaultMessage: 'Europlan/EXIT PLC'
  },
  eingangPCData: {
    id: 'app.components.SideMenu.eingangPCData',
    defaultMessage: 'Infeed PC data'
  },
  epMitPCData: {
    id: 'app.components.SideMenu.epMitPCData',
    defaultMessage: 'Planer PC data'
  },
  zincPCData: {
    id: 'app.components.SideMenu.zincPCData',
    defaultMessage: 'Zinc PC data'
  },
  infeed: {
    id: 'app.components.SideMenu.infeed',
    defaultMessage: 'Infeed'
  },
  cltinfeed: {
    id: 'app.components.SideMenu.cltinfeed',
    defaultMessage: 'CLT Infeed'
  },
  maxipress: {
    id: 'app.components.SideMenu.maxipress',
    defaultMessage: 'Maxipress'
  },
  multiplanPCData: {
    id: 'app.components.SideMenu.multiplanPCData',
    defaultMessage: 'MPlan PC data'
  },
  xcutPCData: {
    id: 'app.components.SideMenu.xcutPCData',
    defaultMessage: 'XCut PC data'
  },
  xcutsPCData: {
    id: 'app.components.SideMenu.xcutsPCData',
    defaultMessage: 'XCuts'
  },
  xcut1PCData: {
    id: 'app.components.SideMenu.xcutsPCData',
    defaultMessage: 'XCut 1 PC data'
  },
  xcut2PCData: {
    id: 'app.components.SideMenu.xcutsPCData',
    defaultMessage: 'XCut 2 PC data'
  },
  xcut3PCData: {
    id: 'app.components.SideMenu.xcutsPCData',
    defaultMessage: 'XCut 3 PC data'
  },
  pressPCData: {
    id: 'app.components.SideMenu.pressPCData',
    defaultMessage: 'Press PC data'
  },
  vnc: {
    id: 'app.components.SideMenu.vnc',
    defaultMessage: 'PLC panel'
  },
  statistics: {
    id: 'app.components.SideMenu.statistics',
    defaultMessage: 'Statistics'
  },
  brookhuis: {
    id: 'app.components.SideMenu.brookhuis',
    defaultMessage: 'Brookhuis'
  },
  gluebook: {
    id: 'app.components.SideMenu.gluebook',
    defaultMessage: 'Gluebook'
  },
  projects: {
    id: 'app.components.SideMenu.projects',
    defaultMessage: 'Projects'
  },
  gluebook: {
    id: 'app.components.SideMenu.glueBook',
    defaultMessage: 'Gluebook'
  },
  videos: {
    id: 'app.components.SideMenu.videos',
    defaultMessage: 'Video Player'
  },
  videotutorials: {
    id: 'app.components.SideMenu.videotutorials',
    defaultMessage: 'Video Tutorials'
  },
  dailyStats: {
    id: 'app.components.SideMenu.dailyStats',
    defaultMessage: 'Daily Stats'
  },
  moisturestats: {
    id: 'app.components.SideMenu.moisturestats',
    defaultMessage: 'Moisture stats'
  },
  permissionsview: {
    id: 'app.components.SideMenu.permissionsview',
    defaultMessage: 'Permissions'
  },
  moveToTodo: {
    id: 'app.containers.Tableview.moveToTodo',
    defaultMessage: 'Move to TODO'
  },
  optimize: {
    id: 'app.containers.Tableview.optimize',
    defaultMessage: 'Optimize'
  },
  removeAllOptimized: {
    id: 'app.containers.Tableview.removeAllOptimized',
    defaultMessage: 'Remove All Optimized'
  },
  samePackageIdTodo: {
    id: 'app.containers.Tableview.samePackageIdTodo',
    defaultMessage: 'Same Package TODO'
  },
  produceSamePackageId: {
    id: 'app.containers.Tableview.produceSamePackageId',
    defaultMessage: 'Produce Same Package'
  },
  moveToProduction: {
    id: 'app.containers.Tableview.moveToProduction',
    defaultMessage: 'Move to Production'
  },
  createCLTPackages: {
    id: 'app.containers.Tableview.createCLTPackages',
    defaultMessage: 'Create CLT Packages'
  },
  recalculate: {
    id: 'app.containers.Tableview.recalculate',
    defaultMessage: 'Recalculate'
  },
  resetCLTPackages: {
    id: 'app.containers.Tableview.resetCLTPackages',
    defaultMessage: 'Reset CLT Packages'
  },
  moveToDone: {
    id: 'app.containers.Tableview.moveToDone',
    defaultMessage: 'Move to Done'
  },
  restartSimulation: {
    id: 'app.containers.Tableview.restartSimulation',
    defaultMessage: 'Restart Simulation'
  },
  moveToDoneSchneider: {
    id: 'app.containers.Tableview.moveToDoneSchneider',
    defaultMessage: 'Move to Done'
  },
  moveToReadySchneider: {
    id: 'app.containers.Tableview.moveToReadySchneider',
    defaultMessage: 'Move to Ready'
  },
  fileUpload: {
    id: 'app.containers.Tableview.fileUpload',
    defaultMessage: 'file upload'
  },
  moveToProductionNew: {
    id: 'app.containers.Tableview.moveToProductionNew',
    defaultMessage: 'Move Order to Production test'
  },
  deleteOrderFromQueue: {
    id: 'app.containers.Tableview.deleteOrderFromQueue',
    defaultMessage: 'Remove Order from Production'
  },
  deleteOrder: {
    id: 'app.containers.Tableview.deleteOrder',
    defaultMessage: 'Delete Order'
  },
  deleteLayer: {
    id: 'app.containers.Tableview.deleteLayer',
    defaultMessage: 'Delete Layer'
  },
  deleteRepair: {
    id: 'app.containers.Tableview.deleteRepair',
    defaultMessage: 'Delete Repair'
  },
  moveOrderToDone: {
    id: 'app.containers.Tableview.moveOrderToDone',
    defaultMessage: 'Move to Done'
  },
  moveOrderToDoneSchneider: {
    id: 'app.containers.Tableview.moveOrderToDoneSchneider',
    defaultMessage: 'Move to Done'
  },
  deleteProject: {
    id: 'app.containers.Tableview.deleteProject',
    defaultMessage: 'Delete Project'
  },
  addExtraLamelEntry: {
    id: 'app.containers.Tableview.addExtraLamelEntry',
    defaultMessage: 'Add Entry'
  },
  addRecipe: {
    id: 'app.containers.Tableview.addRecipe',
    defaultMessage: 'Add new Recipe'
  },
  addIncomingPackage: {
    id: 'app.containers.Tableview.addIncomingPackage',
    defaultMessage: 'Add new Package'
  },
  addRecipeLayer: {
    id: 'app.containers.Tableview.addRecipeLayer',
    defaultMessage: 'Add new Recipe Layer'
  },
  deleteRecipe: {
    id: 'app.containers.Tableview.deleteRecipe',
    defaultMessage: 'Delete Recipe'
  },
  deleteIncomingPackage: {
    id: 'app.containers.Tableview.deleteIncomingPackage',
    defaultMessage: 'Delete Incoming Package'
  },
  stopTask: {
    id: 'app.containers.Tableview.stopTask',
    defaultMessage: 'Stop Task'
  },
  deleteExtraLamella: {
    id: 'app.containers.Tableview.deleteExtraLamella',
    defaultMessage: 'Delete Extra Lamella'
  },
  activateProject: {
    id: 'app.containers.Tableview.activateProject',
    defaultMessage: 'Activate project'
  },
  addedAtAscending: {
    id: 'app.containers.Tableview.addedAtAscending',
    defaultMessage: 'Ascending addedAt'
  },
  addedAtDescending: {
    id: 'app.containers.Tableview.addedAtDescending',
    defaultMessage: 'Descending addedAt'
  },
  IdDescending: {
    id: 'app.containers.Tableview.IdDescending',
    defaultMessage: 'Ascending ID'
  },
  IdAscending: {
    id: 'app.containers.Tableview.IdAscending',
    defaultMessage: 'Descending ID'
  },
  ordering: {
    id: 'app.containers.Tableview.ordering',
    defaultMessage: 'Ordering'
  },
  exact: {
    id: 'app.containers.Tableview.exact',
    defaultMessage: 'Exact'
  },
  range: {
    id: 'app.containers.Tableview.range',
    defaultMessage: 'Range'
  },
  downloadItem: {
    id: 'app.containers.Tableview.downloadItem',
    defaultMessage: 'Download Item'
  },
  makeTodoCopy: {
    id: 'app.containers.Tableview.makeTodoCopy',
    defaultMessage: 'Duplicate to TODO'
  },
  approvedForPlaningTitle: {
    id: 'app.containers.Tableview.approvedForPlaningTitle',
    defaultMessage: 'Approve planing'
  },
  approvedForPlaningBody: {
    id: 'app.containers.Tableview.approvedForPlaningBody',
    defaultMessage: 'Approve order for planing?'
  },
  panelTooWide: {
    id: 'app.containers.ModelFieldErrors.panelTooWide',
    defaultMessage: 'Panel too wide'
  },
  panelTooNarrow: {
    id: 'app.containers.ModelFieldErrors.panelTooNarrow',
    defaultMessage: 'Panel too narrow'
  },
  panelTooLong: {
    id: 'app.containers.ModelFieldErrors.panelTooLong',
    defaultMessage: 'Panel too long'
  },
  panelTooShort: {
    id: 'app.containers.ModelFieldErrors.panelTooShort',
    defaultMessage: 'Panel too short'
  },
  rawMaterialTooWide: {
    id: 'app.containers.ModelFieldErrors.rawMaterialTooWide',
    defaultMessage: 'Raw material too wide'
  },
  insulationWidthError: {
    id: 'app.containers.ModelFieldErrors.insulationWidthError',
    defaultMessage: 'Insulation Width Error'
  },
  rawMaterialTooNarrow: {
    id: 'app.containers.ModelFieldErrors.rawMaterialTooNarrow',
    defaultMessage: 'Raw material too narrow'
  },
  planedMaterialTooWide: {
    id: 'app.containers.ModelFieldErrors.planedMaterialTooWide',
    defaultMessage: 'Planed material too wide'
  },
  planedMaterialTooNarrow: {
    id: 'app.containers.ModelFieldErrors.planedMaterialTooNarrow',
    defaultMessage: 'Planed material too narrow'
  },
  lamellaTooWide: {
    id: 'app.containers.ModelFieldErrors.lamellaTooWide',
    defaultMessage: 'Lamella too wide'
  },
  lamellaTooNarrow: {
    id: 'app.containers.ModelFieldErrors.lamellaTooNarrow',
    defaultMessage: 'Lamella too narrow'
  },
  lamellaTooThick: {
    id: 'app.containers.ModelFieldErrors.lamellaTooThick',
    defaultMessage: 'Lamella height too thick'
  },
  lamellaTooLow: {
    id: 'app.containers.ModelFieldErrors.lamellaTooLow',
    defaultMessage: 'Lamella height too low'
  },
  lamellaTooLong: {
    id: 'app.containers.ModelFieldErrors.lamellaTooLong',
    defaultMessage: 'Lamella too long'
  },
  lamellaTooShort: {
    id: 'app.containers.ModelFieldErrors.lamellaTooShort',
    defaultMessage: 'Lamella too short'
  },
  notBlank: {
    id: 'app.containers.ModelFieldErrors.notBlank',
    defaultMessage: 'cannot be blank'
  },
  mustNumber: {
    id: 'app.containers.ModelFieldErrors.mustNumber',
    defaultMessage: 'must be a number'
  },
  aboveZero: {
    id: 'app.containers.ModelFieldErrors.aboveZero',
    defaultMessage: 'must be above zero'
  },
  LELETEST: {
    id: 'app.containers.ModelFieldErrors.LELETEST',
    defaultMessage: 'LELE TEST'
  },
  addFilter: {
    id: 'app.components.TableBottomFilter.addFilter',
    defaultMessage: 'Add filter'
  },
  results: {
    id: 'app.components.TableBottomFilter.results',
    defaultMessage: 'Results'
  },
  manualModeSwitch: {
    id: 'app.components.MenuSwitch.manualModeSwitch',
    defaultMessage: 'Manual Mode Settings'
  },
  manualSwitch: {
    id: 'app.components.MenuSwitch.manualSwitch',
    defaultMessage: 'Manual on/off'
  },
  loadedPackagesbtn: {
    id: 'app.containers.Header.loadedPackagesbtn',
    defaultMessage: 'Loaded Packages'
  },
  stackedPackagesbtn: {
    id: 'app.containers.Header.stackedPackagesbtn',
    defaultMessage: 'Stacked Packages'
  },
  numStackLamellas: {
    id: 'app.containers.Header.numStackLamellas',
    defaultMessage: 'Stacked lamellas number'
  },
  materialNeededbtn: {
    id: 'app.containers.Header.materialNeededbtn',
    defaultMessage: 'Material Needed'
  },
  ordersqueuebtn: {
    id: 'app.containers.Header.ordersqueuebtn',
    defaultMessage: 'Orders Queue'
  },
  errorlistbtn: {
    id: 'app.containers.Header.errorlistbtn',
    defaultMessage: 'Error List'
  },
  fjordersqueuebtn: {
    id: 'app.containers.Header.fjordersqueuebtn',
    defaultMessage: 'FJ Order Queue'
  },
  cltordersqueuebtn: {
    id: 'app.containers.Header.cltordersqueuebtn',
    defaultMessage: 'CLT Queue'
  },
  layersqueuebtn: {
    id: 'app.containers.Header.layersqueuebtn',
    defaultMessage: 'Layers Queue'
  },
  schneiderLayersqueuebtn: {
    id: 'app.containers.Header.schneiderLayersqueuebtn',
    defaultMessage: 'Layers'
  },
  cltlayersqueuebtn: {
    id: 'app.containers.Header.cltlayersqueuebtn',
    defaultMessage: 'CLT Layers Queue'
  },
  errorslistbtn: {
    id: 'app.containers.Header.errorslistbtn',
    defaultMessage: 'Errors'
  },
  problemsolutionbtn: {
    id: 'app.containers.Header.problemsolutionbtn',
    defaultMessage: 'Problem helper'
  },
  sortingqueue: {
    id: 'app.containers.Header.sortingqueue',
    defaultMessage: 'Sorting pckg'
  },
  archiveMode: {
    id: 'app.containers.Header.archiveMode',
    defaultMessage: 'Archive Mode'
  },
  en: {
    id: 'app.language.en',
    defaultMessage: 'En'
  },
  de: {
    id: 'app.language.de',
    defaultMessage: 'De'
  },
  se: {
    id: 'app.language.se',
    defaultMessage: 'Se'
  },
  fi: {
    id: 'app.language.fi',
    defaultMessage: 'Fi'
  },
  es: {
    id: 'app.language.es',
    defaultMessage: 'Es'
  },
  he: {
    id: 'app.language.he',
    defaultMessage: 'He'
  },
  pt: {
    id: 'app.language.pt',
    defaultMessage: 'Pt'
  },
  sl: {
    id: 'app.language.sl',
    defaultMessage: 'Sl'
  },
  fr: {
    id: 'app.language.fr',
    defaultMessage: 'Fr'
  },
  cltpackages: {
    id: 'app.language.cltpackages',
    defaultMessage: 'CLT Packages'
  },
  files: {
    id: 'app.files',
    defaultMessage: 'Files'
  },
  parseProject: {
    id: 'app.arborealParseProject',
    defaultMessage: 'Parse project'
  },
  parseProjectTest: {
    id: 'app.arborealparseProjectTest',
    defaultMessage: 'Parse project Test'
  },
  createProjectFromFile: {
    id: 'app.arborealcreateProjectFromFile',
    defaultMessage: 'Create Project From File'
  },
  addCLTPackage: {
    id: 'app.addCLTPackage',
    defaultMessage: 'Add CLT package'
  },
  formed: {
    id: 'app.formed',
    defaultMessage: 'Formed'
  },
  cltPackaged: {
    id: 'app.cltPackaged',
    defaultMessage: 'CLT Packaged'
  },
  parsePackage: {
    id: 'app.parsePackage',
    defaultMessage: 'Parse package data'
  },
  reload: {
    id: 'app.reload',
    defaultMessage: 'Reload'
  },
  logout: {
    id: 'app.logout',
    defaultMessage: 'Logout'
  },
  privilege: {
    id: 'app.privilege',
    defaultMessage: 'Privilege'
  },
  profile: {
    id: 'app.profile',
    defaultMessage: 'Profile'
  },
  account: {
    id: 'app.account',
    defaultMessage: 'Account'
  },
  machinesettings: {
    id: 'app.machinesettings',
    defaultMessage: 'Machine settings'
  },
  addMachineSetting: {
    id: 'app.addMachineSetting',
    defaultMessage: 'Add machine setting'
  },
  addArborealRawMaterialMap: {
    id: 'app.addArborealRawMaterialMap',
    defaultMessage: 'Add Arboreal Raw Material Map'
  },
  materialStatus: {
    id: 'app.materialStatus',
    defaultMessage: 'Material status'
  },
  scannedPackageQueue: {
    id: 'app.scannedPackageQueue',
    defaultMessage: 'Scanned package'
  },
  restartScanner: {
    id: 'app.restartScanner',
    defaultMessage: 'Restart Scanner'
  },
  currentlyUsing: {
    id: 'app.currentlyUsing',
    defaultMessage: 'Currently used package'
  },
  packageBeforeXCut: {
    id: 'app.packageBeforeXCut',
    defaultMessage: 'Package before XCut'
  },
  infeedHolder: {
    id: 'app.infeedHolder',
    defaultMessage: 'Infeed Holder'
  },
  CutListDetail: {
    id: 'app.CutListDetail',
    defaultMessage: 'CutList Detail'
  },
  goodWood: {
    id: 'app.goodWood',
    defaultMessage: 'Good Wood'
  },
  badWood: {
    id: 'app.badWood',
    defaultMessage: 'Bad Wood'
  },
  infeedRolls: {
    id: 'app.infeedRolls',
    defaultMessage: 'Infeed rolls'
  },
  moveToLoaded: {
    id: 'app.moveToLoaded',
    defaultMessage: 'Move to loaded'
  },
  createPackageLabel: {
    id: 'app.createPackage',
    defaultMessage: 'Create package label'
  },
  all: {
    id: 'app.all',
    defaultMessage: 'All'
  },
  sendPanelToPLCTitle: {
    id: 'app.sendPanelToPLCTitle',
    defaultMessage: 'Send order data to PLC'
  },
  sendPanelToPLCBody: {
    id: 'app.sendPanelToPLCBody',
    defaultMessage: 'As title suggests'
  },
  scannedPackage: {
    id: 'app.scannedPackage',
    defaultMessage: 'Scanned package'
  },
  arborealID: {
    id: 'app.arborealID',
    defaultMessage: 'Arboreal ID'
  },
  vacuumPackages: {
    id: 'app.vacuumPackages',
    defaultMessage: 'Packages under vacuum'
  },
  packageForklift: {
    id: 'app.packageForklift',
    defaultMessage: 'Package forklift'
  },
  exitPackage: {
    id: 'app.exitPackage',
    defaultMessage: 'Exit package'
  },
  material: {
    id: 'app.material',
    defaultMessage: 'Mat.'
  },
  article: {
    id: 'app.article',
    defaultMessage: 'Article'
  },
  submit: {
    id: 'app.submit',
    defaultMessage: 'Submit'
  },
  terminal: {
    id: 'containers.Tableview.terminal',
    defaultMessage: 'Terminals'
  },
  plcComm: {
    id: 'containers.Tableview.plcComm',
    defaultMessage: 'Plc Comm'
  },
  services: {
    id: 'containers.Tableview.services',
    defaultMessage: 'Services'
  },
  kava: {
    id: 'app.components.SideMenu.kava',
    defaultMessage: 'Kava'
  },
  lastPackageId: {
    id: 'app.lastPackageId',
    defaultMessage: 'Last package id'
  },
  rauteREADY: {
    id: 'app.rauteREADY',
    defaultMessage: 'raute READY/Wood allowance'
  },
  rauteConnected: {
    id: 'app.rauteConnected',
    defaultMessage: 'raute SOCKET'
  },
  rauteWoodAllowance: {
    id: 'app.rauteWoodAllowance',
    defaultMessage: 'raute Wood Allowance'
  },
  lastRauteId: {
    id: 'app.lastRauteId',
    defaultMessage: 'ID before RAUTE'
  },
  lastAfterRauteId: {
    id: 'app.lastAfterRauteId',
    defaultMessage: 'ID after RAUTE'
  },
  canChangeDimensions: {
    id: 'app.canChangeDimensions',
    defaultMessage: 'Can skip dim.'
  },
  addToProject: {
    id: 'app.addToProject',
    defaultMessage: 'Add to project'
  },
  unlockFJGroups: {
    id: 'app.components.RawInfeedLoadingBox.assignToFJ',
    defaultMessage: 'Unlock FJ assignemnts'
  },
  sendToProduction: {
    id: 'app.containers.Tableview.sendToProduction',
    defaultMessage: 'Send to Production'
  },
  resendData: {
    id: 'app.containers.Tableview.resendData',
    defaultMessage: 'Resend data'
  },
  clearZpressOrder: {
    id: 'app.containers.Tableview.clearZpressOrder',
    defaultMessage: 'clearZpressOrder'
  },
  waitUntilFull: {
    id: 'app.containers.Tableview.waitUntilFull',
    defaultMessage: 'Wait full'
  },
  emptyExtraLayers: {
    id: 'app.containers.Tableview.emptyExtraLayers',
    defaultMessage: 'Empty extra'
  },
  finishedPackages: {
    id: 'app.containers.Tableview.finishedPackages',
    defaultMessage: 'Finished packages'
  },
  filling_mode: {
    id: 'app.containers.Tableview.filling_mode',
    defaultMessage: 'Fill'
  },
  emptying_mode: {
    id: 'app.containers.Tableview.filling_mode',
    defaultMessage: 'Emptying'
  },
  whiteGlueEnabled: {
    id: 'app.containers.Tableview.whiteGlueEnabled',
    defaultMessage: 'White glue enabled for all layers'
  },
  whiteGlue: {
    id: 'app.containers.Tableview.whiteGlue',
    defaultMessage: 'White glue'
  },
  noLayersToEmpty: {
    id: 'app.globalMessages.noLayersToEmpty',
    defaultMessage: 'No layers to empty'
  },
  readyToEmpty: {
    id: 'app.globalMessages.readyToEmpty',
    defaultMessage: 'Ready'
  },
  neededBefore: {
    id: 'app.globalMessages.neededBefore',
    defaultMessage: 'Needed before'
  },
  currentlyEmptying: {
    id: 'app.globalMessages.currentlyEmptying',
    defaultMessage: 'Currently emptying'
  },
  clearOutput: {
    id: 'app.globalMessages.clearOutput',
    defaultMessage: 'Clear output'
  },
  combineOrders: {
    id: 'app.globalMessages.combineOrders',
    defaultMessage: 'Combine orders'
  },
  breakOrders: {
    id: 'app.globalMessages.breakOrders',
    defaultMessage: 'Break orders'
  },
  missingPlateReq: {
    id: 'app.globalMessages.missingPlateReq',
    defaultMessage: 'Missing plate request'
  },
  machineDataOK: {
    id: 'app.globalMessages.machineDataOK',
    defaultMessage: 'Machine Data OK'
  },
  sendingFromPlaner: {
    id: 'app.globalMessages.sendingFromPlaner',
    defaultMessage: 'Sending from planer'
  },
  W1Takeaway: {
    id: 'app.globalMessages.W1Takeaway',
    defaultMessage: 'W1 Takeaway'
  },
  W3Takeaway: {
    id: 'app.globalMessages.W3Takeaway',
    defaultMessage: 'W3 Takeaway'
  },
  posWhiteGlue: {
    id: 'app.globalMessages.posWhiteGlue',
    defaultMessage: 'Position white glue'
  },
  posHotmelt: {
    id: 'app.globalMessages.posHotmelt',
    defaultMessage: 'Position hotmelt'
  },
  hotmelt: {
    id: 'app.globalMessages.hotmelt',
    defaultMessage: 'Hotmelt'
  },
  zinkGlue: {
    id: 'app.globalMessages.zinkGlue',
    defaultMessage: 'Zink Glue'
  },
  zinkHardener: {
    id: 'app.globalMessages.zinkHardener',
    defaultMessage: 'Zink Hardener'
  },
  qSawOffset: {
    id: 'app.globalMessages.qSawOffset',
    defaultMessage: 'Q Saw Offset'
  },
  lSawOffset: {
    id: 'app.globalMessages.lSawOffset',
    defaultMessage: 'L Saw Offset'
  },
  staticThicknessTakeaway: {
    id: 'app.globalMessages.staticThicknessTakeaway',
    defaultMessage: 'Static Thickness Takeaway'
  },
  lamellaGlueThickness: {
    id: 'app.globalMessages.lamellaGlueThickness',
    defaultMessage: 'Lamella Glue Thickness'
  },
  addedWidthFromShortToLong: {
    id: 'app.globalMessages.addedWidthFromShortToLong',
    defaultMessage: 'Added width Short -> Long'
  },
  addedWidthFromLongToShort: {
    id: 'app.globalMessages.addedWidthFromLongToShort',
    defaultMessage: 'Added width Long -> Short'
  },
  addedWidthEndOfGroup: {
    id: 'app.globalMessages.addedWidthEndOfGroup',
    defaultMessage: 'Added width End of Group'
  },
  lamellasUntilEndErrorThreshold: {
    id: 'app.globalMessages.lamellasUntilEndErrorThreshold',
    defaultMessage: 'Lamellas Until End Error Threshold'
  },
  minimumLamellasPerGroup: {
    id: 'app.globalMessages.minimumLamellasPerGroup',
    defaultMessage: 'Min. Lamellas Per Group'
  },
  minMergeAmount: {
    id: 'app.globalMessages.minMergeAmount',
    defaultMessage: 'Min. Merge Amount'
  },
  maxPackageOrders: {
    id: 'app.globalMessages.maxPackageOrders',
    defaultMessage: 'Max. Package Orders'
  },
  estimatedMaterialMultiplier: {
    id: 'app.globalMessages.estimatedMaterialMultiplier',
    defaultMessage: 'Estimated Material Multiplier'
  },
  pressGlue: {
    id: 'app.globalMessages.pressGlue',
    defaultMessage: 'Press Glue'
  },
  disableMixer: {
    id: 'app.globalMessages.disableMixer',
    defaultMessage: 'Disable Mixer'
  },
  autoLamellaCorrection: {
    id: 'app.globalMessages.autoLamellaCorrection',
    defaultMessage: 'Auto Lamella Correction'
  },
  usePlanerSettings: {
    id: 'app.globalMessages.usePlanerSettings',
    defaultMessage: 'Use Planer Setup'
  },
  clearPlanerSettings: {
    id: 'app.globalMessages.usePlanerSettings',
    defaultMessage: 'Clear Planer Setup'
  },
  allowMergingOfIDs: {
    id: 'app.globalMessages.allowMegingOfIDs',
    defaultMessage: 'Allow ID Merging'
  },
  checkOrderDone: {
    id: 'app.globalMessages.checkOrderDone',
    defaultMessage: 'Check Order Done'
  },
  zPress1Settings: {
    id: 'app.globalMessages.zPress1Settings',
    defaultMessage: 'ZPress 1 Settings'
  },
  zPress2Settings: {
    id: 'app.globalMessages.zPress2Settings',
    defaultMessage: 'ZPress 2 Settings'
  },
  zinc1Settings: {
    id: 'app.globalMessages.zinc1Settings',
    defaultMessage: 'Zink 1 Settings'
  },
  zinc2Settings: {
    id: 'app.globalMessages.zinc2Settings',
    defaultMessage: 'Zink 2 Settings'
  },
  generalSettings: {
    id: 'app.globalMessages.generalSettings',
    defaultMessage: 'General Settings'
  },
  linesOfWhiteGlue: {
    id: 'app.globalMessages.linesOfWhiteGlue',
    defaultMessage: 'Lines of white glue'
  },
  linesOfHotmelt: {
    id: 'app.globalMessages.linesOfHotmelt',
    defaultMessage: 'Lines of white hotmelt'
  },
  turningBeforePress: {
    id: 'app.globalMessages.turningBeforePress',
    defaultMessage: 'Turning before press'
  },
  turningEverySecond: {
    id: 'app.globalMessages.turningEverySecond',
    defaultMessage: 'Turning every second'
  },
  turningZinc: {
    id: 'app.globalMessages.turningZinc',
    defaultMessage: 'Turning FJ'
  },
  confirmFinishZPress: {
    id: 'app.globalMessages.confirmFinishZPress',
    defaultMessage: 'Confirm that id finished, missing'
  },
  waitingLineToEmptyToContinue: {
    id: 'app.globalMessages.waitingLineToEmptyToContinue',
    defaultMessage: 'Waiting for line to empty to reassign'
  },
  packagesSynced: {
    id: 'app.globalMessages.packagesSynced',
    defaultMessage: 'Packages synced with the machine'
  },
  syncOrders: {
    id: 'app.globalMessages.syncOrders',
    defaultMessage: 'Sync'
  },
  orderMatchesBeforePlaner: {
    id: 'app.globalMessages.orderMatchesBeforePlaner',
    defaultMessage: 'Order matches before planer'
  },
  orderMatchesAfterPlaner: {
    id: 'app.globalMessages.orderMatchesAfterPlaner',
    defaultMessage: 'Order matches after planer'
  },
  lineEmptyFromSuperplanToStacker: {
    id: 'app.globalMessages.lineEmptyFromSuperplanToStacker',
    defaultMessage: 'Empty from planer to stacker'
  },
  splVacuumAllowance: {
    id: 'app.globalMessages.splVacuumAllowance',
    defaultMessage: 'Vacuum allowance'
  },
  stacker2Allowance: {
    id: 'app.globalMessages.stacker2Allowance',
    defaultMessage: 'Stacker allowance 2'
  },
  stacker1Allowance: {
    id: 'app.globalMessages.stacker1Allowance',
    defaultMessage: 'Stacker allowance 1'
  },
  sortingLine1Full: {
    id: 'app.globalMessages.sortingLine1Full',
    defaultMessage: 'Sorting line 1 Full after stacker'
  },
  clearFIFO: {
    id: 'app.globalMessages.clearFIFO',
    defaultMessage: 'Clear FIFO'
  },
  clear: {
    id: 'app.globalMessages.clear',
    defaultMessage: 'clear'
  },
  waitingForLineToEmpty: {
    id: 'app.globalMessages.waitingForLineToEmpty',
    defaultMessage: 'Waiting for the line to empty'
  },
  goldenEyeCounter: {
    id: 'app.globalMessages.goldenEyeCounter',
    defaultMessage: 'Golden eye counter'
  },
  sortingOrder: {
    id: 'app.globalMessages.sortingOrder',
    defaultMessage: 'Sorting order'
  },
  setNewSortingOrder: {
    id: 'app.globalMessages.setNewSortingOrder',
    defaultMessage: 'Set new Sorting order'
  },
  orderStatus: {
    id: 'app.globalMessages.orderStatus',
    defaultMessage: 'Order status'
  },
  orderDone: {
    id: 'app.globalMessages.orderDone',
    defaultMessage: 'Order done'
  },
  changeStatus: {
    id: 'app.globalMessages.orderDone',
    defaultMessage: 'Change Status'
  },
  sortedInputs: {
    id: 'app.globalMessages.sortedInputs',
    defaultMessage: 'Article inputs'
  },
  sortedOutputs: {
    id: 'app.globalMessages.sortedOutputs',
    defaultMessage: 'Article outputs'
  },
  insights: {
    id: 'app.insights',
    defaultMessage: 'Insights'
  },
  matchAndAssignBySettings: {
    id: 'app.containers.tableView.matchAndAssignBySettings',
    defaultMessage: 'Assign by settings'
  },
  schneiderOrderRawMaterial: {
    id: 'app.containers.tableView.schneiderOrderRawMaterial',
    defaultMessage: 'Order raw material'
  },
  notFoundText: {
    id: 'app.containers.notFoundPage.notFoundText',
    defaultMessage: 'This page could not be found'
  },
  backHomeText: {
    id: 'app.containers.notFoundPage.backHomeText',
    defaultMessage: 'Back To Homepage'
  },
  crossOrderCanBeLoaded: {
    id: 'app.containers.notFoundPage.crossOrderCanBeLoaded',
    defaultMessage: 'Cross order can be loaded'
  },
  isoZincTitle: {
    id: 'app.containers.notFoundPage.isoZincTitle',
    defaultMessage: 'ISO Zinc'
  },
  hasInsulation: {
    id: 'app.containers.notFoundPage.hasInsulation',
    defaultMessage: 'has INS'
  },
  loadingISONext: {
    id: 'app.containers.notFoundPage.loadingISONext',
    defaultMessage: 'Loading ISO next'
  },
  loadingWoodNext: {
    id: 'app.containers.notFoundPage.loadingWoodNext',
    defaultMessage: 'Loading wood next'
  },
  stackedPackageInfo: {
    id: 'app.containers.notFoundPage.stackedPackageInfo',
    defaultMessage: 'Stacked package dimensions'
  },
  orderBeforePlaner: {
    id: 'app.containers.notFoundPage.orderBeforePlaner',
    defaultMessage: 'Order before planer'
  },
  orderAfterPlaner: {
    id: 'app.containers.notFoundPage.orderAfterPlaner',
    defaultMessage: 'Order after planer'
  },
  orderBeforeXCut: {
    id: 'app.containers.notFoundPage.orderBeforeXCut',
    defaultMessage: 'Order before XCUT2'
  },
  ISOFullyLoaded: {
    id: 'app.containers.notFoundPage.ISOFullyLoaded',
    defaultMessage: 'ISO fully loaded'
  },
  woodFullyLoaded: {
    id: 'app.containers.notFoundPage.woodFullyLoaded',
    defaultMessage: 'Wood fully loaded'
  },
  subtractFromID: {
    id: 'app.containers.notFoundPage.subtractFromID',
    defaultMessage: 'Subtract from ID'
  },
  deleted: {
    id: 'app.containers.notFoundPage.deleted',
    defaultMessage: 'Deleted'
  },
  speedTime: {
    id: 'app.containers.notFoundPage.speedTime',
    defaultMessage: 'Speed - Time'
  },
  emptyingStatus: {
    id: 'app.containers.notFoundPage.emptyingStatus',
    defaultMessage: 'Emptying status'
  },
  automaticEmptyingActive: {
    id: 'app.containers.notFoundPage.automaticEmptyingActive',
    defaultMessage: 'Automatic emptying active (last lamella time exceeded)'
  },
  automaticEmptyingEnabled: {
    id: 'app.containers.notFoundPage.automaticEmpty',
    defaultMessage: 'Automatic emptying enabled'
  },
  ktz1InfeedEmpty: {
    id: 'app.containers.notFoundPage.ktz1InfeedEmpty',
    defaultMessage: 'KTZ 1 infeed empty'
  },
  ktz2InfeedEmpty: {
    id: 'app.containers.notFoundPage.ktz2InfeedEmpty',
    defaultMessage: 'KTZ 2 infeed empty'
  },
  sandingComplete: {
    id: 'app.containers.notFoundPage.sandingComplete',
    defaultMessage: 'Sanding complete'
  },
  itemDetails: {
    id: 'app.containers.notFoundPage.itemDetails',
    defaultMessage: 'Item details'
  },
  currentGroup: {
    id: 'app.containers.notFoundPage.currentGroup',
    defaultMessage: 'Current group'
  },
  splitWood: {
    id: 'app.containers.notFoundPage.splitWood',
    defaultMessage: 'Split wood'
  },
  noCutMode: {
    id: 'app.containers.notFoundPage.noCutMode',
    defaultMessage: 'KVH No Cut Mode'
  },
  manualLabelMode: {
    id: 'app.containers.notFoundPage.manualLabelMode',
    defaultMessage: 'Custom Label Mode'
  },
  lastEmptyingData: {
    id: 'app.containers.notFoundPage.lastEmptyingData',
    defaultMessage: 'Last emptying data'
  },
  currentQualitySelect: {
    id: 'app.containers.notFoundPage.currentQualitySelect',
    defaultMessage: 'Current default quality'
  },
  currentSuitableEtages: {
    id: 'app.containers.notFoundPage.currentSuitableEtages',
    defaultMessage: 'Current suitable etages'
  },
  selectWidth: {
    id: 'app.containers.customModal.selectWidth',
    defaultMessage: 'Change Width'
  },
  externalQuality: {
    id: 'app.containers.ModelFieldMessages.externalQuality',
    defaultMessage: 'Ext. quality'
  },
  updatePlanerSetup: {
    id: 'app.containers.Tableview.updatePlanerSetup',
    defaultMessage: 'Update Planer Setup'
  },
  confirmServer: {
    id: 'app.containers.Tableview.confirmServer',
    defaultMessage: 'Confirm Server'
  },
  printerEvents: {
    id: 'app.containers.Tableview.printerEvents',
    defaultMessage: 'Printer Events'
  },

  operationState: {
    id: 'app.containers.Tableview.operationState',
    defaultMessage: 'Operation state'
  },
  paternosterAllowance: {
    id: 'app.containers.Tableview.paternosterAllowance',
    defaultMessage: 'Paternoster allowance'
  },
  paternosterOperationAuto: {
    id: 'app.containers.Tableview.paternosterOperationAuto',
    defaultMessage: 'Paternoster operation auto'
  },
  plcPaternosterRequest: {
    id: 'app.containers.Tableview.plcPaternosterRequest',
    defaultMessage: 'PLC paternoster request'
  },
  testOutfeedPrinter2: {
    id: 'app.containers.Tableview.testOutfeedPrinter2',
    defaultMessage: 'Test OutfeedPrinter2'
  },
  testKVHStackerPrinter: {
    id: 'app.containers.Tableview.testKVHStackerPrinter',
    defaultMessage: 'Test KVHStackerPrinter'
  },
  testCLTpanelPrinter: {
    id: 'app.containers.Tableview.testCLTpanelPrinter',
    defaultMessage: 'Test CLTpanelPrinter'
  },
  testFlexiPressPrinter: {
    id: 'app.containers.Tableview.testFlexiPressPrinter',
    defaultMessage: 'Test FlexiPress Printer'
  },
  testRotoPressPrinter: {
    id: 'app.containers.Tableview.testRotoPressPrinter',
    defaultMessage: 'Test RotoPress Printer'
  },
  testCutSawPrinter: {
    id: 'app.containers.Tableview.testCutSawPrinter',
    defaultMessage: 'Test CutSaw Printer'
  },
  currentActiveOrder: {
    id: 'app.containers.Tableview.currentActiveOrder',
    defaultMessage: 'Current active order'
  },
  packagePosA: {
    id: 'app.containers.Tableview.packagePosA',
    defaultMessage: 'OUTER A'
  },
  packagePosB: {
    id: 'app.containers.Tableview.packagePosB',
    defaultMessage: 'INNER B'
  },
  manualA: {
    id: 'app.containers.Tableview.manualA',
    defaultMessage: 'Manual A'
  },
  manualB: {
    id: 'app.containers.Tableview.manualB',
    defaultMessage: 'Manual B'
  },
  forceCalculation: {
    id: 'app.containers.Tableview.forceCalculation',
    defaultMessage: 'Force calculation'
  },
  orderInfoBox: {
    id: 'app.containers.DashboardXLAM.orderInfoBox',
    defaultMessage: 'Order Info'
  },
  sendGlobalMessage: {
    id: 'app.containers.App.sendGlobalMessage',
    defaultMessage: 'Send global message'
  },
  clearGlobalMessage: {
    id: 'app.containers.App.clearGlobalMessage',
    defaultMessage: 'Clear global message'
  },
  pleaseEnterMessage: {
    id: 'app.containers.App.pleaseEnterMessage',
    defaultMessage: 'Please enter message'
  },
  meas: {
    id: 'app.containers.meas',
    defaultMessage: 'Meas'
  },
  infeedArray: {
    id: 'app.containers.infeedArray',
    defaultMessage: 'InfeedArray'
  },
  entryChain: {
    id: 'app.containers.entryChain',
    defaultMessage: 'EntryChain'
  },
  mainChain: {
    id: 'app.containers.mainChain',
    defaultMessage: 'MainChain'
  },
  pow: {
    id: 'app.containers.pow',
    defaultMessage: 'Pow'
  },
  loadingPackagePosition: {
    id: 'app.containers.loadingPackagePosition',
    defaultMessage: 'Loading pos'
  }
});
